<button
  mat-icon-button
  type="button"
  [matMenuTriggerFor]="optionsMenu"
>
  <mat-icon>more_horiz</mat-icon>
</button>

<mat-menu #optionsMenu>
  <button
    mat-menu-item
    class="menu-actions__button"
    type="button"
    (click)="onExportButtonClick(viewType.Internal)"
  >
    <mat-icon class="menu-actions__icon">file_download</mat-icon>
    {{ customLabels?.internal ?? defaultExportButtonLabels.internal }}
  </button>

  @if (shouldDisplayCustomerExportButton) {
    <button
      mat-menu-item
      class="menu-actions__button"
      type="button"
      (click)="onExportButtonClick(viewType.Customer)"
    >
      <mat-icon class="menu-actions__icon">file_download</mat-icon>
      {{ customLabels?.customer ?? defaultExportButtonLabels.customer }}
    </button>
  }

  <button
    mat-menu-item
    class="menu-actions__button"
    type="button"
    (click)="onExportMultipleButtonClick()"
  >
    <mat-icon class="menu-actions__icon">file_download</mat-icon>
    {{ customLabels?.multiple ?? defaultExportButtonLabels.multiple }}
  </button>
</mat-menu>
