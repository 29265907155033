import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, map, Observable } from 'rxjs';

import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';
import { RawFormValues } from '@dartsales/common/core/utils/types/form';
import { DEFAULT_TERM_SETTINGS } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/default-estimate-settings';

import { BaseModulesLocalStorageFormMapper } from './base-modules-local-storage-form.mapper';
import { AbstractKeyValueLocalStorageManagerService } from './abstract-key-value-storage-manager';
import { TermSubcategoryFormControls } from './forms/terms-details-settings-form';

/** Term services local storage service. */
@Injectable()
export class TermSubcategoriesLocalStorageManagerService extends AbstractKeyValueLocalStorageManagerService<TermSubcategoryFormControls> {
  private readonly baseModulesLocalStorageFormMapper = inject(BaseModulesLocalStorageFormMapper);

  /**
   * Get settings key.
   * @param termId$ Term ID.
   * @param subcategoryId$ Subcategory ID.
   */
  public getSettingsKey(
    termId$: Observable<number>,
    subcategoryId$: Observable<number>,
  ): Observable<string> {
    return combineLatest([
      termId$,
      subcategoryId$,
    ]).pipe(
      map(([termId, subcategoryId]) => `${termId}-${subcategoryId}`),
    );
  }

  /** @inheritdoc */
  protected override getSavedSettings(
    key: string,
    settings: EstimateSettingsLocalStorage,
  ): RawFormValues<TermSubcategoryFormControls> {
    return settings.termsDetails?.subcategories[key];
  }

  /** @inheritdoc */
  protected override createForm(): FormGroup<TermSubcategoryFormControls> {
    return this.fb.group<TermSubcategoryFormControls>({
      isExpand: this.fb.control(DEFAULT_TERM_SETTINGS.isExpand),
      units: this.baseModulesLocalStorageFormMapper.createModuleUnitsSettingsForm(),
    });
  }
}
