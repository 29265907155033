import { AfterViewInit, Directive, ElementRef, HostBinding, Input, inject } from '@angular/core';

import { EditableTableComponent } from '../editable-table.component';

/** Sticky row side. */
export type StickyRowSide = 'top' | 'bottom';

/** Sticky row in editable table. */
@Directive({
  selector: '[dartsalescEditableTableStickyRow]',
})
export class EditableTableStickyRowDirective implements AfterViewInit {
  /** Element reference. */
  public readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  private readonly table = inject(EditableTableComponent);

  /** Sticky direction. */
  @Input('dartsalescEditableTableStickyRow')
  public stickyRowSide?: StickyRowSide;

  /** Sticky cell CSS class. */
  @HostBinding('class')
  protected get stickyCellClass(): Record<string, boolean> {
    return {
      [this.stickyTopSideClass]: this.stickyRowSide === 'top',
      [this.stickyBottomSideClass]: this.stickyRowSide === 'bottom',
    };
  }

  private readonly stickyTopSideClass = 'editable-table-sticky-row_top';

  private readonly stickyBottomSideClass = 'editable-table-sticky-row_bottom';

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    this.setTopBottomOffset();
  }

  /** Set top/bottom offset. */
  public setTopBottomOffset(): void {
    // We need to wait until all elements are rendered
    // So it's possible to correctly calculate offsets.
    setTimeout(() => {
      if (this.stickyRowSide === 'top') {
        this.setTopOffset();
      } else if (this.stickyRowSide === 'bottom') {
        this.setBottomOffset();
      }
    }, 0);
  }

  private setTopOffset(): void {
    const topStickyRows = this.table.stickyRows?.filter(row => row.stickyRowSide === 'top') ?? [];
    let topOffset = 0;
    for (const row of topStickyRows) {
      if (row.elementRef === this.elementRef) {
        break;
      }
      topOffset += row.elementRef.nativeElement.getBoundingClientRect().height;
    }

    const element = this.elementRef.nativeElement;
    element.style.position = 'sticky';
    element.style.top = `${topOffset}px`;
    element.style.zIndex = '5';
  }

  private setBottomOffset(): void {
    const bottomStickyRows = this.table.stickyRows?.filter(row => row.stickyRowSide === 'bottom').reverse() ?? [];
    let bottomOffset = 0;
    for (const row of bottomStickyRows) {
      if (row.elementRef === this.elementRef) {
        break;
      }
      bottomOffset += row.elementRef.nativeElement.getBoundingClientRect().height;
    }

    const element = this.elementRef.nativeElement;
    element.style.position = 'sticky';
    element.style.bottom = `${bottomOffset}px`;
    element.style.zIndex = '4';
  }
}
