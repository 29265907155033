@if (catalogItemsSelectionService.selectedItems$ | async; as selectedItems) {
  @if (selectedItems.length > 0) {
    <mat-chip
      class="chip"
      [removable]="true"
      (removed)="catalogItemsSelectionService.clearSelectedItems()"
    >
      <span>
        {{ selectedItems.length | pluralize: "item" : "items" }} selected
      </span>
      <button
        matChipRemove
        type="button"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  }
}
