import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FilterComponentType, injectFilterControl, injectFilterData } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';
import { OptionSelect } from '@dartsales/common/core/models/option-select';

/** Enum filter data. */
export type EnumFilterSingleSelectInputData = {

  /** Options. */
  readonly options: readonly OptionSelect<string>[];
};

/** Enum select filter input. */
@Component({
  selector: 'dartsalesc-enum-filter-single-select-input',
  templateUrl: './enum-filter-single-select-input.component.html',
  styleUrls: ['./enum-filter-single-select-input.component.css'],

  // We have nested form controls. 'Default' change detection is required for displaying errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EnumFilterSingleSelectInputComponent<T = string> implements FilterComponentType<T, EnumFilterSingleSelectInputData> {
  /** @inheritdoc */
  public readonly formControl = injectFilterControl<T>();

  /** @inheritdoc */
  public readonly data = injectFilterData<EnumFilterSingleSelectInputData>();
}
