import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild, inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, Observable, filter, shareReplay, combineLatest, switchMap, BehaviorSubject, startWith } from 'rxjs';

import { ProjectStatus } from '@dartsales/common/core/enums/project-status';
import { Project } from '@dartsales/common/core/models/project/project';
import { ProjectLayoutService } from '@dartsales/common/core/services/project-layout.service';
import { FinalEstimate } from '@dartsales/common/core/models/estimate/final-estimate/final-estimate';
import { OverviewAlternateEstimate } from '@dartsales/common/core/models/estimate/alternate-estimate';
import { ProjectAlternateEstimatesService } from '@dartsales/common/core/services/project-alternate-estimates.service';
import { ProjectFinalEstimatesService } from '@dartsales/common/core/services/project-final-estimates.service';
import { FinalEstimateStatus } from '@dartsales/common/core/models/estimate/final-estimate/final-estimate-status';
import { ProjectChangeOrdersService } from '@dartsales/common/core/services/project-change-orders.service';
import { PreviewChangeOrder } from '@dartsales/common/core/models/estimate/change-order/change-order';
import { UserPermission } from '@dartsales/common/core/enums/user-permission';
import { CHANGE_ORDER_ID_PARAM, ESTIMATE_ID_PARAM, SERVICE_ID_PARAM, TERM_ID_PARAM, injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { NavItem } from '../nav-list-item/navigation-item';

/** Project layout nav item. */
type ProjectLayoutNavItem = {

  /** Has divider. */
  readonly hasDivider?: boolean;
} & NavItem;

/** Project sidebar component. */
@Component({
  selector: 'dartsalesw-project-sidebar',
  templateUrl: './project-sidebar.component.html',
  styleUrls: ['./project-sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSidebarComponent {

  /** Is sidebar opened. */
  @Input()
  public set isSidebarOpened(value: boolean) {
    this.isSidebarOpened$.next(value);
  }

  /** Estimate editor nav item template. */
  @ViewChild('estimateEditor')
  protected readonly estimateEditorTemplate?: TemplateRef<unknown>;

  private readonly routePaths = injectWebAppRoutes();

  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly projectAlternateEstimatesServices = inject(ProjectAlternateEstimatesService);

  private readonly projectFinalEstimatesService = inject(ProjectFinalEstimatesService);

  private readonly projectChangeOrdersService = inject(ProjectChangeOrdersService);

  private readonly router = inject(Router);

  private readonly route = inject(ActivatedRoute);

  /** Is sidebar opened. */
  protected readonly isSidebarOpened$ = new BehaviorSubject(true);

  /** Project. */
  protected readonly project$ = this.projectLayoutService.project$;

  /** Current url. */
  protected readonly currentUrl$ = this.createCurrentUrlStream();

  /** Organization name. */
  protected readonly organization$ = this.projectLayoutService.organization$;

  /** Navigation list items. */
  protected readonly navigationListItems$ = this.project$.pipe(
    switchMap(project => this.createNavigationItemsStream(project)),
  );

  /**
   * Handle click on navigate button.
   * @param event Event.
   * @param project Project.
   */
  protected onNavigateButtonClick(event: MouseEvent, project: Project): void {
    event.stopPropagation();
    event.preventDefault();
    const path = this.getEstimateEditorPath(project);
    this.router.navigate([path]);
  }

  private getIdParamFormRoute(route: ActivatedRoute, paramKey: string): string | null {
    if (route.firstChild == null) {
      return null;
    }

    const paramsMap = route.firstChild.snapshot.paramMap;
    const id = paramsMap.get(paramKey);

    if (id != null) {
      return id;
    }

    return this.getIdParamFormRoute(route.firstChild, paramKey);
  }

  private createCurrentUrlStream(): Observable<string> {
    // We can't observe current url changes directly.
    // That's why we need to observe NavigationEnd event.
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(() => this.router.url),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createNavigationItemsStream(project: Project): Observable<ProjectLayoutNavItem[]> {
    return combineLatest([
      this.createProjectNavigationStream(project),
      this.createAdditionalNavLinks(project),
      this.createBaseNavigationItemsStream(project),
    ]).pipe(
      map(([projectNavigation, additionalLinks, navItems]) => [
        projectNavigation,
        ...additionalLinks,
        ...navItems,
      ]),
    );
  }

  private createProjectNavigationStream(project: Project): Observable<ProjectLayoutNavItem> {
    const projectChildPaths = this.routePaths.project.children({ projectId: project.id });

    return combineLatest([
      this.projectFinalEstimatesService.estimates$,
      this.projectAlternateEstimatesServices.estimates$,
      this.projectChangeOrdersService.changeOrders$,
    ]).pipe(
      map(([finalEstimates, alternateEstimates, changeOrders]) => ({
        label: 'Project Dashboard',
        path: projectChildPaths.dashboard.url,
        subTitle: ProjectStatus.toReadable(project.status),
        iconName: 'grid_view',
        expandedByDefault: true,
        children: ([
          {
            label: 'Base Estimate',
            path: projectChildPaths.dashboard.children.baseEstimate.url,
            iconName: 'flag',
            customNavItemTemplate: this.estimateEditorTemplate,
          },
          {
            label: 'Alternate Estimates',
            path: projectChildPaths.dashboard.children.summary.children.alternateEstimates.url,
            children: this.generateAlternateEstimateNavigationItems(project, alternateEstimates),
          },
          {
            label: 'Final Estimates',
            path: projectChildPaths.dashboard.children.summary.children.finalEstimates.url,
            children: this.generateFinalEstimateNavigationItems(project, finalEstimates),
          },
          {
            label: 'Change Orders',
            path: projectChildPaths.dashboard.children.summary.children.changeOrders.url,
            children: this.generateChangeOrderNavigationItems(project, changeOrders),
          },
        ] as const).filter(child => {
          if (child.label === 'Final Estimates' && child.children.length === 0) {
            return false;
          }

          if (child.label === 'Alternate Estimates' && child.children.length === 0) {
            return false;
          }

          if (child.label === 'Change Orders' && child.children.length === 0) {
            return false;
          }

          return true;
        }),
      })),
    );
  }

  private createAdditionalNavLinks(project: Project): Observable<ProjectLayoutNavItem[]> {
    return combineLatest([
      this.isSidebarOpened$,
      this.currentUrl$.pipe(startWith('')),
    ])
      .pipe(
        map(([isSidebarOpened]) => {
          if (isSidebarOpened) {
            return [];
          }

          return [
            {
              label: '',
              path: this.getEstimateEditorPath(project),
              iconName: 'flag',
              disableActiveIndicator: true,
            },
          ];
        }),
      );
  }

  private getEstimateEditorPath(project: Project): string {
    const projectChildPaths = this.routePaths.project.children({ projectId: project.id });
    const estimateId = this.getIdParamFormRoute(this.route, ESTIMATE_ID_PARAM);
    const changeOrderId = this.getIdParamFormRoute(this.route, CHANGE_ORDER_ID_PARAM);
    const termId = this.getIdParamFormRoute(this.route, TERM_ID_PARAM);
    const serviceId = this.getIdParamFormRoute(this.route, SERVICE_ID_PARAM);
    const isAlternatePage = this.router.url.includes(projectChildPaths.dashboard.children.alternateEstimates.path);
    const isBaseEstimatePage = this.router.url.includes(projectChildPaths.dashboard.children.baseEstimate.path);
    const isChangeOrderPage = this.router.url.includes(projectChildPaths.dashboard.children.changeOrders.path);

    if (termId != null && serviceId != null) {
      /** From Alternate Estimate/Term/Modules page to Alternate Estimate page. */
      if (estimateId != null && isAlternatePage) {
        return projectChildPaths.dashboard.children.alternateEstimates.children.details.children({ estimateId }).terms.url({ termId });
      }

      /** From Change orders/Term/Modules page to Change orders page. */
      if (changeOrderId != null && isChangeOrderPage) {
        return projectChildPaths.dashboard.children.changeOrders.children.details.children({ changeOrderId }).terms.url({ termId });
      }

      /** From Base Estimate/Term/Modules page to Base Estimate page. */
      return projectChildPaths.dashboard.children.baseEstimate.children.terms.url({ termId });
    }

    /** From Base Estimate/Modules page to Base Estimate page. */
    if (isBaseEstimatePage) {
      return projectChildPaths.dashboard.children.baseEstimate.url;
    }

    /** From Alternate Estimate/Modules page to Alternate Estimate page. */
    if (estimateId != null && isAlternatePage) {
      return projectChildPaths.dashboard.children.alternateEstimates.children.details.url({ estimateId });
    }

    /** From Change orders/Modules page to Change orders page. */
    if (changeOrderId != null && isChangeOrderPage) {
      return projectChildPaths.dashboard.children.changeOrders.children.details.url({ changeOrderId });
    }

    return this.router.url;
  }

  private generateFinalEstimateNavigationItems(project: Project, finalEstimates: FinalEstimate[]): ProjectLayoutNavItem[] {
    return finalEstimates.map(finalEstimate => ({
      label: finalEstimate.name,
      iconName: finalEstimate.status === FinalEstimateStatus.Awarded ? 'star' : undefined,
      path: this.createProjectDashboardRoutes(project).finalEstimates.children.details.url({ [ESTIMATE_ID_PARAM]: finalEstimate.id }),
    }));
  }

  private generateAlternateEstimateNavigationItems(
    project: Project,
    alternateEstimates: OverviewAlternateEstimate[],
  ): ProjectLayoutNavItem[] {
    return alternateEstimates.map(alternateEstimate => ({
      label: alternateEstimate.name,
      path: this.createProjectDashboardRoutes(project).alternateEstimates
        .children.details.url({ [ESTIMATE_ID_PARAM]: alternateEstimate.id }),
    }));
  }

  private generateChangeOrderNavigationItems(
    project: Project,
    changeOrders: PreviewChangeOrder[],
  ): ProjectLayoutNavItem[] {
    return changeOrders.map(changeOrder => ({
      label: changeOrder.name,
      path: this.createProjectDashboardRoutes(project).changeOrders.children.details.url({ [CHANGE_ORDER_ID_PARAM]: changeOrder.id }),
    }));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private createProjectDashboardRoutes(project: Project) {
    return this.routePaths.project.children({ projectId: project.id }).dashboard.children;
  }

  private createBaseNavigationItemsStream(project: Project): Observable<ProjectLayoutNavItem[]> {
    const projectChildPaths = this.routePaths.project.children({ projectId: project.id });
    return this.projectLayoutService.hasPermissionInCurrentOrganization(
      [UserPermission.CRUDResource],
    ).pipe(
      map(isAllowPermission => {
        const generalNavigationItems = [
          {
            label: 'Compare Projects',
            iconName: 'compare_arrows',
            path: projectChildPaths.compare.url,
          },
          {
            label: 'Files',
            iconName: 'folder',
            path: projectChildPaths.files.url,
          },
        ];

        if (isAllowPermission) {
          return [
            ...generalNavigationItems,
            {
              label: 'Resources',
              svgIcon: 'resource',
              path: projectChildPaths.resources.children.bidCategories.url,
              hasDivider: true,
              children: [
                {
                  label: 'Bid Categories',
                  svgIcon: 'bidCategories',
                  path: projectChildPaths.resources.children.bidCategories.url,
                },
                {
                  label: 'Labor Roles',
                  svgIcon: 'user',
                  path: projectChildPaths.resources.children.laborRoles.url,
                },
                {
                  label: 'Default Expense Types',
                  svgIcon: 'expenseType',
                  path: projectChildPaths.resources.children.expenseTypes.url,
                },
                {
                  label: 'Labor Tasks',
                  svgIcon: 'wrench',
                  path: projectChildPaths.resources.children.laborTasks.url,
                },
                {
                  label: 'Subcontractors',
                  svgIcon: 'briefcase',
                  path: projectChildPaths.resources.children.subcontractors.url,
                },

                /** The page is temporarily hidden. Task https://saritasa.atlassian.net/browse/DART-1693. */
                // {
                //   label: 'System Classifications',
                //   svgIcon: 'systemClassification',
                //   path: projectChildPaths.resources.children.systemClassification.url,
                // },
                {
                  label: 'Service Categories',
                  iconName: 'event_note',
                  path: projectChildPaths.resources.children.serviceCategories.url,
                },
              ],
            },
          ];
        }

        return generalNavigationItems;
      }),
    );
  }
}
