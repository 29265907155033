import { BehaviorSubject, Observable, combineLatest, filter, map, shareReplay, skip } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';

import { TableCellFormControl } from '../table-cell-form-control.component';

/**
 * Clarification about union type in this component:
 * 'OptionSelect' - the value is selected from the options;
 * 'string' - new value entered by user (can be used as search as well as for creating new options);
 * When user input changes `newItemChange` Output is emitted.
 */

/** Autocomplete table cell. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-autocomplete-table-cell',
  templateUrl: './autocomplete-table-cell.component.html',
  styleUrls: ['./autocomplete-table-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AutocompleteTableCellComponent<T> extends TableCellFormControl<OptionSelect<T> | null>
  implements OnInit {

  /** Select options. */
  @Input()
  public set options(value: readonly OptionSelect<T>[]) {
    this.options$.next([...value]);
  }

  /** Select placeholder. */
  @Input()
  public placeholder = 'Select value';

  /**
   * Width of the autocomplete panel.
   * This value is used in px.
   * The default value is 0 - the width is set based on the width of the input tag.
   */
  @Input()
  public panelWidth = 0;

  /** Is readonly. */
  @Input()
  public isReadonly = false;

  /**
   * Function to display autocomplete selected value.
   * @param value Value.
   */
  @Input()
  public displayWith: (value: OptionSelect<T> | null) => string = value => value?.label ?? '';

  /** Selection change. */
  @Output()
  public readonly selectionChange = new EventEmitter<T>();

  /** New item create. */
  @Output()
  public readonly newItemChange = new EventEmitter<string>();

  private readonly fb = inject(NonNullableFormBuilder);

  /** Search form control. */
  protected readonly autocompleteControl = this.fb.control<OptionSelect<T> | string>('');

  private readonly options$ = new BehaviorSubject<OptionSelect<T>[]>([]);

  /** Filtered options stream. */
  protected readonly filteredOptions$ = this.createFilteredOptions(this.options$);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: OptionSelect<T> | null): void {
    this.autocompleteControl.setValue(value ?? '', { emitEvent: false });
    super.writeValue(value);
  }

  /**
   * Display function for MatAutocomplete.
   * We use arrow function to avoid 'this' context binding issues.
   * @param value Autocomplete option value.
   */
  protected autocompleteDisplayFn = (value: OptionSelect<T> | string | null): string => {
    if (typeof value === 'string') {
      return value;
    }

    return this.displayWith(value);
  };

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.autocompleteControl.disable();
    } else {
      this.autocompleteControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /**
   * Handler for selected option.
   * @param event MatOption selection change.
   */
  protected onOptionSelected(event: MatOptionSelectionChange<OptionSelect<T>>): void {
    /** This check solves this problem https://github.com/angular/components/issues/7369. */
    if (event.isUserInput) {
      this.controlValue = event.source.value;
      this.selectionChange.emit(event.source.value.value);
    }
  }

  private subscribeToControlChanges(): void {
    listenControlChanges(this.autocompleteControl).pipe(
      skip(1),
      filter((value): value is string => typeof value === 'string'),
      untilDestroyed(this),
    )
      .subscribe(value => {
        this.newItemChange.emit(value);
      });
  }

  private createFilteredOptions(options$: Observable<OptionSelect<T>[]>): Observable<OptionSelect<T>[]> {
    return combineLatest([options$, listenControlChanges(this.autocompleteControl)]).pipe(
      map(([options, value]) => {
        if (typeof value === 'string') {
          return this.filterSelectOptions(value, options);
        }
        return options;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private filterSelectOptions(value: string, options: readonly OptionSelect<T>[]): OptionSelect<T>[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => option.label.toLowerCase().includes(filterValue));
  }
}
