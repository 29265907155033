import { Directive, Input, TemplateRef, inject } from '@angular/core';

/**
 * Directive to mark ng-template as table-header.
 * @example
 * ```html
 * <ng-container *dartsalescTableHeader="let col">
 *  {{ col|uppercase }}
 * </ng-container>
 * ```
 */
@Directive({
  selector: '[dartsalescTableHeader]',
})
export class TableHeaderDirective {
  /** Template. */
  public readonly template = inject<TemplateRef<unknown>>(TemplateRef);

  /**
   * Whether this is cell of sub header or not.
   * This input works only at web/src/app/features/shared/components/editable-table/editable-table.component.ts.
   * @deprecated
   */
  @Input()
  public dartsalescTableHeaderIsSubHeader = false;
}
