import { BehaviorSubject, Observable, combineLatest, debounceTime, defer, finalize, first, iif, map, of, shareReplay, skip, switchMap, take } from 'rxjs';

import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';
import { Sort } from '@dartsales/common/core/models/list-utilities/sort';
import { ProcessingRequestsCounter } from '@dartsales/common/core/utils/processing-requests-counter';

/**
 * Abstract class for module page service.
 * This service is designed to be base service for all modules page service.
 * It should contain only shared logic for fetching and saving the module.
 * Hierarchy of subclasses may look like this:
 * - AbstractModulePageService -> PointsListModuleService.
 * - AbstractModulePageService -> AbstractExpensesModuleService -> EstimateExpensesModuleService.
 */
export abstract class AbstractModulePageService<TModule> {
  /** Parent ID. */
  public readonly abstract parentId$: Observable<number>;

  /** Id module data is loading or not. */
  public abstract readonly isLoading$: Observable<boolean>;

  /** Whether module is saving or not. */
  private readonly isSaving$ = new BehaviorSubject(false);

  /** Whether module is fetching data or not. */
  private readonly isFetching$ = new BehaviorSubject(false);

  private readonly triggerModuleReload$ = new BehaviorSubject<void>(undefined);

  /** Active requests counter. */
  protected readonly activeRequestsCounter = new ProcessingRequestsCounter();

  private readonly moduleInner$ = this.createInnerModuleStream();

  /** Module. */
  public readonly module$ = this.createModuleStream();

  /**
   * Number of processing module save requests.
   * We need this property to wait for module update if saving is processing.
   */
  protected readonly processingSaveRequestsCount$ = this.activeRequestsCounter.requestsCount$;

  /** Module sort. */
  public readonly sort$?: BehaviorSubject<Sort>;

  /**
   * Fetch module data.
   * @param parentId Parent ID.
   * @param sort Sort.
   */
  protected abstract fetchModuleData(parentId: number, sort: Sort | null): Observable<TModule>;

  /** Refresh module. */
  public refreshModule(): void {
    this.triggerModuleReload$.next();
  }

  /**
   * Save module data.
   * @param saveRequest Save request.
   * @param refreshModule Should refresh module.
   */
  public saveModuleData<T>(saveRequest: (parentId: number) => Observable<T>, refreshModule = true): Observable<void> {
    return defer(() => {
      this.activeRequestsCounter.increment();
      return this.parentId$;
    }).pipe(
      first(),
      switchMap(parentId => saveRequest(parentId).pipe(
        toggleExecutionState(this.isSaving$),
      )),
      switchMap(() => iif(
        () => refreshModule,
        this.reloadModuleData(),
        of(undefined),
      )),
      finalize(() => this.activeRequestsCounter.decrement()),
    );
  }

  /**
   * Create loading stream.
   * @param loaders Additional loading streams.
   */
  protected createModuleLoadingStream(loaders: readonly Observable<boolean>[] = []): Observable<boolean> {
    return combineLatest([
      this.isSaving$,
      this.isFetching$,
      ...loaders,
    ]).pipe(
      map(isLoadingList => isLoadingList.some(isLoading => isLoading)),
    );
  }

  private createModuleStream(): Observable<TModule> {
    // We want to delay module emit if new save requests were sent.
    return this.moduleInner$.pipe(
      switchMap(module => this.processingSaveRequestsCount$.pipe(
        debounceTime(500),
        first(count => count === 0),
        map(() => module),
      )),
      finalize(() => this.activeRequestsCounter.reset()),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private createInnerModuleStream(): Observable<TModule> {
    // 'parentId$' property is abstract, that's why we use 'defer'.
    const parentId$ = defer(() => this.parentId$);

    // 'sort$' property is optional, that's why we use 'defer'.
    const sort$ = defer(() => this.sort$ ?? of(null));

    // If multiple requests for reloading module were sent we want to debounce them.
    const moduleReloadReady$ = this.triggerModuleReload$.pipe(
      debounceTime(1000),
    );

    return combineLatest([
      parentId$,
      sort$,
      moduleReloadReady$,
    ]).pipe(
      switchMap(([parentId, sort]) => this.fetchModuleData(parentId, sort).pipe(
        toggleExecutionState(this.isFetching$),
      )),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private reloadModuleData(): Observable<void> {
    // We trigger reload and wait for fetched data.
    this.refreshModule();
    return this.moduleInner$.pipe(
      skip(1),
      take(1),
      map(() => undefined),
    );
  }
}
