import { BatchEditEntity } from '@dartsales/common/core/models/batch-edit-entity';

import { PointsListSystemGroup } from '../system-group/points-list-system-group';

import { EditPointsListSystemDetails } from './edit-points-list-system-details';
import { PointsListSystem } from './points-list-system';

/** Points list system edit model. */
export class EditPointsListSystem {
  /** Order. */
  public readonly order?: number;

  /** System details. */
  public readonly data: EditPointsListSystemDetails;

  /**
   * Parent system groups ID. Pass this if you want to move system to another system group.
   * Leave this property empty in other cases.
   */
  public readonly estimateSystemGroupId?: PointsListSystemGroup['id'];

  public constructor(data: EditPointsListSystemInitArgs) {
    this.order = data.order;
    this.data = data.data;
    this.estimateSystemGroupId = data.estimateSystemGroupId;
  }
}

type EditPointsListSystemInitArgs = EditPointsListSystem;

/** Points list system batch edit model. */
export class BatchEditPointsListSystem extends BatchEditEntity<PointsListSystem['id'], EditPointsListSystem> { }
