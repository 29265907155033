<div class="amount-input-container container">
  @if (isResetAvailable) {
    <button
      mat-icon-button
      class="reset-button"
      type="button"
      title="Reset value"
      (click)="onResetButtonClick()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  }
  <ng-container>
    @if (units === amountCalcUnits.Amount) {
      <input
        class="amount-input body-2"
        [dartsalescInputMask]="amountInputMask"
        [formControl]="form.controls.amount"
        [class.amount-input_reset-available]="isResetAvailable"
      />
    }
    @if (units === amountCalcUnits.Percent) {
      <input
        class="amount-input body-2"
        [dartsalescInputMask]="percentInputMask"
        [formControl]="form.controls.percent"
        [class.amount-input_reset-available]="isResetAvailable"
      />
    }
  </ng-container>

  <mat-button-toggle-group
    class="container__toggle-group"
    [value]="units"
    (change)="unitsChange.emit($event.value)"
  >
    <mat-button-toggle
      class="toggle"
      [value]="amountCalcUnits.Percent"
    >
      <mat-icon class="toggle__icon">percent</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      class="toggle"
      [value]="amountCalcUnits.Amount"
    >
      <mat-icon class="toggle__icon">attach_money</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
