<div
  class="draggable-row"
  [dndType]="dragPayloadType"
  [dndDraggable]="dragPayload"
  [dndDisableDragIf]="isDisabled"
  (dndStart)="onDragStart($event)"
  (dndEnd)="onDragEnd($event)"
>
  <ng-content />

  <ng-template #dragPreview>
    <div class="drag-preview">
      <dartsalesw-draggable-item-preview
        [selectedItemsCount]="selectedItemsCount"
      >
        @if (singleItemDragTemplate; as template) {
          <ng-template #singleItemDragTemplate>
            <ng-container [ngTemplateOutlet]="template" />
          </ng-template>
        }
        @if (multipleItemsDragTemplate; as template) {
          <ng-template #multipleItemsDragTemplate>
            <ng-container [ngTemplateOutlet]="template" />
          </ng-template>
        }
      </dartsalesw-draggable-item-preview>
    </div>
  </ng-template>
</div>
