import { computed, ReadonlySignal, Signal, signal } from '@preact/signals-core';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';

import { AbstractCalculator } from './abstract-calculator';

/** Margin calculator initialization arguments. */
export type AbstractMarginCalculatorInitArgs<T> = {

  /**
   * Calculated cost of item
   * https://docs.google.com/spreadsheets/d/1VzxlBUTNTsKc2SRX-DNj0MGar7zejjTqUkTlMQooJso/edit#gid=0.
   **/
  readonly totalDirectCost: ReadonlySignal<number>;

  /** Initial gross margin and markup values. */
  readonly marginValue: T;

  /** Calc units. */
  readonly marginUnits?: AmountCalcUnits;

  /** Margin type. */
  readonly marginType?: MarginType;
};

type CreateMarginInstanceFn<TResult, TArgs> = (args: {
  readonly grossMargin?: TArgs;
  readonly markup?: TArgs;
} & TResult) => TResult;

/** Margin calculation. */
export abstract class AbstractMarginCalculator<TMargin, TMarginValues> extends AbstractCalculator<TMargin> {

  /** @inheritdoc */
  public override readonly result: ReadonlySignal<TMargin>;

  /** Value. */
  public readonly value: Signal<TMargin>;

  private readonly _units: Signal<AmountCalcUnits>;

  /** Units. */
  public readonly units: ReadonlySignal<AmountCalcUnits>;

  private readonly _marginType: Signal<MarginType>;

  /** Margin type. */
  public readonly marginType: ReadonlySignal<MarginType>;

  public constructor(
    initParams: AbstractMarginCalculatorInitArgs<TMargin>,
    private readonly createMarginInstance: CreateMarginInstanceFn<TMargin, TMarginValues>,
  ) {
    super();

    this.value = signal(initParams.marginValue);
    this._marginType = signal(initParams.marginType ?? MarginType.DEFAULT);
    this.marginType = computed(() => this._marginType.value);
    this._units = signal(initParams.marginUnits ?? AmountCalcUnits.DEFAULT);
    this.units = computed(() => this._units.value);

    this.result = computed(() => this.calculateResult(this.value.value));
  }

  /**
   * Set margin type.
   * @param margin Margin type.
   */
  public setMarginType(margin: MarginType): void {
    this._marginType.value = margin;
  }

  /**
   * Set margin units.
   * @param units Margin units.
   */
  public setUnits(units: AmountCalcUnits): void {
    this._units.value = units;
  }

  protected abstract calculateValueByAmount(amount: number): TMargin;

  /**
   * Set margin amount and recalculate all values.
   * @param amount Amount in $.
   */
  public setAmount(amount: number): void {
    this.value.value = this.calculateValueByAmount(amount);
  }

  /**
   * Change value by gross margin.
   * @param grossMargin Gross margin value.
   */
  public setGrossMargin(grossMargin: TMarginValues): void {
    this._marginType.value = MarginType.GrossMargin;
    this.value.value = this.calculateResult(this.createMarginInstance({
      ...this.value.peek(),
      grossMargin,
    }));
  }

  /**
   * Change value by markup.
   * @param markup Markup value.
   */
  public setMarkup(markup: TMarginValues): void {
    this._marginType.value = MarginType.Markup;
    this.value.value = this.calculateResult(this.createMarginInstance({
      ...this.value.peek(),
      markup,
    }));
  }
}
