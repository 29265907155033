import { Observable } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { PointsListAllEntitiesIds } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-all-entities-ids';
import { PointsListGroupsApiService } from '@dartsales/common/core/services/api/points-list/points-list-groups-api.service';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';

import { PointsListTransactionUndoCommand } from '../points-list-transaction-undo-command';

/** Remove points list entities command. */
export class RemoveEntitiesCommand extends PointsListTransactionUndoCommand<RemoveEntitiesCommandInitArgs> {
  private readonly pointsListGroupsApiService = this.injector.get(PointsListGroupsApiService);

  /** @inheritdoc */
  public override executeAction(): Observable<ApiTransactionId> {
    return this.pointsListGroupsApiService.delete(this.args.estimateId, this.args.payload);
  }
}

type RemoveEntitiesCommandInitArgs = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;

  /** Payload. */
  readonly payload: PointsListAllEntitiesIds;
};
