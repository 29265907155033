import { Injectable, inject } from '@angular/core';
import { first, map, Observable, of, switchMap } from 'rxjs';

import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';

import { EstimateId } from '../../../models/estimate/estimate';
import { StorageService } from '../../storage.service';

import { DEFAULT_ESTIMATE_SETTINGS } from './default-estimate-settings';

const ESTIMATE_SETTINGS_STORAGE_PREFIX = 'estimate-settings';

/**
 * Get estimate settings storage key.
 * @param id ID.
 */
function getEstimateSettingsStorageKey(id: EstimateId): string {
  return `${ESTIMATE_SETTINGS_STORAGE_PREFIX}-${id}`;
}

const MAX_NUMBER_OF_ESTIMATES = 100;

/** Estimate settings storage service. */
@Injectable({
  providedIn: 'root',
})
export class EstimateSettingsStorageService {

  private readonly storage = inject(StorageService);

  /**
   * Save estimate settings in local storage.
   * @param id Estimate ID.
   * @param data Estimate settings.
   */
  public save(id: EstimateId, data: EstimateSettingsLocalStorage): Observable<void> {
    return this.hasEstimateInStorage(id).pipe(
      first(),
      switchMap(hasEstimateInStorage => {
        if (hasEstimateInStorage) {
          return this.saveEstimateSettings(id, data);
        }
        return this.createEstimateSettings(id, data);
      }),
    );
  }

  /**
   * Get estimate settings from local storage.
   * @param id Estimate ID.
   */
  public get(id: EstimateId): Observable<EstimateSettingsLocalStorage> {
    return this.storage.get<EstimateSettingsLocalStorage>(getEstimateSettingsStorageKey(id)).pipe(
      map(localStorageValue => localStorageValue ?? DEFAULT_ESTIMATE_SETTINGS),
    );
  }

  private hasEstimateInStorage(id: EstimateId): Observable<boolean> {
    return this.storage.get<EstimateSettingsLocalStorage>(getEstimateSettingsStorageKey(id)).pipe(
      map(value => Boolean(value)),
    );
  }

  private saveEstimateSettings(id: EstimateId, data: EstimateSettingsLocalStorage): Observable<void> {
    return this.storage.save(getEstimateSettingsStorageKey(id), data);
  }

  private createEstimateSettings(id: EstimateId, data: EstimateSettingsLocalStorage): Observable<void> {
    return this.clearOverflowedStorage().pipe(
      switchMap(() => this.saveEstimateSettings(id, data)),
    );
  }

  private clearOverflowedStorage(): Observable<void> {
    return this.storage.getAll().pipe(
      switchMap(storage => {
        const estimatesSettingsStorage = storage.filter(([key]) =>
          key.includes(ESTIMATE_SETTINGS_STORAGE_PREFIX))
          .map(([key, value]) =>
            ([key, JSON.parse(value)] as [string, EstimateSettingsLocalStorage]));

        const numberOfEstimates = estimatesSettingsStorage.length;

        if (numberOfEstimates > MAX_NUMBER_OF_ESTIMATES) {
          const outdatedEstimateStorageKey = this.getOutdatedEstimateStorageKey(estimatesSettingsStorage);

          if (outdatedEstimateStorageKey != null) {
            return this.storage.remove(outdatedEstimateStorageKey);
          }
        }
        return of(undefined);
      }),
    );

  }

  private getOutdatedEstimateStorageKey(estimatesSettingsStorage: readonly [string, EstimateSettingsLocalStorage][]): string | null {
    const sortedEstimateSettingsStorage = this.sortEstimateSettingsBySaveTime(estimatesSettingsStorage);
    const outdatedEstimateSettings = sortedEstimateSettingsStorage.at(0);
    if (outdatedEstimateSettings == null) {
      return null;
    }
    const [key] = outdatedEstimateSettings;
    return key;
  }

  private sortEstimateSettingsBySaveTime(
    value: readonly [string, EstimateSettingsLocalStorage][],
  ): [string, EstimateSettingsLocalStorage][] {
    return [...value].sort(([_, value1], [__, value2]) => value1.meta.timestamp - value2.meta.timestamp);
  }
}
