import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LetDirective } from '@ngrx/component';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DndModule } from 'ngx-drag-drop';
import { AgGridAngular } from 'ag-grid-angular';
import { RxFor } from '@rx-angular/template/for';

import { MaterialModule } from './modules/material.module';
import { BaseTableComponent } from './components/base-table/base-table.component';
import { FormControlValidationMessageComponent } from './components/form-control-validation-message/form-control-validation-message.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { LoadingDirective } from './directives/loading.directive';
import {
  TableCellDirective,
  TableColumnDirective,
  TableHeaderDirective,
} from './directives/table';
import { BaseDialogComponent } from './components/base-dialog/base-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SharedPipesModule } from './pipes/pipes.module';
import { ChipsListComponent } from './components/chips-list/chips-list.component';
import { NumberRangeFormControlComponent } from './components/number-range-form-control/number-range-form-control.component';
import { DateRangeFilterInputComponent } from './components/search-filters/date-range-filter-input/date-range-filter-input.component';
import { OrganizationNameFilterInputComponent } from './components/search-filters/organization-name-filter-input/organization-name-filter-input.component';
import { SignalRenderDirective } from './directives/signal-render.directive';
import { TagFilterInputComponent } from './components/search-filters/tag-filter-input/tag-filter-input.component';
import { EnumFilterSingleSelectInputComponent } from './components/search-filters/enum-filter-single-select-input/enum-filter-single-select-input.component';
import { StringArrayFilterInputComponent } from './components/search-filters/string-array-filter-input/string-array-filter-input.component';
import { EnumFilterMultiSelectInputComponent } from './components/search-filters/enum-filter-multi-select-input/enum-filter-multi-select-input.component';
import { NumberRangeFilterInputComponent } from './components/search-filters/number-range-filter-input/number-range-filter-input.component';
import { DateFilterInputComponent } from './components/search-filters/date-filter-input/date-filter-input.component';
import { NumberFilterInputComponent } from './components/search-filters/number-filter-input/number-filter-input.component';
import { TextFilterInputComponent } from './components/search-filters/text-filter-input/text-filter-input.component';
import { FilterFormControlOutletComponent } from './components/search-filters/filter-form-control-outlet/filter-form-control-outlet.component';
import { ChipsListWithSuggestedComponent } from './components/chips-list-with-suggested/chips-list-with-suggested.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { AutocompleteFixedScrollDirective } from './directives/autocomplete-fixed-scroll.directive';
import { EditableTableCellComponent } from './components/editable-table-cell/editable-table-cell.component';
import { TableCellEditModeDirective } from './components/editable-table-cell/directives/table-cell-edit-mode.directive';
import { TableCellViewModeDirective } from './components/editable-table-cell/directives/table-cell-view-mode.directive';
import { TableCellInputDirective } from './components/editable-table-cell/directives/table-cell-input.directive';
import { TextTableCellComponent } from './components/editable-table-cell/components/text-table-cell/text-table-cell.component';
import { TableCellSuffixDirective } from './components/editable-table-cell/directives/table-cell-suffix.directive';
import { TableCellPrefixDirective } from './components/editable-table-cell/directives/table-cell-prefix.directive';
import { NumberTableCellComponent } from './components/editable-table-cell/components/number-table-cell/number-table-cell.component';
import { MaskedNumberTableCellComponent } from './components/editable-table-cell/components/masked-number-table-cell/masked-number-table-cell.component';
import { EditableTableComponent } from './components/editable-table/editable-table.component';
import { EditableTableColDirective } from './components/editable-table/directives/editable-table-column.directive';
import { BidCategoryFilterInputComponent } from './components/search-project-filters/bid-category-filter-input/bid-category-filter-input.component';
import { AutocompleteTableCellComponent } from './components/editable-table-cell/components/autocomplete-table-cell/autocomplete-table-cell.component';
import { EditableTableBodyRowDirective } from './components/editable-table/directives/editable-table-body-row.directive';
import { EditableTableCellDirective } from './components/editable-table/directives/editable-table-cell.directive';
import { EditableTableHeaderRowDirective } from './components/editable-table/directives/editable-table-header-row.directive';
import { EditableTableFooterRowDirective } from './components/editable-table/directives/editable-table-footer-row.directive';
import { PercentNumberTableCellComponent } from './components/editable-table-cell/components/percent-number-table-cell/percent-number-table-cell.component';
import { SingleSelectTableCellComponent } from './components/editable-table-cell/components/single-select-table-cell/single-select-table-cell.component';
import { MultiSelectTableCellComponent } from './components/editable-table-cell/components/multi-select-table-cell/multi-select-table-cell.component';
import { EditableTableStickyColumnCellDirective } from './components/editable-table/directives/editable-table-sticky-column-cell.directive';
import { EditableTableStickyRowDirective } from './components/editable-table/directives/editable-table-sticky-row.directive';
import { NumberArrayFilterInputComponent } from './components/search-filters/number-array-filter-input/number-array-filter-input.component';
import { PlaceholderRowComponent } from './components/editable-table/components/placeholder-row/placeholder-row.component';
import { BaseDialogFooterComponent } from './components/base-dialog/components/base-dialog-footer/base-dialog-footer.component';
import { ExpandCollapseIconComponent } from './components/expand-collapse-icon/expand-collapse-icon.component';
import { TemplateContextDirective } from './directives/template-context.directive';
import { PopoverModule } from './modules/popover.module';
import { TableCellBulkUpdateComponent } from './components/bulk-update/table-cell-bulk-update/table-cell-bulk-update.component';
import { EditableTableRowDragPreviewDirective } from './components/editable-table/directives/editable-table-row-drag-preview.directive';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { TableHeaderSortingButtonComponent } from './components/editable-table-cell/components/table-header-sorting-button/table-header-sorting-button.component';
import { ScrollToTopDirective } from './directives/scroll-to-top.directive';
import { ViewportRendererComponent } from './components/viewport-renderer/viewport-renderer.component';
import { ViewportRendererContainerDirective } from './components/viewport-renderer/viewport-renderer-container.directive';
import { TableColumnBulkUpdateComponent } from './components/table-column-bulk-update/table-column-bulk-update.component';
import { UserPermissionsDirective } from './directives/user-permissions.directive';
import { CurrentOrganizationPermissionsDirective } from './directives/current-organization-permissions.directive';
import { OrganizationPermissionsDirective } from './directives/organization-permissions.directive';
import { GrossMarginPercentNumberTableCellComponent } from './components/editable-table-cell/components/percent-number-table-cell/gross-margin-percent-number-table-cell.component';
import { AutoresizeTextareaDirective } from './directives/autoresize-textarea.directive';
import { HeaderCellContentComponent } from './components/ag-grid-table/components/header-cell-content/header-cell-content.component';
import { OverrideSingleSelectTableCellComponent } from './components/editable-table-cell/components/override-single-select-table-cell/override-single-select-table-cell.component';
import { OverridableAutocompleteTableCellComponent } from './components/editable-table-cell/components/overridable-autocomplete-table-cell/overridable-autocomplete-table-cell.component';
import { InputMaskDirective } from './directives/input-mask.directive';
import { ScrollAnchorDirective } from './directives/scroll-anchor.directive';
import { TableCellBulkUpdateRemoveComponent } from './components/bulk-update/table-cell-bulk-update-remove/table-cell-bulk-update-remove.component';
import { BulkUpdateIndicatorComponent } from './components/bulk-update/bulk-update-indicator/bulk-update-indicator.component';
import { MatMenuTriggerDirective } from './directives/mat-menu-trigger.directive';

const PRIVATE_DECLARATIONS: Type<unknown>[] = [
  ValidationMessageComponent,
  TextFilterInputComponent,
  NumberFilterInputComponent,
  DateFilterInputComponent,
  NumberRangeFilterInputComponent,
  EnumFilterMultiSelectInputComponent,
  EnumFilterSingleSelectInputComponent,
  StringArrayFilterInputComponent,
  NumberRangeFormControlComponent,
  DateRangeFilterInputComponent,
  OrganizationNameFilterInputComponent,
  TagFilterInputComponent,
  BidCategoryFilterInputComponent,
  ProgressBarComponent,
  EditableTableStickyColumnCellDirective,
  EditableTableStickyRowDirective,
  ProgressBarComponent,
  EditableTableStickyColumnCellDirective,
  EditableTableStickyRowDirective,
  NumberArrayFilterInputComponent,
];

const EXPORTED_DECLARATIONS: Type<unknown>[] = [
  // Components.
  BaseTableComponent,
  BaseDialogComponent,
  BaseDialogFooterComponent,
  FormControlValidationMessageComponent,
  ConfirmDialogComponent,
  ChipsListComponent,
  FilterFormControlOutletComponent,
  ChipsListWithSuggestedComponent,
  ProgressBarComponent,
  EditableTableComponent,
  EditableTableCellComponent,
  TextTableCellComponent,
  NumberTableCellComponent,
  MaskedNumberTableCellComponent,
  AutocompleteTableCellComponent,
  SingleSelectTableCellComponent,
  MultiSelectTableCellComponent,
  PercentNumberTableCellComponent,
  GrossMarginPercentNumberTableCellComponent,
  TableHeaderSortingButtonComponent,
  PlaceholderRowComponent,
  ExpandCollapseIconComponent,
  TableCellBulkUpdateComponent,
  TableCellBulkUpdateRemoveComponent,
  BulkUpdateIndicatorComponent,
  BreadcrumbsComponent,
  ViewportRendererComponent,
  TableColumnBulkUpdateComponent,
  HeaderCellContentComponent,
  OverrideSingleSelectTableCellComponent,
  OverridableAutocompleteTableCellComponent,

  // Directives.
  LoadingDirective,
  SignalRenderDirective,
  TableCellDirective,
  TableHeaderDirective,
  TableColumnDirective,
  AutocompleteFixedScrollDirective,
  TableCellEditModeDirective,
  TableCellViewModeDirective,
  TableCellInputDirective,
  TableCellPrefixDirective,
  TableCellSuffixDirective,
  EditableTableColDirective,
  EditableTableBodyRowDirective,
  EditableTableHeaderRowDirective,
  EditableTableFooterRowDirective,
  EditableTableCellDirective,
  EditableTableRowDragPreviewDirective,
  TemplateContextDirective,
  ScrollToTopDirective,
  ViewportRendererContainerDirective,
  UserPermissionsDirective,
  CurrentOrganizationPermissionsDirective,
  OrganizationPermissionsDirective,
  AutoresizeTextareaDirective,
  InputMaskDirective,
  ScrollAnchorDirective,
  MatMenuTriggerDirective,
];

const EXPORTED_MODULES: Type<unknown>[] = [
  CommonModule,
  MaterialModule,
  ReactiveFormsModule,
  SharedPipesModule,
  InfiniteScrollModule,
  LetDirective,
  PopoverModule,
  BreadcrumbComponent,
  BreadcrumbItemDirective,
  NgxMatSelectSearchModule,
  DndModule,
  AgGridAngular,
  RxFor,
  PortalModule,
];

/** Common module. Contains entities shared across applications. */
@NgModule({
  declarations: [...PRIVATE_DECLARATIONS, ...EXPORTED_DECLARATIONS],
  imports: [...EXPORTED_MODULES],
  exports: [...EXPORTED_DECLARATIONS, ...EXPORTED_MODULES],
})
export class CommonSharedModule { }
