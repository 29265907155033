@if (
  {
    options: (selectableOptions$ | async) ?? [],
    preselectedOptions: (preselectedOptions$ | async) ?? [],
  };
  as data
) {
  <div class="container">
    @if (!hideSearch) {
      <mat-form-field class="filter-search no-padding">
        <mat-icon matPrefix>search</mat-icon>
        <dartsalesw-search-input
          placeholder="Search options"
          [formControl]="searchControl"
        />
      </mat-form-field>
    }

    @if (data.options.length > 0) {
      <div class="options">
        @for (option of data.preselectedOptions; track option) {
          <mat-checkbox
            [checked]="true"
            [disabled]="true"
          >
            {{ option.label }}
          </mat-checkbox>
        }
        <mat-checkbox
          [checked]="areAllOptionsSelected(data.options)"
          [indeterminate]="
            areSomeOptionsSelected && !areAllOptionsSelected(data.options)
          "
          (change)="toggleAll(data.options)"
        >
          Select All
        </mat-checkbox>
        @for (option of data.options; track option) {
          <mat-checkbox
            class="child-option"
            [checked]="isOptionSelected(option)"
            (change)="changeOptionSelection(option, $event.checked)"
          >
            {{ option.label }}
          </mat-checkbox>
        }
      </div>
    } @else {
      <div class="no-options">No options found</div>
    }
  </div>
}
