import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';

import { OptionSelect } from '@dartsales/common/core/models/option-select';

/** Table header select. */
@Component({
  selector: 'dartsalesw-table-header-select',
  templateUrl: './table-header-select.component.html',
  styleUrls: [
    '../table-header-select-shared.css',
    './table-header-select.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderSelectComponent<T extends string> {

  /** Selected value. */
  @Input()
  public set selectedValue(value: T | null) {
    if (value != null) {
      this.formControl.patchValue(value);
    }
  }

  /** Options. */
  @Input()
  public options: readonly OptionSelect<T>[] = [];

  /** Selection change. */
  @Output()
  public readonly selectionChange = new EventEmitter<T>();

  private readonly fb = inject(NonNullableFormBuilder);

  /** Form control. */
  protected readonly formControl = this.fb.control<T | null>(null);
}
