@if (item) {
  <a
    mat-list-item
    class="list-item"
    [class.active]="isItemActive(item)"
    [class.are-children-active]="areChildrenActive(item)"
    [class.has-children]="item.children?.length"
    [class.expanded]="isExpanded"
    [class.top-level]="depth === 0"
    [class.collapsed]="collapsed"
    [routerLink]="item.path"
  >
    <div
      matListItemIcon
      class="list-item__offset"
      [class.no-icon]="!item.iconName && !item.svgIcon"
      [ngStyle]="{ 'margin-left': depth * 12 + 'px' }"
    ></div>
    @if (item.iconName || item.svgIcon) {
      <ng-template
        [ngTemplateOutlet]="item.customNavItemTemplate || defaultNavItem"
        [ngTemplateOutletContext]="{
          $implicit: item,
        }"
      />
      <ng-template #defaultNavItem>
        <mat-icon
          matListItemIcon
          fontSet="material-icons-outlined"
          class="list-item__icon"
          [svgIcon]="item.svgIcon ?? ''"
          [class.icon-with-offset]="depth > 0"
        >
          {{ item.iconName }}
        </mat-icon>
      </ng-template>
    }

    <div class="list-item__info info">
      <div
        class="info__label"
        [title]="item.label"
      >
        {{ item.label }}
      </div>
      @if (item.subTitle) {
        <div class="info__subtitle">
          {{ item.subTitle }}
        </div>
      }
    </div>
    @if (!collapsed && item.children?.length) {
      <button
        mat-icon-button
        type="button"
        (click)="toggleExpand($event)"
      >
        <span class="list-item__arrow">
          <mat-icon>expand_more</mat-icon>
        </span>
      </button>
    }
  </a>
}

<div
  class="list-item__children"
  [class.expanded]="isExpanded && !collapsed"
>
  @for (child of item?.children; track $index) {
    <dartsalesw-nav-list-item
      [item]="child"
      [depth]="depth + 1"
      [currentUrl]="currentUrl"
    />
  }
</div>
