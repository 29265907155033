<div class="actions">
  <div class="actions__buttons">
    <span class="body-2">Expand:</span>

    @for (action of actions.expand; track action.label; let index = $index) {
      @if (index !== 0) {
        <mat-divider
          class="actions__divider"
          [vertical]="true"
        />
      }
      <button
        class="actions__button"
        mat-button
        type="button"
        (click)="action.click()"
      >
        {{ action.label }}
      </button>
    }
  </div>

  <div class="actions__buttons">
    <span class="body-2">Collapse:</span>
    @for (action of actions.collapse; track action.label; let index = $index) {
      @if (index !== 0) {
        <mat-divider
          class="actions__divider"
          [vertical]="true"
        />
      }
      <button
        class="actions__button"
        mat-button
        type="button"
        (click)="action.click()"
      >
        {{ action.label }}
      </button>
    }
  </div>
</div>
