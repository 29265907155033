import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { assertNonNull } from '@dartsales/common/core/utils/assert-non-null';
import { calculateFractionByPercent } from '@dartsales/common/core/utils/percents';

import { AmountPercent } from '../../estimate/amount-percent';

import { DomainEscalationCalculator } from './domain-escalation-calculator';

/** Domain compound escalation calculator. */
export namespace DomainCompoundEscalationCalculator {
  type CompoundEscalationCalculatorParams = {

    /** Compound escalation. */
    readonly compoundEscalation: AmountPercent;
  } & StrictOmit<DomainEscalationCalculator.EscalationCalculatorParams,
    'escalationValue'
    | 'escalationUnits'
  >;

  type OverridableCompoundEscalationCalculatorParams = StrictOmit<CompoundEscalationCalculatorParams, 'compoundEscalation'> & {

    /** Compound escalation. */
    readonly compoundEscalation: Overridable<AmountPercent>;
  };

  /**
   * Calculate compound escalation.
   * @param args Arguments.
   */
  export function calculateCompoundEscalation({
    directCost,
    contingencyAmount,
    compoundEscalation,
  }: CompoundEscalationCalculatorParams): AmountPercent {
    const { percent } = compoundEscalation;
    const fraction = directCost + contingencyAmount;

    assertNonNull(percent, `Percent isn't provided to escalate calculation.`);
    return new AmountPercent({
      amount: calculateFractionByPercent(fraction, percent),
      percent,
    });
  }

  /**
   * Calculate overridable compound escalation.
   * @param args Arguments.
   */
  export function calculateOverridableCompoundEscalation({
    directCost,
    contingencyAmount,
    compoundEscalation,
  }: OverridableCompoundEscalationCalculatorParams): Overridable<AmountPercent> {
    return new Overridable({
      initial: calculateCompoundEscalation({
        directCost,
        contingencyAmount,
        compoundEscalation: compoundEscalation.initial,
      }),
      override: compoundEscalation.override !== null ? calculateCompoundEscalation({
        directCost,
        contingencyAmount,
        compoundEscalation: compoundEscalation.override,
      }) : null,
    });
  }
}
