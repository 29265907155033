import { computed } from '@preact/signals-core';

import { OverridableBasePricingCalculator, OverridableBasePricingCalculatorParams } from '@dartsales/common/core/models/calculators/overridable-base-pricing-calculator';
import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';

import { AmountPercent } from '../../estimate/amount-percent';
import { ModuleProperties } from '../../estimate/modules/module-properties';
import { DomainCostCalculator } from '../domain/domain-cost-calculator';

import { ModuleUnitPreferences } from './estimate-calculator-preferences';
import { EstimateCustomerOverheadCalculator } from './estimate-customer-overhead-calculator';

/** Estimate summary calculator params. */
export type EstimateSummaryCalculatorParams = {

  /** Customer margin params. */
  readonly customerMarginParams: {

    /** Customer margin value. */
    readonly value: AmountPercent;

    /** Customer overhead units. */
    readonly overheadUnits: AmountCalcUnits;

    /** Customer margin units. */
    readonly marginUnits: AmountCalcUnits;
  };
} & OverridableBasePricingCalculatorParams;

/** Estimate summary calculator. */
export class EstimateSummaryCalculator extends OverridableBasePricingCalculator {

  /** Customer overhead calculator. */
  public readonly customerOverheadCalc: EstimateCustomerOverheadCalculator;

  public constructor(initParams: EstimateSummaryCalculatorParams) {
    super(initParams);

    this.customerOverheadCalc = new EstimateCustomerOverheadCalculator({
      totalDirectCost: computed(() => DomainCostCalculator.calculateTotalDirectCost({
        directCost: this.directCostCalc.result.value.combinedValue,
        contingencyAmount: this.contingencyCalc.result.value.combinedValue.amount,
        escalationAmount: this.escalationCalc.result.value.combinedValue.amount,
      })),
      sellPrice: computed(() => this.result.value.sellPrice.combinedValue),
      margin: initParams.customerMarginParams.value,
      marginType: initParams.marginParams.marginType,
      marginUnits: initParams.customerMarginParams.marginUnits,
      overheadUnits: initParams.customerMarginParams.overheadUnits,
    });
  }

  /**
   * Creates summary params.
   * @param args Arguments.
   */
  public static createParams(args: {
    readonly properties: ModuleProperties;
    readonly preferences?: ModuleUnitPreferences;
    readonly marginType?: MarginType;
  }): EstimateSummaryCalculatorParams {
    const { properties, preferences } = args;

    return {
      ...OverridableBasePricingCalculator.createParamsByPricing({
        originalPricing: properties,
        contingencyUnits: preferences?.contingencyUnits,
        escalationUnits: preferences?.escalationUnits,
        marginUnits: preferences?.marginUnits,
        marginType: args.marginType,
      }),
      customerMarginParams: {
        value: properties.customerMarkup,
        marginUnits: preferences?.customerMarginUnits ?? AmountCalcUnits.DEFAULT,
        overheadUnits: preferences?.customerOverheadUnits ?? AmountCalcUnits.DEFAULT,
      },
    };
  }
}
