import { enumToArray } from '../utils/enum-to-array';

/** Labor role rate type. */
export enum LaborRoleRateType {
  Regular = 'Regular',
  OvertimeOne = 'OvertimeOne',
  OvertimeTwo = 'OvertimeTwo',
}

/** Namespace related to LaborRoleRateType. */
export namespace LaborRoleRateType {
  const TO_TITLE_MAP: Record<LaborRoleRateType, string> = {
    [LaborRoleRateType.Regular]: 'Reg',
    [LaborRoleRateType.OvertimeOne]: 'OT1',
    [LaborRoleRateType.OvertimeTwo]: 'OT2',
  };

  /**
   * Converts a certain rate type to readable title.
   * @param value Rate type.
   */
  export function toReadable(value: LaborRoleRateType): string {
    return TO_TITLE_MAP[value];
  }

  /** Gets list of all rate types. */
  export function toArray(): LaborRoleRateType[] {
    return enumToArray(LaborRoleRateType);
  }
}
