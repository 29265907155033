<span class="header-units-label">
  {{ shortMarginTypeLabel }}
</span>
<mat-select
  class="header-units-select"
  panelClass="units-select-panel"
  [formControl]="markupTypeControl"
  (selectionChange)="onOptionSelected($event.value)"
>
  @for (item of markupTypeOptions$ | async; track item) {
    <mat-option
      class="header-units-select__option"
      [value]="item.value"
    >
      {{ item.label }}
    </mat-option>
  }
</mat-select>

@if (isEditMode) {
  <div
    class="input-container"
    [class.input-container_display]="
      markupTypeControl.value.units === amountCalcUnits.Percent
    "
  >
    @if (cellPrefix) {
      <div class="prefix">
        <ng-content select="[dartsalescTableCellPrefix]" />
      </div>
    }
    <input
      *ngrxLet="
        markupTypeControl.value.marginType ===
        marginType.GrossMargin as displayGrossMargin
      "
      #gmInput
      class="input-container__input"
      [class.cell__has-prefix]="cellPrefix && displayGrossMargin"
      [dartsalescInputMask]="grossMarginMask"
      [class.input-container__input_display]="displayGrossMargin"
      [formControl]="marginPercentsControl.controls.grossMargin"
      [readonly]="isLockedValue"
      (blur)="onInputBlur($event)"
      (keydown.enter)="gmInput.blur(); $event.stopPropagation()"
    />
    <input
      *ngrxLet="
        markupTypeControl.value.marginType ===
        marginType.Markup as displayMarkup
      "
      #markupInput
      class="input-container__input"
      [dartsalescInputMask]="markupMask"
      [class.cell__has-prefix]="cellPrefix && displayMarkup"
      [class.input-container__input_display]="displayMarkup"
      [formControl]="marginPercentsControl.controls.markup"
      [readonly]="isLockedValue"
      (blur)="onInputBlur($event)"
      (keydown.enter)="markupInput.blur(); $event.stopPropagation()"
    />
  </div>
} @else {
  <dartsalesw-table-header-readonly-cell
    label="Average Markup percent"
    [value]="value"
  />
}
