<mat-chip-grid
  #chipGrid
  aria-label="Member selection"
  [class.chips-container]="controlValue?.length"
  [disabled]="searchControl.disabled"
>
  @for (item of controlValue; track $index; let index = $index) {
    <mat-chip-row (removed)="removeChipItem(index)">
      <span [title]="item">
        {{ item }}
      </span>
      @if (!disabled) {
        <button
          matChipRemove
          type="button"
          [attr.aria-label]="'remove ' + item"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      }
    </mat-chip-row>
  }
</mat-chip-grid>
@if (!disabled) {
  <div
    #origin="matAutocompleteOrigin"
    matAutocompleteOrigin
    class="search"
    matTooltipPosition="above"
    matTooltipClass="array-input-tooltip"
    [matTooltip]="tooltipText"
    [matTooltipDisabled]="shouldDisableTooltip"
  >
    <input
      matInput
      class="search__input"
      type="text"
      [maxlength]="maxLength"
      [placeholder]="placeholder"
      [formControl]="searchControl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin"
      (click)="focus()"
      (matChipInputTokenEnd)="addChipItem($event)"
    />
    <mat-icon class="search__icon">arrow_drop_down</mat-icon>
  </div>
}
<mat-autocomplete
  #auto
  dartsalescFixedScroll
  class="chips-autocomplete"
  [hideSingleSelectionIndicator]="true"
  (optionSelected)="onOptionSelected($event)"
>
  <div class="progress-bar">
    @if (isLoading) {
      <mat-progress-bar mode="indeterminate" />
    }
  </div>
  @if (optionsList) {
    <div
      infiniteScroll
      class="options"
      infiniteScrollContainer=".chips-autocomplete"
      [fromRoot]="true"
      [infiniteScrollDisabled]="!auto.isOpen"
      (scrolled)="onOptionsScroll()"
    >
      <div class="hint">{{ suggestedText }}</div>
      @for (item of optionsList; track item) {
        <mat-option [value]="item">
          {{ item }}
        </mat-option>
      }
    </div>
  }
</mat-autocomplete>
