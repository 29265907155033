import { Observable, BehaviorSubject, combineLatest, merge, of } from 'rxjs';
import { map, withLatestFrom, tap, switchMap, shareReplay, debounceTime, catchError } from 'rxjs/operators';

import { FetchListOptions } from '../../models/list-utilities/fetch-list-options';
import { PagedList } from '../../models/list-utilities/paged-list';
import { PaginationData } from '../../models/list-utilities/pagination-data';
import { Sort } from '../../models/list-utilities/sort';
import { toggleExecutionState } from '../rxjs/toggle-execution-state';

import { FetchItemsApiRequest, ListManagerInitArgs } from './list-manager-types';
import { IListHandleStrategy } from './list-strategies';

type SortMap = ReadonlyMap<Sort['field'], Sort['direction']>;

/**
 * Provide functionality to work with lists.
 * Handle pagination, filters and sorting.
 */
export class ListManager<TItem, TFilter = void> {

  /** Emits information about page pagination. */
  public readonly pagePagination$: Observable<PaginationData>;

  /** Emits value of selected filters. */
  public readonly filter$: Observable<TFilter>;

  /** Emits value of selected sort. */
  public readonly sort$: Observable<readonly Sort[]>;

  /** Emits value of selected sort in format of Map[field, direction]. */
  public readonly sortMap$: Observable<SortMap>;

  /** List loading state. */
  public readonly isLoading$: Observable<boolean>;

  /** List items. */
  public readonly items$: Observable<TItem[]>;

  /** Reset pagination params. */
  public readonly resetPaginationParams$: Observable<[readonly Sort[] | undefined, TFilter]>;

  /** Is list empty. */
  public readonly isEmpty$: Observable<boolean>;

  /** Can load more items. */
  public readonly canLoadMoreItems$: Observable<boolean>;

  private readonly loadingInner$ = new BehaviorSubject<boolean>(true);

  private readonly reload$ = new BehaviorSubject<void>(undefined);

  private readonly pagination$: BehaviorSubject<PaginationData>;

  private readonly sortInner$: BehaviorSubject<readonly Sort[]>;

  private readonly currentValueInner$ = new BehaviorSubject<TItem[]>([]);

  private readonly listStrategy: IListHandleStrategy<TItem>;

  private readonly request: FetchItemsApiRequest<TItem, TFilter>;

  /** Is first page. */
  public get isFirstPage(): boolean {
    return this.pagination$.value.page === 1;
  }

  /** Is last page. */
  public get isLastPage(): boolean {
    const pagination = this.pagination$.value;
    return this.checkIsLastPage(pagination);
  }

  /**
   * Some notes about ListManger filters, sorting and pagination.
   * ### Filters
   * There are three options for filters:
   * - `initialFilters` - static filters, can't be changed after initialization;
   * - `filter$` - dynamic filters inside RxJS stream;
   * - 'empty' - filter will be always 'undefined' (which means empty in this context).
   *
   * It's important that you should provide **either one** 'initialFilters' or 'filter$' not both
   * (if both will be passed then 'initialFilters' will be used).
   *
   * ### Sorting
   * Sorting is managed by ListManager internally. You can pass initial sorting value using `initialSort` option. \
   * You can update sorting using 'sortChanged' method.
   *
   * ### Pagination
   * Pagination is managed by ListManager internally. You can pass initial pagination value using `pagination` option. \
   * You can change pagination using several different methods of ListManager.
   * @param options Options.
   */
  public constructor(options: ListManagerInitArgs<TItem, TFilter>) {
    this.listStrategy = options.strategy;
    this.request = options.request;

    this.sortInner$ = this.createSortInner(options.initialSort);
    this.pagination$ = new BehaviorSubject(options.pagination ?? new PaginationData());

    if (options.initialFilter) {
      this.filter$ = new BehaviorSubject<TFilter>(options.initialFilter);
    } else if (options.filter$) {
      this.filter$ = options.filter$;
    } else {
      this.filter$ = new BehaviorSubject<TFilter>(undefined as TFilter);
    }

    this.sort$ = this.sortInner$.asObservable();
    this.sortMap$ = this.createSortMap();
    this.isLoading$ = this.loadingInner$.asObservable();

    this.resetPaginationParams$ = combineLatest([
      this.sort$,
      this.filter$,
    ]).pipe(
      debounceTime(400),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.pagePagination$ = merge(
      this.pagination$,
      this.resetPaginationParams$.pipe(
        map(() => this.getDefaultPagination()),
      ),
    ).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.items$ = this.getPaginatedItems();
    this.isEmpty$ = this.createIsEmptyStream();
    this.canLoadMoreItems$ = this.createCanLoadMoreItemsStream();
  }

  /**
   * Sort changed.
   * @param sort Updated sort.
   */
  public sortChanged(sort?: readonly Sort[]): void {
    this.sortInner$.next(sort ?? []);
  }

  /**
   * Pagination changed.
   * @param pagination Updated pagination.
   * @param triggerReload Should reload the list.
   */
  public paginationChanged(pagination: PaginationData, triggerReload = false): void {
    this.pagination$.next(pagination);
    if (triggerReload) {
      this.reload$.next();
    }
  }

  /** Manually reload current page. */
  public reloadCurrentPage(): void {
    this.reload$.next();
  }

  /** Go to next page. */
  public nextPage(): void {
    const currentPagination = this.pagination$.value;
    if (!this.isLastPage) {
      const nextPagination = new PaginationData({
        page: this.pagination$.value.page + 1,
        pageSize: currentPagination.pageSize,
      });
      this.paginationChanged(nextPagination, true);
    }
  }

  /** Go to prev page. */
  public prevPage(): void {
    const currentPagination = this.pagination$.value;
    if (!this.isFirstPage) {
      const nextPagination = new PaginationData({
        page: this.pagination$.value.page - 1,
        pageSize: currentPagination.pageSize,
      });
      this.paginationChanged(nextPagination, true);
    }
  }

  /** Return to first page. */
  public returnToFirstPage(): void {
    const nextPagination = this.getDefaultPagination();
    this.paginationChanged(nextPagination, true);
  }

  /**
   * Get list of paginated items from server.
   * Be aware that all errors are handled internally. So getPaginatedItems doesn't emit any errors related to data fetching.
   */
  private getPaginatedItems(): Observable<TItem[]> {
    return this.reload$.pipe(
      switchMap(() => this.resetPaginationParams$),
      withLatestFrom(this.pagePagination$),
      switchMap(([[sort, filter], pagination]) => this.requestData(this.request, { sort, filter, pagination })),
      tap(pagedList => this.paginationChanged(pagedList.pagination)),
      map(pagedList => this.listStrategy.handle(pagedList)),
      tap(list => this.currentValueInner$.next(list)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /**
   * Request data.
   * @param func Function to use for data request.
   * @param options Options.
   */
  private requestData(func: FetchItemsApiRequest<TItem, TFilter>, options: FetchListOptions<TFilter>): Observable<PagedList<TItem>> {
    return func(options).pipe(
      toggleExecutionState(this.loadingInner$),
      catchError(() => of(new PagedList({
        pagination: options.pagination ?? new PaginationData(),
        items: this.currentValueInner$.value,
      }))),
    );
  }

  /** Reset pagination. */
  private getDefaultPagination(): PaginationData {
    const currentPagination = this.pagination$.value;
    return new PaginationData({
      page: 1,
      pageSize: currentPagination.pageSize,
      totalCount: currentPagination.totalCount,
    });
  }

  private checkIsLastPage(pagination: PaginationData): boolean {
    return pagination.page * pagination.pageSize >= pagination.totalCount;
  }

  private createIsEmptyStream(): Observable<boolean> {
    return this.items$.pipe(
      map(items => items.length === 0),
    );
  }

  private createCanLoadMoreItemsStream(): Observable<boolean> {
    return combineLatest([
      this.pagination$,
      this.isLoading$,
    ]).pipe(
      map(([pagination, isLoading]) => !this.checkIsLastPage(pagination) || isLoading),
    );
  }

  private createSortMap(): Observable<SortMap> {
    return this.sort$.pipe(
      map(sort => new Map(
        sort.map(item => [item.field, item.direction]),
      )),
    );
  }

  private createSortInner(sort?: readonly Sort[]): BehaviorSubject<readonly Sort[]> {
    if (sort == null) {
      const emptySortArray: readonly Sort[] = [];
      return new BehaviorSubject(emptySortArray);
    }
    return new BehaviorSubject(sort);
  }
}
