import { Directive, ViewChild, Input } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { BaseCellEditorComponent } from '../../base-components/base-cell-editor.component';

/** Abstract select cell editor component. */
@Directive()
export abstract class AbstractSelectCellEditorComponent<T> extends BaseCellEditorComponent<T> {

  @ViewChild(MatSelect)
  private readonly select?: MatSelect;

  /** Placeholder. */
  @Input()
  public placeholder = 'Select value';

  /** @inheritdoc */
  protected override readonly setFocus = (): void => {
    this.select?.focus();
    this.select?.open();
  };

  /** @inheritdoc */
  protected override checkIsFocusOut = (event: FocusEvent): boolean => {
    // Important!
    // We have to set 'tabindex="0"' attribute in '<mat-option>' element to make it focusable.
    // Otherwise, 'relatedTarget' will have incorrect value.
    // Options list wrapper element will be targeted instead of '<mat-option>' element.
    const relatedTarget = event.relatedTarget as HTMLElement | null;

    // 'mat-option' elements capture focus, we need to ignore such events.
    const isMatOptionFocused = relatedTarget?.tagName === 'MAT-OPTION';

    return event.target !== this.document.activeElement && !isMatOptionFocused;
  };

  /**
   * Handle selection change.
   * @param change Selection change.
   */
  protected handleSelectionChange(change: MatSelectChange): void {
    const value = change.value as T;
    this.valueChange.emit(value);
  }
}
