import { Injectable, inject } from '@angular/core';

import { AppConfig } from '../app.config';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {

  private readonly appConfigService = inject(AppConfig);

  // Please prefer the following way of defining urls scope:
  // public readonly scope = {
  //   getById: (id: string) => this.toApi('scope', id),
  //   getList: this.toApi('scope/list/'),
  // };

  /** Endpoints for hubs. */
  public readonly hubs = {
    messages: this.toApi('/hubs/sales/estimates-hub'),
    baseEstimates: this.toApi('/hubs/sales/base-estimates'),
  };

  /** Endpoints for Authorization API. */
  public readonly auth = {
    login: this.toApi('auth'),
    refreshSecret: this.toApi('auth'),
    resetPassword: this.toApi('auth/forgot-password'),
    confirmPasswordReset: this.toApi('auth/reset-password'),
  } as const;

  /** Endpoints for update personal user info. */
  public readonly personalInfo = {
    update: this.toApi('personal'),
  } as const;

  /** Endpoints for getting/editing current user's info. */
  public readonly userAuth = {
    currentProfile: this.toApi('auth'),
    changePassword: this.toApi('users/change-password'),
  } as const;

  /** Endpoints for projects. */
  public readonly projectsApi = {
    getRecent: this.toApi('sales/base-estimates/recent'),
    getList: this.toApi('sales/base-estimates'),
    getById: (id: number) => this.toApi('sales/base-estimates', String(id)),
    create: this.toApi('sales/base-estimates'),
    update: (id: number) => this.toApi('sales/base-estimates', String(id)),
    clone: (id: number) => this.toApi('sales/base-estimates', String(id), 'duplicate'),
    delete: (id: number) => this.toApi('sales/base-estimates', String(id)),
    updateStatus: (id: number) => this.toApi('sales/base-estimates', String(id), 'status'),
  } as const;

  public readonly opportunitiesApi = {
    createOpportunity: (id: number) => this.toApi('sales', 'base-estimates', String(id), 'opportunity-id'),
    permanentLink: (opportunityId: number) => this.toCrmApi('opportunities-single', String(opportunityId)),
  } as const;

  /** Endpoints for estimate templates. */
  public readonly templatesApi = {
    getList: this.toApi('sales/base-estimates/template'),
    getById: (id: number) => this.toApi('sales/base-estimates/template', String(id)),
    create: this.toApi('sales/base-estimates/template'),
    delete: (id: number) => this.toApi('sales/base-estimates/template', String(id)),
  } as const;

  /** Endpoints for organizations. */
  public readonly organizationsApi = {
    getById: (id: number) => this.toApi('organizations', String(id)),
    getList: this.toApi('organizations-list'),
    getUsersList: (id: number) => this.toApi('organizations', String(id), 'users'),
  } as const;

  private readonly estimatesApiBase = this.toApi('sales/estimates');

  /** Common estimate api. */
  public readonly commonEstimateApi = {
    updateDetails: (estimateId: number) => this.toApi(this.estimatesApiBase, String(estimateId), 'description'),
  } as const;

  /** Endpoints for alternate estimates. */
  public readonly alternateEstimatesApi = {
    get: (alternateEstimateId: number) => this.toApi(this.estimatesApiBase, 'alternate', String(alternateEstimateId)),
    edit: (alternateEstimateId: number) => this.toApi(this.estimatesApiBase, 'alternate', String(alternateEstimateId)),
    getList: (baseEstimateId: number) => this.toApi(this.estimatesApiBase, String(baseEstimateId), 'alternate'),
    create: (baseEstimateId: number) => this.toApi(this.estimatesApiBase, String(baseEstimateId), 'alternate'),
    delete: (alternateId: number) => this.toApi(this.estimatesApiBase, 'alternate', String(alternateId)),
    getInfoAboutNewAlternate: (baseEstimateId: number) => this.toApi(this.estimatesApiBase, String(baseEstimateId), 'alternate-name'),
  } as const;

  /** Endpoints for final estimates. */
  public readonly finalEstimatesApi = {
    create: (baseEstimateId: number) => this.toApi(this.estimatesApiBase, String(baseEstimateId), 'final'),
    getPreview: (baseEstimateId: number) => this.toApi(this.estimatesApiBase, String(baseEstimateId), 'final-preview'),
    get: (finalEstimateId: number) => this.toApi(this.estimatesApiBase, 'final', String(finalEstimateId)),
    update: (finalEstimateId: number) => this.toApi(this.estimatesApiBase, 'final', String(finalEstimateId)),
    delete: (finalEstimateId: number) => this.toApi(this.estimatesApiBase, 'final', String(finalEstimateId)),
    getList: (baseEstimateId: number) => this.toApi(this.estimatesApiBase, 'base-estimate', String(baseEstimateId), 'final'),
    award: (baseEstimateId: number, finalEstimateId: number) => this.toApi(
      this.estimatesApiBase, 'base-estimate', String(baseEstimateId), 'final', 'awarded', String(finalEstimateId),
    ),
    getAwarded: (baseEstimateId: number) => this.toApi(
      this.estimatesApiBase, 'base-estimate', String(baseEstimateId), 'final', 'awarded',
    ),
    updateAwarded: (baseEstimateId: number, finalEstimateId: number) => this.toApi(
      this.estimatesApiBase, 'base-estimate', String(baseEstimateId), 'final', 'awarded', String(finalEstimateId),
    ),
  } as const;

  /**  Endpoints for fork with custom module items API. */
  public customModuleItemsApi = {
    create: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'custom/items'),
    update: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'custom/items'),
    delete: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'custom/items'),
    updateItemsPercents: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'custom/items/percents'),
    updateOrder: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'custom/items/order-batch-update'),
    updateLockStatusPercents: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'custom/percent-locks'),
  } as const;

  /** Endpoints for work with custom module. */
  public customModuleApi = {
    get: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'custom'),
  } as const;

  /** Endpoints for change orders of estimate. */
  public readonly changeOrdersApi = {
    getList: (baseEstimateId: number) => this.toApi(this.estimatesApiBase, String(baseEstimateId), 'change-order'),
    getById: (changeOrderId: number) => this.toApi(this.estimatesApiBase, 'change-order', String(changeOrderId)),
    create: (finalEstimateId: number) => this.toApi(this.estimatesApiBase, 'final', String(finalEstimateId), 'change-order'),
    update: (changeOrderId: number) => this.toApi(this.estimatesApiBase, 'change-order', String(changeOrderId)),
    delete: (changeOrderId: number) => this.toApi(this.estimatesApiBase, 'change-order', String(changeOrderId)),
  } as const;

  /** Terms endpoints. */
  public readonly termsApi = {
    termCollection: this.toApi('sales/estimate-terms'),
    termDetails: (termId: number) => this.toApi('sales/estimate-terms', String(termId)),
    termDuplicate: (termId: number) => this.toApi('sales/estimate-terms', String(termId), 'duplicate'),
    createAlternateFromTerm: (termId: number) => this.toApi('sales/estimate-terms', String(termId), 'create-alternate'),
    createAlternatesFromAllTerms: this.toApi('sales/estimate-terms/create-alternates-from-all'),
    updateOrder: this.toApi('sales/estimate-terms/order-batch-update'),
    renumber: (estimateId: number) => this.toApi('sales/estimate-terms', String(estimateId), 'renumber'),
    updateCompoundEscalation: this.toApi('sales/estimate-terms/compound-escalation'),
  } as const;

  /** Term modules endpoints. */
  public readonly termModulesApi = {
    getCustom: (termId: number) => this.toApi('sales', 'estimate-terms', String(termId), 'custom-summary'),
    getExpenses: (termId: number) => this.toApi('sales', 'estimate-terms', String(termId), 'expenses-summary'),
    getLabor: (termId: number) => this.toApi('sales', 'estimate-terms', String(termId), 'labor-summary'),
    getMaterial: (termId: number) => this.toApi('sales', 'estimate-terms', String(termId), 'material-summary'),
    getSubcontractor: (termId: number) => this.toApi('sales', 'estimate-terms', String(termId), 'subcontractor-summary'),
  } as const;

  /** Estimate services endpoints. */
  public readonly estimateServicesApi = {
    create: this.toApi('sales/estimate-services'),
    getTermServices: this.toApi('sales/estimate-services'),
    export: (serviceId: number) => this.toApi('sales/estimate-services', String(serviceId), 'export'),
    getById: (serviceId: number) => this.toApi('sales/estimate-services', String(serviceId)),
    update: (serviceId: number) => this.toApi('sales/estimate-services', String(serviceId)),
    delete: (serviceId: number) => this.toApi('sales/estimate-services', String(serviceId)),
    createFromTemplates: this.toApi('sales/estimate-services/from-templates'),
    saveToTemplate: (serviceId: number) => this.toApi('sales/estimate-services', String(serviceId), 'save-to-template'),
    createServicePart: (serviceId: number) => this.toApi('sales/estimate-services', String(serviceId), 'parts'),
    createServicePartFromSymbols: (serviceId: number) => this.toApi('sales/estimate-services', String(serviceId), 'parts/from-symbols'),
    deleteServicePart: (serviceId: number, servicePartId: string) =>
      this.toApi('sales/estimate-services', String(serviceId), 'parts', String(servicePartId)),
    getTemplates: this.toApi('sales/estimate-services/templates'),
    getTemplate: (serviceId: number) => this.toApi('sales/estimate-services/templates', String(serviceId)),
    deleteTemplates: this.toApi('sales/estimate-services/templates'),
    restoreTemplate: (serviceId: number) => this.toApi('sales/estimate-services/templates', String(serviceId)),
    updateTemplatesOrder: this.toApi('sales/estimate-services/templates/order-batch-update'),
    getLaborInfo: (serviceId: number) => this.toApi('sales/estimate-services/templates', String(serviceId), 'labor-info'),
    getMaterialInfo: (serviceId: number) => this.toApi('sales/estimate-services/templates', String(serviceId), 'material-info'),
  } as const;

  /** Endpoints for PointsList module. */
  public readonly pointsListApi = {
    getByEstimateId: (id: number) => this.toApi(`sales/estimates/${id}/points-list`),
    delete: (id: number) => this.toApi(`sales/estimates/${id}/points-list`),
    duplicate: (id: number) => this.toApi(`sales/estimates/${id}/points-list/duplicate`),
    ungroup: (id: number) => this.toApi(`sales/estimates/${id}/points-list/ungroup`),

    tabs: {
      get: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/tabs/summaries`),
      getByTabId: (estimateId: number, tabId: string) => this.toApi(`sales/estimates/${estimateId}/points-list/tabs/${tabId}`),
      create: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/tabs`),
      update: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/tabs`),
      duplicate: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/tabs/duplicate`),
    },

    systemGroups: {
      create: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/system-groups`),
      update: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/system-groups`),
      duplicate: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/system-groups/duplicate`),
      move: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/system-groups/move`),
    },

    systems: {
      create: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/systems`),
      update: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/systems`),
      duplicate: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/systems/duplicate`),
      move: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/systems/move`),
      createFromPalette: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/systems/from-palette`),
      updateTaskRates: (estimateId: number) =>
        this.toApi(`sales/estimates/${estimateId}/points-list/systems/task-rates`),
    },

    partGroups: {
      create: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/part-groups`),
      update: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/part-groups`),
      duplicate: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/part-groups/duplicate`),
      createFromPalette: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/part-groups/from-palette`),
      move: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/part-groups/move`),
    },

    parts: {
      create: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/parts`),
      update: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/parts`),
      duplicate: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/parts/duplicate`),
      createFromPalette: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/parts/from-palette`),
      move: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/parts/move`),
    },

    bulkUpdate: {
      getInfo: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/tabs/bulk-update-info`),
      update: (estimateId: number) => this.toApi(`sales/estimates/${estimateId}/points-list/bulk-update`),
    },

    getSubcontractorsInfo:
      (estimateId: number) => this.toApi('sales', 'estimates', String(estimateId), 'subcontractor', 'calculation-info'),
  } as const;

  /** Endpoints for CustomPorts API. */
  public readonly customPortsApi = {
    getList: this.toApi(`custom-ports/categories`),
  } as const;

  /** Endpoints for getting/editing documents. */
  public readonly documentsApi = {
    getRecentFilesList: (id: number) => this.toApi('sales/estimate', String(id), 'files/recent'),
    getDocumentsList: (id: number) => this.toApi('sales/estimate', String(id), 'files'),
    updateFile: (id: number, estimateFileId: string) => this.toApi('sales/estimate', String(id), 'files', String(estimateFileId)),
    deleteFile: (id: number, estimateFileId: string) => this.toApi('sales/estimate', String(id), 'files', String(estimateFileId)),
    updateFolder: (id: number, estimateFolderId: string) => this.toApi('sales/estimate', String(id), 'folders', String(estimateFolderId)),
    deleteFolder: (id: number, estimateFolderId: string) => this.toApi('sales/estimate', String(id), 'folders', String(estimateFolderId)),
    createFolder: (id: number) => this.toApi('sales/estimate', String(id), 'folders'),
    uploadFile: (estimateId: number) => this.toApi('sales/estimate', String(estimateId), 'files'),
    confirmUploadFile: (id: number, fileId: string) => this.toApi('sales/estimate', String(id), 'files', String(fileId), 'confirm-upload'),
    downloadFile: (id: number, estimateFileId: string) =>
      this.toApi('sales/estimate', String(id), 'files', String(estimateFileId), 'download-url'),
    downloadZip: (id: number) =>
      this.toApi('sales/estimate', String(id), 'zip'),
  } as const;

  /** Endpoints for base estimate API. */
  public readonly baseEstimateApi = {
    getByEstimateId: (id: number) => this.toApi('sales/base-estimates', String(id), 'modules'),
    getChangeOrderCustomColumns: (id: number) => this.toApi('sales/base-estimates', String(id), 'change-orders/custom-columns'),
    createChangeOrderCustomColumn: (id: number) => this.toApi('sales/base-estimates', String(id), 'change-orders/custom-columns'),
    updateChangeOrderCustomColumn: (id: number, columnId: string) => this.toApi(
      'sales/base-estimates', String(id), 'change-orders/custom-columns', columnId,
    ),
    deleteChangeOrderCustomColumns: (id: number, columnId: string) => this.toApi(
      'sales/base-estimates', String(id), 'change-orders/custom-columns', columnId,
    ),
    getAlternatesCounter: (id: number) => this.toApi('sales/base-estimates', String(id), 'alternates-counter'),
    getEstimatesToMerge: (id: number) => this.toApi('sales/base-estimates', String(id), 'estimates-to-merge'),
  };

  /** Endpoints for estimate modules API. */
  public readonly estimateModulesApi = {
    getExpenses: (id: number) => this.toApi('sales/estimates', String(id), 'expenses'),
    saveExpenses: (id: number) => this.toApi('sales/estimates', String(id), 'expenses'),
    getTotals: (id: number) => this.toApi('sales/estimates', String(id), 'totals'),
    saveTotals: (id: number) => this.toApi('sales/estimates', String(id), 'totals'),
    getPointsList: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'points-list'),
    updateOverrides: (id: number) => this.toApi('sales/estimates', String(id), 'modules-overrides'),
    merge: (estimateId: number) => this.toApi('sales/estimates', String(estimateId), 'merge'),
  };

  /** Endpoints for material modules API. */
  public readonly materialModulesApi = {
    standardPart: {
      updateStandardPartsGroup: (estimateId: number, standardMaterialId: string) =>
        this.toApi('sales/estimates', String(estimateId), 'material/standard/part-groups', standardMaterialId),
      updateStandardGroup: (estimateId: number) =>
        this.toApi('sales/estimates', String(estimateId), 'material/standard/groups'),
    },
    nonStandardPart: {
      updateNonStandardPart: (estimateId: number, nonStandardMaterialId: string) =>
        this.toApi('sales/estimates', String(estimateId), 'material/non-standard/parts', nonStandardMaterialId),
      createNonStandardPart: (estimateId: number) =>
        this.toApi('sales/estimates', String(estimateId), 'material/non-standard/parts'),
      deleteNonStandardPart: (estimateId: number, nonStandardMaterialId: string) =>
        this.toApi('sales/estimates', String(estimateId), 'material/non-standard/parts', nonStandardMaterialId),
    },
    getMaterial: (estimateId: number) => this.toApi('sales/estimates', String(estimateId), 'material'),
    updateMaterialPercents: (estimateId: number) =>
      this.toApi('sales/estimates', String(estimateId), 'material/percents'),
    updateLockStatusPercents: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'material/percent-locks'),
  };

  /** Endpoints for work with subcontractor module API. */
  public readonly subcontractorModuleApi = {
    get: (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor'),
    applyTableBulkUpdate: (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor', 'bulk-update'),
    updateLockStatusPercents: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'subcontractor/percent-locks'),
  } as const;

  /** Endpoints for work with subcontractor lump sum pricing items API. */
  public readonly subcontractorLumpSumPricingApi = {
    create: (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor', 'lump-sum-pricings'),
    batchUpdate: (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor', 'lump-sum-pricings'),
    delete: (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor', 'lump-sum-pricings'),
    updateItemsPercents:
      (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor', 'lump-sum-pricings', 'percents'),
  } as const;

  /** Endpoints for work with subcontractor unit pricing items API. */
  public readonly subcontractorUnitPricingApi = {
    create: (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor', 'unit-pricings'),
    batchUpdate: (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor', 'unit-pricings'),
    delete: (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor', 'unit-pricings'),
    updateItemsPercents:
      (parentId: number) => this.toApi('sales', 'estimates', String(parentId), 'subcontractor', 'unit-pricings', 'percents'),
  } as const;

  /** Endpoints for labor module API. */
  public readonly laborModuleApi = {
    module: {
      get: (estimateId: number) => this.toApi('sales/estimates', String(estimateId), 'labor'),
      updatePercents: (estimateId: number) =>
        this.toApi('sales/estimates', String(estimateId), 'labor/roles/percents'),
      updateLockStatusPercents: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'labor/roles/percent-locks'),
      applyBulkUpdate: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'labor/bulk-update'),
    },
    role: {
      getList: (estimateId: number) => this.toApi('sales/estimates', String(estimateId), 'labor/roles'),
      update: (estimateId: number) => this.toApi('sales/estimates', String(estimateId), 'labor/roles'),
    },
    task: {
      create: (estimateId: number) => this.toApi('sales/estimates', String(estimateId), 'labor/tasks/custom'),
      update: (estimateId: number) => this.toApi('sales/estimates', String(estimateId), 'labor/tasks'),
      delete: (estimateId: number) => this.toApi('sales/estimates', String(estimateId), 'labor/tasks/custom'),
    },
  };

  /** Endpoints for the palette components API. */
  public readonly paletteComponentsApi = {
    getSystemsFromPalette: this.toApi(`sales/palette-components/systems`),
    getPartsSymbolsFromPalette: this.toApi(`sales/palette-components/parts-symbols`),
    getTagsFromPalette: this.toApi(`sales/palette-components/tags`),
    getManufacturersFromPalette: this.toApi(`sales/palette-components/manufacturers`),
    getOrganizationsFromPalette: this.toApi(`sales/palette-components/organizations`),
    getSuppliersFromPalette: this.toApi(`sales/palette-components/suppliers`),
    getDetailedComponent: (uuid: string) => this.toApi(`library/components/${uuid}/info`),
    updateDetailedComponent: (uuid: string) => this.toApi(`library/components/${uuid}`),
  } as const;

  /** Endpoints for the estimate components API. */
  public readonly estimateComponentsApi = {
    getComponents: this.toApi('sales/project-components'),
    getComponentsByProjectId: (projectId: number) => this.toApi('sales/project-components', projectId.toString()),
  } as const;

  /** Endpoints for the estimate material groups API. */
  public readonly estimateMaterialGroupsApi = {
    getGroups: (estimateId: number) => this.toApi(`sales/material-groups/estimates/${estimateId}`),
    createGroup: this.toApi(`sales/material-groups`),
    updateGroup: (groupId: number) => this.toApi(`sales/material-groups/${groupId}`),
    deleteGroup: (groupId: number) => this.toApi(`sales/material-groups/${groupId}`),
    updateOrder: this.toApi('sales/material-groups/order-batch-update'),
  } as const;

  /** Endpoints for the estimate systems API. */
  public readonly estimateSystemsApi = {
    createSystem: this.toApi('sales/components/systems'),
    getSystemById: (systemId: string) => this.toApi('sales/components/systems', systemId),
    updateSystemById: (systemId: string) => this.toApi('sales/components/systems', systemId),
    createSystemFromPalette: this.toApi('sales/components/systems/from-palette'),
    duplicateSystems: this.toApi('sales/components/systems/duplicate'),
    updateSystems: this.toApi('sales/components/systems/batch'),
    deleteSystems: this.toApi('sales/components/systems/batch'),
  } as const;

  /** Endpoints for the estimate expenses API. */
  public readonly estimateExpensesApi = {
    getTravelCosts: (id: number) => this.toApi('sales/estimates', String(id), 'expenses/travel-costs'),
    createTravelCost: (id: number) => this.toApi('sales/estimates', String(id), 'expenses/travel-costs'),
    updateTravelCosts: (estimateId: number) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/travel-costs'),
    deleteTravelCost: (estimateId: number, travelCostId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/travel-costs', String(travelCostId)),

    getEmployees: (id: number) => this.toApi('sales/estimates', String(id), 'expenses/employees'),
    createEmployee: (id: number) => this.toApi('sales/estimates', String(id), 'expenses/employees'),
    updateEmployee: (estimateId: number, employeeId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/employees', String(employeeId)),
    deleteEmployee: (estimateId: number, employeeId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/employees', String(employeeId)),
    duplicateEmployee: (estimateId: number, employeeId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/employees', String(employeeId), 'duplicate'),

    updateRoles: (estimateId: number) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/labor-roles'),

    getOverrides: (estimateId: number, employeeId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/travel-costs/override', String(employeeId)),
    updateOverride: (estimateId: number, employeeId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/travel-costs/override', String(employeeId)),

    updateSummariesPercents: (estimateId: number) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/summaries/percents'),

    applyModuleBulkUpdate: (estimateId: number) =>
      this.toApi('sales/estimates', String(estimateId), 'expenses/bulk-update'),
    updateLockStatusPercents: (parentId: number) => this.toApi('sales/estimates', String(parentId), 'expenses/summaries/percent-locks'),
  } as const;

  /** Endpoints for getting tutorials video. */
  public readonly tutorialsApi = {
    getCategoryList: this.toApi('tutorials/categories'),
    getYoutubeVideo: (videoId: string) => `https://youtube.com/oembed?url=${videoId}&format=json`,
  } as const;

  /** Endpoints for getting/editing subcontractor. */
  public readonly subcontractorApi = {
    getList: this.toApi('sales/subcontractors'),
    create: this.toApi('sales/subcontractors'),
    update: (id: number) => this.toApi('sales/subcontractors', String(id)),
    delete: (id: number) => this.toApi('sales/subcontractors', String(id)),
    updateOrder: this.toApi('sales/subcontractors', 'order-batch-update'),
  } as const;

  /** Endpoints for getting/editing labor tasks. */
  public readonly laborTasksApi = {
    getList: this.toApi('sales/labor-tasks'),
    create: this.toApi('sales/labor-tasks'),
    update: (id: number) => this.toApi('sales/labor-tasks', String(id)),
    delete: (id: number) => this.toApi('sales/labor-tasks', String(id)),
    updateOrder: this.toApi('sales/labor-tasks', 'order-batch-update'),
  };

  /** Endpoints for getting/editing labor roles. */
  public readonly laborRoleApi = {
    getList: this.toApi('sales/labor-roles'),
    create: this.toApi('sales/labor-roles'),
    update: (id: number) => this.toApi('sales/labor-roles', String(id)),
    delete: (id: number) => this.toApi('sales/labor-roles', String(id)),
    updateOrder: this.toApi('sales/labor-roles', 'order-batch-update'),
  };

  /** Endpoints for getting/editing bid category. */
  public readonly bidCategoryApi = {
    getAvailableForUser: this.toApi('sales/bid-categories/user-available'),
    getList: this.toApi('sales/bid-categories'),
    create: this.toApi('sales/bid-categories'),
    update: (id: number) => this.toApi('sales/bid-categories', String(id)),
    delete: (id: number) => this.toApi('sales/bid-categories', String(id)),
    updateOrder: this.toApi('sales/bid-categories', 'order-batch-update'),
  };

  /** Endpoints for expense types. */
  public readonly expenseTypesApi = {
    getList: (organizationId: number) => this.toApi('sales/expense-types', String(organizationId)),
    create: (organizationId: number) => this.toApi('sales/expense-types', String(organizationId)),
    update: (expenseTypeId: number) => this.toApi('sales/expense-types', String(expenseTypeId)),
    delete: (expenseTypeId: number) => this.toApi('sales/expense-types', String(expenseTypeId)),
    updateOrder: this.toApi('sales/expense-types', 'order-batch-update'),
  };

  /** Endpoints for getting/editing bidders. */
  public readonly biddersApi = {
    getList: this.toApi('sales/bidders/search'),
    create: this.toApi('sales/bidders'),
    delete: (id: number) => this.toApi('sales/bidders', String(id)),
  };

  /** Endpoints for getting/editing system classification. */
  public readonly systemClassificationApi = {
    getList: this.toApi('sales/system-classifications'),
    create: this.toApi('sales/system-classifications'),
    update: (id: number) => this.toApi('sales/system-classifications', String(id)),
    delete: (id: number) => this.toApi('sales/system-classifications', String(id)),
    updateOrder: this.toApi('sales/system-classifications', 'order-batch-update'),
  } as const;

  /** Endpoints for getting/editing tags. */
  public readonly tagsApi = {
    getTags: this.toApi('library', 'tags', 'search'),
  } as const;

  /** Endpoints for getting/editing products types. */
  public readonly productsTypesApi = {
    getProductsTypes: this.toApi('sales', 'product-types', 'search'),
  } as const;

  /** Endpoints for getting/editing service categories. */
  public readonly serviceCategoriesApi = {
    getList: this.toApi('sales', 'service-categories'),
    getById: (id: number) => this.toApi('sales', 'service-categories', String(id)),
    create: this.toApi('sales', 'service-categories'),
    update: (id: number) => this.toApi('sales', 'service-categories', String(id)),
    delete: (id: number) => this.toApi('sales', 'service-categories', String(id)),
    updateOrder: this.toApi('sales/service-categories', 'order-batch-update'),
    subCategoriesApi: {
      create: this.toApi('sales', 'service-categories', 'sub-categories'),
      getById: (id: number) => this.toApi('sales', 'service-categories', 'sub-categories', String(id)),
      update: (id: number) => this.toApi('sales', 'service-categories', 'sub-categories', String(id)),
      delete: (id: number) => this.toApi('sales', 'service-categories', 'sub-categories', String(id)),
      updateOrder: this.toApi('sales/service-categories', 'sub-categories', 'order-batch-update'),
    },
  } as const;

  /** Endpoints for export. */
  public readonly exportApi = {
    getServicesTree: (estimateId: number) =>
      this.toApi('sales', 'estimates', 'export-multiple', 'services-options-tree', String(estimateId)),
    estimates: {
      finalEstimate: (id: number) => this.toApi('sales', 'estimates', 'final', String(id), 'export'),
      changeOrder: (id: number) => this.toApi('sales', 'estimates', 'change-order', String(id), 'export'),
      baseEstimate: (id: number) => this.toApi('sales', 'base-estimates', String(id), 'export'),
      alternateEstimate: (id: number) => this.toApi(this.estimatesApiBase, 'alternate', String(id), 'export'),
    },
    exportMultiple: this.toApi(this.estimatesApiBase, 'export-multiple'),
    modules: {
      subcontractor: (parentId: number) => this.toApi(this.estimatesApiBase, String(parentId), 'subcontractor', 'export'),
      labor: (parentId: number) => this.toApi(this.estimatesApiBase, String(parentId), 'labor', 'export'),
      custom: (parentId: number) => this.toApi(this.estimatesApiBase, String(parentId), 'custom', 'export'),
      material: (parentId: number) => this.toApi(this.estimatesApiBase, String(parentId), 'material', 'export'),
      expenses: (parentId: number) => this.toApi(this.estimatesApiBase, String(parentId), 'per-diem', 'export'),
    },
    pointsList: (estimateId: number) => this.toApi(this.estimatesApiBase, String(estimateId), 'points-list', 'export'),
    service: (serviceId: number) => this.toApi('sales', 'estimate-services', String(serviceId), 'export'),
    subcategory: (subcategoryId: number) => this.toApi('sales', 'service-categories', 'sub-categories', String(subcategoryId), 'export'),
    term: (termId: number) => this.toApi('sales', 'estimate-terms', String(termId), 'export'),
    termSummaries: (termId: number) => this.toApi('sales', 'estimate-terms', String(termId), 'module-summaries', 'export'),
  } as const;

  /** Routes for getting/editing library component attachment. */
  public readonly libraryComponentAttachmentApi = {
    getAttachments: this.toApi('library', 'component-attachments'),
    createAndUploadFile: this.toApi('library', 'component-attachments', 'upload '),
    create: this.toApi('library', 'component-attachments'),
    uploadUrl: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId, 'upload-url'),
    confirmUploadFile: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId, 'confirm-upload'),
    downloadFile: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId),
    downloadFileUrl: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId, 'download-url'),
    permanentLink: (attachmentId: string) => this.toApi('library', 'component-attachments', 'permanent-links', attachmentId),
    update: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId),
    delete: (componentId: string) => this.toApi('library', 'component-attachments', componentId),
  } as const;

  /** Routes for getting/editing estimate component attachment. */
  public readonly estimateComponentAttachmentApi = {
    create: (estimateId: number) => this.toApi('sales/estimates', String(estimateId), 'points-list/attachments'),
    confirmUploadFile: (estimateId: number, attachmentId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'points-list/attachments', attachmentId, 'confirm-upload'),
    downloadFileUrl: (estimateId: number, attachmentId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'points-list/attachments', attachmentId, 'download-url'),
    downloadFile: (estimateId: number, attachmentId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'points-list/attachments', attachmentId),
    uploadUrl: (estimateId: number, attachmentId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'points-list/attachments', attachmentId, 'upload-url'),
    permanentLink: (estimateId: number, attachmentId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'points-list/attachments/permanent-links', attachmentId),
    delete: (estimateId: number, attachmentId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'points-list/attachments', attachmentId),
    update: (estimateId: number, attachmentId: string) =>
      this.toApi('sales/estimates', String(estimateId), 'points-list/attachments', attachmentId),
  } as const;

  /** Endpoints for transactions API. */
  public readonly transactionsApi = {
    undo: (transactionId: string) => this.toApi('sales', 'transactions', transactionId, 'undo'),
    redo: (transactionId: string) => this.toApi('sales', 'transactions', transactionId, 'redo'),
  } as const;

  /**
   * Checks whether the url is application-scoped.
   * @param url Url to check.
   */
  public isApplicationUrl(url: string): boolean {
    return url.startsWith(this.appConfigService.apiUrl);
  }

  /**
   * Checks whether the specified url is calling an auth-related endpoint.
   * @param url Url to check.
   */
  public isAuthUrl(url: string): boolean {
    return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
  }

  private toApi(...args: readonly string[]): string {
    const path = args.join('/');
    return new URL(path, this.appConfigService.apiUrl).toString();
  }

  private toCrmApi(...args: readonly string[]): string {
    const path = args.join('/');
    return new URL(path, this.appConfigService.crmUrl).toString();
  }
}
