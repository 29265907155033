import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { CatalogItemsSelectionService } from '../../../project-dashboard/services/catalog-items-selection.service';

/** Catalog selected items chip component. */
@Component({
  selector: 'dartsalesw-catalog-selected-items-chip',
  templateUrl: './catalog-selected-items-chip.component.html',
  styleUrls: ['./catalog-selected-items-chip.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogSelectedItemsChipComponent {
  /** Catalog items selection service. */
  protected readonly catalogItemsSelectionService = inject(CatalogItemsSelectionService);
}
