<mat-sidenav-container
  *ngrxLet="isSidebarOpened$ | async as isSidebarOpened"
  class="sidenav-wrapper"
>
  <mat-sidenav
    opened
    mode="side"
    class="project-sidebar"
    [class.mini]="!isSidebarOpened"
  >
    @if (project$ | async; as project) {
      <div
        class="top-section"
        [class.expanded]="true"
      >
        @if (organization$ | async; as organization) {
          <dartsalesw-organization-avatar
            class="top-section__logo"
            [avatarUrl]="project.projectInfo.details.organizationLogoUrl"
          />
          <div class="top-section__title">
            <h4>{{ organization.name }}</h4>
          </div>
        }
      </div>
    }
    <mat-nav-list class="project-sidebar__navigation">
      @for (
        item of navigationListItems$ | async;
        track item;
        let index = $index
      ) {
        <dartsalesw-nav-list-item
          [class.category]="item.hasDivider"
          [item]="item"
          [currentUrl]="currentUrl$ | async"
          [collapsed]="!isSidebarOpened"
        />
      }
    </mat-nav-list>
  </mat-sidenav>
  <!-- We need to override the Angular Material style for "mat-sidenav-content". -->
  <!-- eslint-disable @angular-eslint/template/no-inline-styles -->
  <mat-sidenav-content
    class="project-sidebar-content"
    style="margin-left: none"
    [class.sidebar-mini]="!isSidebarOpened"
  >
    <ng-content />
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template
  #estimateEditor
  let-item
>
  @if (project$ | async; as project) {
    <button
      mat-icon-button
      class="list-item-button"
      type="button"
      (click)="onNavigateButtonClick($event, project)"
    >
      <mat-icon
        matListItemIcon
        fontSet="material-icons-outlined"
        class="list-item-button__icon"
        [svgIcon]="item.svgIcon ?? ''"
      >
        {{ item.iconName }}
      </mat-icon>
    </button>
  }
</ng-template>
