import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { NotificationMessage } from '@dartsales/common/core/models/notification-message';
import { NotificationsService } from '@dartsales/common/core/services/notifications.service';

/** Notifications button component. */
@Component({
  selector: 'dartsalesw-notifications-button',
  templateUrl: './notifications-button.component.html',
  styleUrls: ['./notifications-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsButtonComponent {
  private readonly notificationsService = inject(NotificationsService);

  /** Notification messages. */
  protected readonly notifications$ = this.notificationsService.unreadMessages$;

  /**
   * Get messages count.
   * @param messages Messages.
   */
  protected getMessagesCount(messages: readonly NotificationMessage[] | null): string {
    if (messages === null) {
      return '';
    }
    if (messages.length < 10) {
      return String(messages.length);
    }

    return '9+';
  }

  /** Handle notifications panel close. */
  protected onNotificationsPanelClose(): void {
    this.notificationsService.updateLastReadDate(new Date());
  }
}
