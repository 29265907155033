import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TabLink } from '@dartsales/common/core/models/tab-link';

/** Navigation tabs component. */
@Component({
  selector: 'dartsalesw-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTabsComponent {
  private readonly route = inject(ActivatedRoute);

  /** Tab links. */
  @Input()
  public links: TabLink[] | null = [];

  /** Query params. */
  public readonly queryParams$ = this.route.queryParams;
}
