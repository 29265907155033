@if (cellPrefix) {
  <div class="prefix">
    <ng-content select="[dartsalescTableCellPrefix]" />
  </div>
}
@if (isEditMode$ | async) {
  <dartsalesc-masked-number-cell-editor
    [class.cell__has-prefix]="cellPrefix"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    [mask]="percentMask"
  />
} @else {
  <dartsalesc-masked-number-cell-renderer
    [class.cell__has-prefix]="cellPrefix"
    [mask]="percentMask"
    [isFocusable]="(isFocusable$ | async) ?? false"
    [value]="formControl.value"
  />
}
