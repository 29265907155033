<dartsalesc-table-cell-bulk-update
  [tooltipText]="tooltipText"
  [isUpdateConfirmed]="areAllRowsConfirmed(rows)"
  [maxWidth]="maxWidth"
  (applyUpdateClick)="bulkUpdate.emit(true)"
  (discardUpdateClick)="bulkUpdate.emit(false)"
>
  @if (hasRemoveMessage(rows)) {
    <p>
      {{ getRemoveMessage(rows) }}
    </p>
  }
  @if (hasUpdateMessage(rows)) {
    <p>
      {{ getUpdateMessage(rows) }}
    </p>
  }
  <p>Do you want to apply update for all changes in this table?</p>
</dartsalesc-table-cell-bulk-update>
