<mat-form-field class="no-label">
  <mat-select
    placeholder="Select"
    [multiple]="true"
    [formControl]="innerFormControl"
  >
    @for (item of bidCategoryIdsOptions$ | async; track $index) {
      <mat-option [value]="item.value">
        {{ item.label }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
