<div class="notification">
  <mat-icon
    svgIcon="checkmark-circle"
    class="notification__check-icon"
  />

  <div
    class="notification__label"
    matSnackBarLabel
  >
    <div class="notification__body">
      <h3 class="subtitle-2">{{ data.title }}</h3>

      <p class="body-2">{{ data.message }}</p>

      @if (data.link; as link) {
        <a
          mat-button
          color="primary"
          class="notification__view-link"
          [routerLink]="link"
          (click)="close()"
        >
          Open
        </a>
      }
    </div>
  </div>

  <button
    mat-icon-button
    type="button"
    matSnackBarAction
    class="notification__close-button"
    matTooltip="Close"
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
