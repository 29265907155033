<ng-container
  *ngrxLet="{
    isRendererMode: (isEditMode$ | async) === false,
    isFocusable: (isFocusable$ | async) ?? false,
  } as data"
>
  @if (data.isRendererMode) {
    <dartsalesc-multi-select-cell-renderer
      [value]="controlValue"
      [options]="options"
      [class.focusable]="data.isFocusable"
      [isFocusable]="data.isFocusable"
    />
  } @else {
    <dartsalesc-multi-select-cell-editor
      [errorStateMatcher]="errorStateMatcher"
      [compareWith]="compareWith"
      [options]="options"
      [value]="controlValue ?? null"
      (valueChange)="controlValue = $event"
    />
  }
</ng-container>
