import mime from 'mime';

/** Common mime type. */
export const COMMON_MIME_TYPE = 'application/octet-stream';

/** PDF file type. */
export const PDF_MIME_TYPE = 'application/pdf';

/**
 * Get mime type from file name.
 * @param fileName File name.
 */
export function getMimeType(fileName: string): string {
  return mime.getType(fileName) ?? COMMON_MIME_TYPE;
}

/**
 * Map extension to readable.
 * @param mimeType MimeType.
 */
export function mimeTypeToReadableExtension(mimeType: string): string {
  const placeholder = '-';
  const extension = mime.getExtension(mimeType);

  if (mimeType === COMMON_MIME_TYPE || !extension) {
    return placeholder;
  }

  return extension.toUpperCase();
}
