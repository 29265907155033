@if (control) {
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      placeholder="••••••"
      [autocomplete]="autocomplete"
      [formControl]="control"
      [type]="shouldShowPassword ? 'text' : 'password'"
    />
    <button
      mat-icon-button
      matIconSuffix
      type="button"
      (click)="onToggleButtonClick()"
    >
      <mat-icon fontSet="material-icons-outlined">
        {{ shouldShowPassword ? "visibility" : "visibility_off" }}
      </mat-icon>
    </button>
    <mat-error>
      <dartsalesc-form-control-validation-message [control]="control" />
    </mat-error>
  </mat-form-field>
}
