<div
  class="layout layout_expanded"
  [class.layout_hidden]="!isExpanded"
>
  <header class="header">
    <h2 class="title subtitle-1">{{ headerTitle }}</h2>
    <div class="buttons">
      @if (isOpenInNewButtonDisplayed) {
        <button
          mat-icon-button
          type="button"
          class="button"
          (click)="openInNewButtonClick.emit()"
        >
          <mat-icon class="button__icon">open_in_new</mat-icon>
        </button>
      }

      <dartsalesw-horizontal-expand-button
        class="expand-button"
        [isExpanded]="isExpanded"
        (buttonClick)="toggle()"
      />
    </div>
  </header>

  <ng-content />
</div>

<div
  class="layout layout_collapsed"
  [class.layout_hidden]="isExpanded"
>
  <dartsalesw-horizontal-expand-button
    class="expand-button"
    [isExpanded]="isExpanded"
    (buttonClick)="toggle()"
  />

  <h2 class="layout__heading caption-2">{{ headerTitle }}</h2>
</div>
