import { Injectable, inject } from '@angular/core';

import { PointsListSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system';
import { BatchEditPointsListSystem, EditPointsListSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system';
import { CreatePointsListSystem, CreatePointsListSystemFromPalette } from '@dartsales/common/core/models/estimate/modules/points-list/system/create-points-list-system';

import { BatchEditPointsListSystemDto, CreatePointsListSystemDto, CreatePointsListSystemFromPaletteDto, EditPointsListSystemDto, PointsListSystemDto } from '../../../dto/estimate/modules/points-list/system/points-list-system.dto';
import { IMapper } from '../../../mappers';

import { PointsListSystemDetailsMapper } from './points-list-system-details.mapper';
import { PointsListPartGroupMapper } from './points-list-part-group.mapper';
import { PointsListSystemPropertiesMapper } from './points-list-system-properties.mapper';

/** Points list system mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemMapper implements IMapper<PointsListSystemDto, PointsListSystem> {
  private readonly pointsListSystemDetailsMapper = inject(PointsListSystemDetailsMapper);

  private readonly pointsListSystemPropertiesMapper = inject(PointsListSystemPropertiesMapper);

  private readonly pointsListPartGroupMapper = inject(PointsListPartGroupMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListSystemDto): PointsListSystem {
    return new PointsListSystem({
      id: dto.id,
      systemGroupId: dto.systemGroupId,
      paletteComponentId: dto.paletteComponentId,
      order: dto.order,
      data: this.pointsListSystemDetailsMapper.fromDto(dto.data),
      properties: this.pointsListSystemPropertiesMapper.fromDto(dto.properties),
      partGroups: dto.partGroups.map(group => this.pointsListPartGroupMapper.fromDto(group)),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListSystem): PointsListSystemDto {
    return {
      id: data.id,
      systemGroupId: data.systemGroupId,
      paletteComponentId: data.paletteComponentId,
      order: data.order,
      data: this.pointsListSystemDetailsMapper.toDto(data.data),
      partGroups: data.partGroups.map(group => this.pointsListPartGroupMapper.toDto(group)),
      properties: this.pointsListSystemPropertiesMapper.toDto(data.properties),
    };
  }

  /**
   * To create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreatePointsListSystem): CreatePointsListSystemDto {
    return {
      data: this.pointsListSystemDetailsMapper.toCreateDto(data.data),
      order: data.order,
    };
  }

  /**
   * To create from palette DTO.
   * @param data Data.
   */
  public toCreateFromPaletteDto(data: CreatePointsListSystemFromPalette): CreatePointsListSystemFromPaletteDto {
    return {
      order: data.order,
      systems: data.paletteSystemIds.map(id => ({
        paletteSystemId: id,
      })),
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListSystem): EditPointsListSystemDto {
    return {
      data: this.pointsListSystemDetailsMapper.toEditDto(data.data),
      order: data.order,
      estimateSystemGroupId: data.estimateSystemGroupId,
    };
  }

  /**
   * To batch edit DTO.
   * @param data Data.
   */
  public toBatchEditDto(data: BatchEditPointsListSystem): BatchEditPointsListSystemDto {
    return {
      systemId: data.id,
      system: this.toEditDto(data.value),
    };
  }
}
