@if (columns[0]; as column) {
  <ng-container [dartsalescEditableTableCol]="column.name">
    <ng-container
      *dartsalescEditableTableCell="let col; colSpan: columns.length"
    >
      <div class="placeholder">
        {{ placeholder }}
      </div>
    </ng-container>
  </ng-container>
}

@for (column of columns.slice(1); track column.name) {
  <ng-container [dartsalescEditableTableCol]="column.name">
    <ng-container *dartsalescEditableTableCell="let col; colSpan: 0" />
  </ng-container>
}
