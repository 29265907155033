@if (cellPrefix) {
  <div class="prefix">
    <ng-content select="[dartsalescTableCellPrefix]" />
  </div>
}
@if ((isEditMode$ | async) === false) {
  <dartsalesc-masked-number-cell-renderer
    [class.cell__has-prefix]="cellPrefix"
    [shouldHideValue]="hideValue"
    [value]="controlValue"
    [mask]="mask"
    [placeholder]="placeholder"
    [isFocusable]="(isFocusable$ | async) ?? false"
  />
} @else {
  @if (controlValue !== null) {
    <dartsalesc-masked-number-cell-editor
      [class.cell__has-prefix]="cellPrefix"
      [errorStateMatcher]="errorStateMatcher"
      [mask]="mask"
      [formControl]="formControl"
      [placeholder]="placeholder"
    />
  }
}
