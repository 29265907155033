import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject, OnInit, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { Observable, map, BehaviorSubject, combineLatest, shareReplay } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSelect } from '@angular/material/select';

import { LaborRoleRateType } from '@dartsales/common/core/enums/labor-role-rate-type';
import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { PointsListSystemTaskRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-task-rate';
import { CURRENCY_MASK } from '@dartsales/common/core/utils/constants';
import { PointsListLaborRoleRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-labor-role-rate';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { LaborRoleInternalRates } from '@dartsales/common/core/models/resources/labor-role-internal-rates';
import { LaborRole, LaborTaskRole } from '@dartsales/common/core/models/resources/labor-role';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';

import { PointsListSystemService } from '../../../../project-dashboard/pages/systems-page/components/points-list-table/services/points-list-system.service';
import { PointsListModuleService } from '../../../../project-dashboard/services/points-list-module-page.service';

/** Task rate value. */
export type TaskRateValue = {

  /** Labor role ID. */
  readonly roleId: ConfirmedOverridable<LaborRole['id']>;

  /** Rate type. */
  readonly rateType: LaborRoleRateType;

  /** Rates. */
  readonly rates: ConfirmedOverridable<LaborRoleInternalRates>;
};

type LaborRoleOption = {

  /** Label. */
  readonly label: string;

  /** Tasks rate options. */
  readonly tasksRateOptions: readonly OptionSelect<TaskRateValue>[];
};

/** Labor rate type select component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-labor-rate-type-select',
  templateUrl: './labor-rate-type-select.component.html',
  styleUrls: [
    '../table-header-select-shared.css',
    './labor-rate-type-select.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaborRateTypeSelectComponent implements OnInit {

  /** Task. */
  @Input()
  public set taskRate(value: PointsListSystemTaskRate | null) {
    if (value != null) {
      this.taskRate$.next(value);
    }
  }

  /** Whether select is read-only. */
  @Input()
  public set isReadonly(value: boolean) {
    this.isReadonly$.next(value);
  }

  /** Labor rate type change event. */
  @Output()
  public readonly rateTypeChange = new EventEmitter<TaskRateValue>();

  /** Bulk update save event. Emits boolean value - whether update should be applied or discarded. */
  @Output()
  public readonly bulkUpdateSave = new EventEmitter<boolean>();

  @ViewChild(MatSelect)
  private readonly matSelect?: MatSelect;

  private readonly pointsListSystemService = inject(PointsListSystemService);

  private readonly pointsListModuleService = inject(PointsListModuleService);

  /** Points list system task rate. */
  protected readonly taskRate$ = new BehaviorSubject<PointsListSystemTaskRate | null>(null);

  /**
   * Function to compare option values.
   * @param option Option value.
   * @param value Select value.
   */
  protected readonly compareFunction = (option: TaskRateValue, value: TaskRateValue): boolean =>
    option.roleId.combinedValue === value.roleId.combinedValue && option.rateType === value.rateType;

  /** Labor rate type. */
  protected readonly laborRateType = LaborRoleRateType;

  /** Currency mask. */
  protected readonly currencyMask = CURRENCY_MASK;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Labor rate type control. */
  protected readonly rateTypeControl = this.fb.control<TaskRateValue | null>(null);

  /** Labor rate type select options list stream. */
  protected readonly laborRateTypeOptions$ = this.createLaborRateTypeOptionsStream();

  private readonly isReadonly$ = new BehaviorSubject(false);

  /** Is bulk update available. */
  protected readonly isBulkUpdateAvailable$ = this.createIsBulkUpdateAvailableStream();

  /** Tooltip. */
  protected readonly tooltip$ = this.createTooltipStream();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToReadonlyStateChanges();
    this.subscribeToRateTypeChanges();
  }

  private createIsBulkUpdateAvailableStream(): Observable<boolean> {
    return combineLatest([this.taskRate$, this.isReadonly$]).pipe(
      map(([taskRate, isReadonly]) => (taskRate?.roleRate.isBulkUpdateAvailable ?? false) && !isReadonly),
    );
  }

  private createLaborRateTypeOptionsStream(): Observable<LaborRoleOption[]> {
    return combineLatest([
      this.taskRate$,
      this.pointsListModuleService.laborTasks$,
      this.pointsListSystemService.laborRoles$,
    ])
      .pipe(
        map(([taskRate, laborTasks, rolesFromResources]) => {
          const laborTask = laborTasks.find(task => task.id === taskRate?.taskId);

          if (taskRate != null && laborTask == null) {
            return this.createLaborRoleOptions(taskRate, rolesFromResources);
          }

          const overrideLaborRole = rolesFromResources.find(role => role.id === taskRate?.laborRoleId.override);
          const laborRoles = this.mergeAndSortOptions(laborTask?.roles ?? [], rolesFromResources, overrideLaborRole);

          return laborRoles.map(role => ({
            label: role.name,
            tasksRateOptions: this.getTasksRatesOptions({ taskRate, role }),
          })) ?? [];
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  private getRateTypeLabel(rateType: LaborRoleRateType, roleRate: PointsListSystemTaskRate['roleRate']): string {
    const rateTypeLabel = LaborRoleRateType.toReadable(rateType);
    const rateCost = roleRate.getRateByRateType(rateType);

    return `${rateTypeLabel}: $${rateCost}/hr`;
  }

  private subscribeToReadonlyStateChanges(): void {
    this.isReadonly$.pipe(
      untilDestroyed(this),
    ).subscribe(isReadonly => {
      if (isReadonly) {
        this.rateTypeControl.disable();
      }
    });
  }

  private subscribeToRateTypeChanges(): void {
    this.taskRate$.pipe(
      untilDestroyed(this),
    ).subscribe(taskRate => {
      if (taskRate) {
        this.rateTypeControl.setValue({
          roleId: ConfirmedOverridable.createConfirmedValue(taskRate.laborRoleId.initial),
          rateType: taskRate.roleRate.rateType,
          rates: taskRate.roleRate.rates,
        });
      }
    });
  }

  private getTasksRatesOptions(args: {
    taskRate: PointsListSystemTaskRate | null;
    role: LaborRole;
  }): OptionSelect<TaskRateValue>[] {
    const { taskRate, role } = args;
    return LaborRoleRateType.toArray().map(rate => ({
      label: this.getRateTypeLabel(rate, taskRate?.laborRoleId.initial === role.id ? taskRate.roleRate :
        new PointsListLaborRoleRate({
          rateType: LaborRoleRateType.Regular,
          rates: this.getConfirmedRoleRate(role),
        })),
      value: {
        rateType: rate,
        roleId: ConfirmedOverridable.createConfirmedValue(role.id),
        rates: taskRate?.laborRoleId.initial === role.id ? taskRate.roleRate.rates :
          this.getConfirmedRoleRate(role),
      },
    }));
  }

  private getConfirmedRoleRate(role: LaborRole): ConfirmedOverridable<LaborRoleInternalRates> {
    return new ConfirmedOverridable({
      initial: new LaborRoleInternalRates({
        rate: role.rate.internal,
        rateOvertimeOne: role.rateOvertimeOne.internal,
        rateOvertimeTwo: role.rateOvertimeTwo.internal,
      }),
      override: null,
      isConfirmed: true,
    });
  }

  private createTooltipStream(): Observable<string> {
    return combineLatest([
      this.pointsListSystemService.laborRoles$,
      this.taskRate$,
      listenControlChanges(this.rateTypeControl),
    ]).pipe(
      map(([laborRoles, taskRate]) => {
        const selectValue = this.matSelect?.triggerValue ??
          (taskRate !== null ? this.getRateTypeLabel(taskRate.roleRate.rateType, taskRate.roleRate) : '');

        const roleName = laborRoles.find(role => role.id === taskRate?.laborRoleId?.initial)?.name;
        return `${roleName} ${selectValue}`;
      }),
    );
  }

  private createLaborRoleOptions(taskRate: PointsListSystemTaskRate, laborRoles: readonly LaborRole[]): LaborRoleOption[] {
    const role = laborRoles.find(laborRole => laborRole.id === taskRate.laborRoleId.initial);
    return role ? [
      {
        label: role.name,
        tasksRateOptions: this.getTasksRatesOptions({
          taskRate,
          role,
        }),
      },
    ] : [];
  }

  private mergeAndSortOptions(
    laborTaskRoles: readonly LaborTaskRole[],
    rolesFromResources: readonly LaborRole[],
    overrideLaborRole?: LaborRole,
  ): LaborRole[] {
    const laborRoles = laborTaskRoles
      .map(role => rolesFromResources.find(laborRole => laborRole.id === role.id))
      .filter((role): role is LaborRole => role != null);

    if (overrideLaborRole != null) {
      laborRoles.push(overrideLaborRole);
    }

    return laborRoles.sort((a, b) => a.order - b.order);
  }
}
