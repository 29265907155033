<!--
  Don't remove the class, it's referenced in global styles
  This is necessary to be able to limit or transform positioning and overflow errors.
-->
@if (errorMessage; as message) {
  <span
    class="validation-message"
    data-testid="message"
    [title]="message"
  >
    {{ message }}
  </span>
}
