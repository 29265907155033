import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, HostBinding, inject, Input, OnInit, Output } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { BehaviorSubject, skip, filter, Observable, combineLatest, map, shareReplay } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';

import { TableCellFormControl } from '../table-cell-form-control.component';

/** Overridable autocomplete table cell. */
@Component({
  selector: 'dartsalesc-overridable-autocomplete-table-cell',
  templateUrl: './overridable-autocomplete-table-cell.component.html',
  styleUrls: ['./overridable-autocomplete-table-cell.component.css'],

  // We have to use Default change detection strategy to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OverridableAutocompleteTableCellComponent<T> extends TableCellFormControl<ConfirmedOverridable<OptionSelect<T>> | null>
  implements OnInit {

  /** Select options. */
  @Input()
  public set options(value: readonly OptionSelect<T>[]) {
    this.options$.next([...value]);
  }

  /** Select placeholder. */
  @Input()
  public placeholder = 'Select value';

  /** Is readonly. */
  @Input()
  public isReadonly = false;

  /** Whether cell has unconfirmed override. Override is considered 'unconfirmed' when it was set automatically due to bulk update.  */
  @Input()
  public hasUnconfirmedOverride = false;

  /**
   * Get tooltip message.
   * @param _ Initial value.
   */
  @Input()
  public getTooltipMessage = (_: T): string => '';

  /** Reset value. */
  @Output()
  public readonly resetValue = new EventEmitter<ConfirmedOverridable<OptionSelect<T>>>();

  /** Selection change. */
  @Output()
  public readonly selectionChange = new EventEmitter<T>();

  /** New item create. */
  @Output()
  public readonly newItemChange = new EventEmitter<string>();

  /** Override item. */
  @Output()
  public readonly overrideItem = new EventEmitter<string>();

  /** Is highlighted. */
  @HostBinding('class.highlighted')
  protected get isHighlighted(): boolean {
    return this.controlValue?.override != null && this.hasUnconfirmedOverride;
  }

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly destroyRef = inject(DestroyRef);

  /** Search form control. */
  protected readonly autocompleteControl = this.fb.control<OptionSelect<T> | string>('');

  private readonly options$ = new BehaviorSubject<OptionSelect<T>[]>([]);

  /** Filtered options stream. */
  protected readonly filteredOptions$ = this.createFilteredOptions(this.options$);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** Whether input has reset button or not. */
  protected get isResetAvailable(): boolean {
    return (
      !this.disabled &&
      this.controlValue?.override !== null &&
      this.hasUnconfirmedOverride
    );
  }

  /** Tooltip message. */
  protected get tooltipMessage(): string {
    if (this.controlValue?.initial !== null && this.controlValue?.initial !== undefined) {
      return `Reset to value: ${this.getTooltipMessage(this.controlValue.initial.value)}`;
    }
    return `Reset value`;
  }

  /** @inheritdoc */
  public override writeValue(value: ConfirmedOverridable<OptionSelect<T>> | null): void {
    this.autocompleteControl.setValue(value?.combinedValue ?? '', { emitEvent: false });
    super.writeValue(value);
  }

  /**
   * Display function for MatAutocomplete.
   * @param value Autocomplete option value.
   */
  protected displayFn(value: OptionSelect<T> | string | null): string {
    if (typeof value === 'string') {
      return value;
    }

    return value?.label ?? '';
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.autocompleteControl.disable();
    } else {
      this.autocompleteControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /**
   * Handler for selected option.
   * @param event MatOption selection change.
   */
  protected onOptionSelected(event: MatOptionSelectionChange<OptionSelect<T>>): void {
    /** This check solves this problem https://github.com/angular/components/issues/7369. */
    if (event.isUserInput) {
      this.selectionChange.emit(event.source.value.value);
    }
  }

  /**
   * Handler for selected option.
   * @param event MatOption selection change.
   */
  protected onNewOptionSelected(event: MatOptionSelectionChange<string | OptionSelect<T>>): void {
    /** This check solves this problem https://github.com/angular/components/issues/7369. */
    if (event.isUserInput) {
      if (typeof event.source.value === 'string') {
        this.newItemChange.emit(event.source.value);
      }
    }
  }

  private subscribeToControlChanges(): void {
    listenControlChanges(this.autocompleteControl).pipe(
      skip(1),
      filter((value): value is string => typeof value === 'string'),
      takeUntilDestroyed(this.destroyRef),
    )
      .subscribe(value => {
        this.overrideItem.emit(value);
      });
  }

  private createFilteredOptions(
    options$: Observable<OptionSelect<T>[]>,
  ): Observable<OptionSelect<T>[]> {
    return combineLatest([options$, listenControlChanges(this.autocompleteControl)]).pipe(
      map(([options, value]) => {
        if (typeof value === 'string') {
          return this.filterSelectOptions(value, options);
        }
        return options;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private filterSelectOptions(
    value: string,
    options: readonly OptionSelect<T>[],
  ): OptionSelect<T>[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  /** Handle reset button click. */
  protected onResetButtonClick(): void {
    if (this.controlValue !== null) {
      const clearedOverride = new ConfirmedOverridable({
        isConfirmed: true,
        initial: this.controlValue.initial,
        override: null,
      });
      this.writeValue(clearedOverride);
      this.controlValue = clearedOverride;
      this.resetValue.emit(clearedOverride);
    }
  }
}
