import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest, debounceTime, filter, map, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatIconButton } from '@angular/material/button';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { DEFAULT_MARGIN_PARAMS, MarginParams } from '@dartsales/common/core/models/margin-params';
import { MAX_GROSS_MARGIN_PERCENT_NUMBER, NUMBER_WITH_FRACTION_DIGITS_MASK, PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE } from '@dartsales/common/core/utils/constants';
import { MarginValues } from '@dartsales/common/core/models/estimate/modules/margin';
import { TableCellPrefixDirective } from '@dartsales/common/shared/components/editable-table-cell/directives/table-cell-prefix.directive';
import { compareRoundNumbers } from '@dartsales/common/core/utils/rounds';

import { AmountInputHelpers } from '../../inputs/abstract-input';

type MarginPercents = Pick<MarginValues, 'grossMargin' | 'markup'>;

type MarginInputValues = Partial<{

  /** Markup. */
  readonly markup: string;

  /** Gross margin. */
  readonly grossMargin: string;
}>;

/** Margin table header cell component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-margin-table-header-cell',
  templateUrl: './margin-table-header-cell.component.html',
  styleUrls: [
    '../table-header-select-shared.css',
    './margin-table-header-cell.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarginTableHeaderCellComponent implements OnInit {

  /** Set margin percents. */
  @Input()
  public set marginPercents(value: MarginValues) {
    this.marginPercents$.next(this.valueToInput(value));
    this.marginPercentsControl.patchValue(this.valueToInput(value), { emitEvent: false });
  }

  /** Margin params. */
  @Input()
  public set params(value: MarginParams | null) {
    this.markupTypeControl.patchValue(value ?? DEFAULT_MARGIN_PARAMS);
  }

  /** Whether value is locked. */
  @Input()
  public isLockedValue = false;

  /** Is readonly. */
  @Input()
  public isReadonly = false;

  /** Margin change. */
  @Output()
  public readonly marginParamsChange = new EventEmitter<MarginParams>();

  /** Apply percent value. */
  @Output()
  public readonly percentApply = new EventEmitter<MarginValues>();

  /** Cell prefix. */
  @ContentChild(TableCellPrefixDirective)
  protected readonly cellPrefix?: TableCellPrefixDirective;

  /** Default input button. */
  @ViewChild(MatIconButton, { read: ElementRef })
  private readonly defaultButton?: ElementRef<HTMLButtonElement>;

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly inputBlurTrigger$ = new BehaviorSubject<void>(undefined);

  private readonly valueChangeSaveTrigger$ = new BehaviorSubject<void>(undefined);

  /** Margin type. */
  protected readonly marginType = MarginType;

  /** Amount calc units. */
  protected readonly amountCalcUnits = AmountCalcUnits;

  /** Markup mask. */
  protected readonly markupMask = NUMBER_WITH_FRACTION_DIGITS_MASK;

  /** Gross margin mask. */
  protected readonly grossMarginMask: Inputmask.Options = { ...NUMBER_WITH_FRACTION_DIGITS_MASK, max: MAX_GROSS_MARGIN_PERCENT_NUMBER };

  /** Margin percents form group. */
  protected readonly marginPercentsControl = this.fb.group({
    markup: this.fb.control(''),
    grossMargin: this.fb.control(''),
  });

  /** Markup type control. */
  protected readonly markupTypeControl = this.fb.control<MarginParams>({
    marginType: MarginType.GrossMargin,
    units: AmountCalcUnits.Percent,
  });

  /** Markup type select options list stream. */
  protected readonly markupTypeOptions$ = this.createMarkupOptions();

  private readonly marginPercents$ = new BehaviorSubject<MarginInputValues>({});

  /** Margin type short label. */
  protected get shortMarginTypeLabel(): string {
    const controlValue = this.markupTypeControl.value;
    return this.getMarginTypeLabel(controlValue, true);
  }

  /** Get control value. */
  protected get value(): number | undefined {
    if (this.markupTypeControl.value.marginType === MarginType.GrossMargin) {
      return Number(this.marginPercentsControl.controls.grossMargin.value) / 100;
    }
    return Number(this.marginPercentsControl.controls.markup.value) / 100;
  }

  /** Is edit mode. */
  protected get isEditMode(): boolean {
    const { units } = this.markupTypeControl.value;
    return units === AmountCalcUnits.Percent &&
      !this.isReadonly;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToMarkupPercentControlChanges();
    this.subscribeToInputTriggerChanges();
    this.subscribeToUnitsControlChanges();
  }

  /**
   * Handle input blur.
   * @param event Focus event.
   */
  protected onInputBlur(event: FocusEvent): void {
    if (event.relatedTarget !== this.defaultButton?.nativeElement) {
      this.inputBlurTrigger$.next();
    }
  }

  /**
   * Handler for selected option.
   * @param value Margin params.
   */
  protected onOptionSelected(value: MarginParams): void {
    this.marginParamsChange.emit(value);
  }

  private createMarkupOptions(): Observable<OptionSelect<MarginParams>[]> {
    const values: readonly MarginParams[] = [
      {
        marginType: MarginType.Markup,
        units: AmountCalcUnits.Percent,
      },
      {
        marginType: MarginType.Markup,
        units: AmountCalcUnits.Amount,
      },
      {
        marginType: MarginType.GrossMargin,
        units: AmountCalcUnits.Percent,
      },
      {
        marginType: MarginType.GrossMargin,
        units: AmountCalcUnits.Amount,
      },
    ];

    const options = values.map<OptionSelect<MarginParams>>(option => ({
      label: this.getMarginTypeLabel(option),
      value: option,
    }));

    return listenControlChanges(this.markupTypeControl).pipe(
      map(control => options.filter(
        option => option.value.marginType !== control.marginType || option.value.units !== control.units,
      )),
    );
  }

  private getMarginTypeLabel(value: MarginParams, useShortLabel = false): string {
    const marginTypeLabel = useShortLabel ?
      MarginType.toShortReadable(value.marginType) :
      MarginType.toReadable(value.marginType);
    const calcUnitsLabel = AmountCalcUnits.toReadable(value.units);
    return `${marginTypeLabel} ${calcUnitsLabel}`;
  }

  /**
   * Convert display format to native value.
   * @param valueVm Display value.
   */
  private valueFromInput(valueVm: MarginInputValues): MarginPercents {
    const grossMarginPercent = AmountInputHelpers.percentFromInput((valueVm.grossMargin ?? 0)?.toString(), this.grossMarginMask) ?? 0;
    const markupPercent = AmountInputHelpers.percentFromInput((valueVm.markup ?? 0).toString(), this.markupMask) ?? 0;

    return { grossMargin: grossMarginPercent, markup: markupPercent };
  }

  /**
   * Convert value to display format.
   * @param value Native component value.
   */
  private valueToInput(value: MarginPercents): MarginInputValues {
    return {
      grossMargin: AmountInputHelpers.percentToInput(value.grossMargin ?? 0),
      markup: AmountInputHelpers.percentToInput(value.markup ?? 0),
    };
  }

  private checkIsInputValuesChangedWithRound(
    prevValueFromInput: MarginInputValues,
    valueFromInput: MarginInputValues,
    compareFractionDigits: number,
  ): boolean {
    const prevValue = this.valueFromInput({
      grossMargin: prevValueFromInput.grossMargin,
      markup: prevValueFromInput.markup,
    });
    const currentValue = this.valueFromInput({
      grossMargin: valueFromInput.grossMargin,
      markup: valueFromInput.markup,
    });

    if (this.markupTypeControl.value.marginType === MarginType.GrossMargin) {
      return !compareRoundNumbers(prevValue.grossMargin, currentValue.grossMargin, compareFractionDigits);
    }

    if (this.markupTypeControl.value.marginType === MarginType.Markup) {
      return !compareRoundNumbers(prevValue.markup, currentValue.markup, compareFractionDigits);
    }
    return false;
  }

  private subscribeToMarkupPercentControlChanges(): void {
    const debounceTimeMs = 2000;
    this.marginPercentsControl.valueChanges.pipe(
      debounceTime(debounceTimeMs),
      tap(() => this.valueChangeSaveTrigger$.next()),
      untilDestroyed(this),
    ).subscribe();
  }

  private subscribeToInputTriggerChanges(): void {
    combineLatest([
      this.inputBlurTrigger$,
      this.valueChangeSaveTrigger$,
    ]).pipe(
      filter(() => {
        if (this.marginPercentsControl.pristine) {
          return false;
        }

        const prevValue = this.marginPercents$.value;
        const currentValue = this.marginPercentsControl.value;

        return this.checkIsInputValuesChangedWithRound(prevValue, currentValue, PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE);
      }),
      tap(() => {
        const valueFromInput = this.valueFromInput(this.marginPercentsControl.value);
        this.percentApply.emit(valueFromInput);
      }),
      untilDestroyed(this),
    )
      .subscribe();
  }

  private subscribeToUnitsControlChanges(): void {
    listenControlChanges(this.markupTypeControl).pipe(
      filter(marginParams => marginParams.units === AmountCalcUnits.Percent),
      untilDestroyed(this),
    )
      .subscribe(() => this.marginPercentsControl.markAsPristine());
  }
}
