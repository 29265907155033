<table class="editable-table">
  <thead class="editable-table__header">
    <dartsalesc-progress-bar [loading]="loading" />
    @for (headerRow of headerRows; track $index) {
      <tr
        class="table-row"
        dartsalescEditableTableStickyRow="top"
      >
        @if (enableDraggableRows) {
          <td
            class="table-cell"
            dartsalescEditableTableStickyColumnCell="left"
          >
            <!-- Empty. -->
          </td>
        }
        <ng-container
          [ngTemplateOutlet]="rowCellsTemplate"
          [ngTemplateOutletContext]="{ $implicit: headerRow.tableColumns }"
        />
      </tr>
    }
  </thead>
  @if (useVirtualScroll) {
    <!-- We have to add this invisible fake row to display cells with colspan correctly. -->
    @if (bodyRows?.get(0); as row) {
      <tr
        class="table-row virtual-scroll-fake-row"
        [ngClass]="row.rowClasses"
      >
        @if (enableDraggableRows) {
          <td
            dartsalescEditableTableStickyColumnCell="left"
            class="table-cell drag-cell"
          ></td>
        }
        <ng-container
          [ngTemplateOutlet]="rowCellsTemplate"
          [ngTemplateOutletContext]="{ $implicit: row.tableColumns }"
        />
      </tr>
    }
    <cdk-virtual-scroll-viewport
      *ngrxLet="bodyRows?.changes | async"
      #scrollViewPort
      [itemSize]="virtualScrollItemSizePx"
      [minBufferPx]="virtualScrollItemSizePx * virtualScrollMinBufferItems"
      [maxBufferPx]="
        virtualScrollItemSizePx * (virtualScrollMinBufferItems + 10)
      "
    >
      <div
        *ngrxLet="scrollViewPort.renderedRangeStream | async as listRange"
        cdkDropList
        class="editable-table__body"
        [cdkDropListSortPredicate]="dragSortPredicate"
        [cdkDropListData]="
          rowsForVirtualScroll.slice(listRange?.start ?? 0, listRange?.end)
        "
        [cdkDropListDisabled]="!enableDraggableRows"
      >
        <tr
          *cdkVirtualFor="
            let row of rowsForVirtualScroll;
            templateCacheSize: 0;
            trackBy: trackByIndex;
            let index = index
          "
          class="table-row"
          cdkDrag
          [cdkDragDisabled]="!row.isDraggable"
          [cdkDragData]="{ row, index }"
          [dartsalescEditableTableStickyRow]="row.stickySide"
          [class.table-row_highlighted]="row.isHighlighted"
          [class.table-row_collapsed]="!row.isExpanded"
          [ngClass]="row.rowClasses"
          (cdkDragDropped)="row.rowDragDropped.emit($event)"
        >
          @if (enableDraggableRows) {
            <td
              dartsalescEditableTableStickyColumnCell="left"
              class="table-cell drag-cell"
            >
              @if (row.isDraggable) {
                <div
                  cdkDragHandle
                  class="drag-handle"
                >
                  <mat-icon class="drag-handle__icon">drag_indicator</mat-icon>
                </div>
              }
            </td>
          }
          <ng-container
            [ngTemplateOutlet]="rowCellsTemplate"
            [ngTemplateOutletContext]="{ $implicit: row.tableColumns }"
          />
          <div *cdkDragPreview>
            @if (row.rowDragPreview) {
              <ng-container [ngTemplateOutlet]="row.rowDragPreview.template" />
            } @else {
              @if (row.tableColumns?.get(0)?.tableCell?.template; as template) {
                <ng-container
                  [ngTemplateOutlet]="template"
                  [ngTemplateOutletContext]="{
                    $implicit: row.tableColumns?.get(0),
                  }"
                />
              }
            }
          </div>
          <div *cdkDragPlaceholder>
            <td [attr.colspan]="_columns.length">
              <!-- Empty. -->
            </td>
          </div>
        </tr>
      </div>
    </cdk-virtual-scroll-viewport>
  } @else {
    <tbody
      *ngrxLet="bodyRows?.changes | async"
      cdkDropList
      class="editable-table__body"
      [cdkDropListData]="bodyRows?.toArray()"
      [cdkDropListSortPredicate]="dragSortPredicate"
      [cdkDropListDisabled]="!enableDraggableRows"
    >
      @for (row of bodyRows; track $index; let index = $index) {
        <tr
          class="table-row"
          cdkDrag
          cdkDragPreviewContainer="parent"
          [cdkDragDisabled]="!row.isDraggable"
          [cdkDragData]="{ row, index }"
          [dartsalescEditableTableStickyRow]="row.stickySide"
          [class.table-row_highlighted]="row.isHighlighted"
          [class.table-row_collapsed]="!row.isExpanded"
          [ngClass]="row.rowClasses"
          (cdkDragDropped)="row.rowDragDropped.emit($event)"
        >
          @if (enableDraggableRows) {
            <td
              dartsalescEditableTableStickyColumnCell="left"
              class="table-cell drag-cell"
            >
              @if (row.isDraggable) {
                <div
                  cdkDragHandle
                  class="drag-handle"
                >
                  <mat-icon class="drag-handle__icon">drag_indicator</mat-icon>
                </div>
              }
            </td>
          }
          <ng-container
            [ngTemplateOutlet]="rowCellsTemplate"
            [ngTemplateOutletContext]="{ $implicit: row.tableColumns }"
          />
          <div *cdkDragPreview>
            @if (row.rowDragPreview) {
              <ng-container [ngTemplateOutlet]="row.rowDragPreview.template" />
            } @else {
              <div class="default-drag-preview">
                <div class="drag-handle">
                  <mat-icon class="drag-handle__icon">drag_indicator</mat-icon>
                </div>
                @if (
                  row.tableColumns?.get(0)?.tableCell?.template;
                  as template
                ) {
                  <ng-container
                    [ngTemplateOutlet]="template"
                    [ngTemplateOutletContext]="{
                      $implicit: row.tableColumns?.get(0),
                    }"
                  />
                }
              </div>
            }
          </div>
          <div *cdkDragPlaceholder>
            <td [attr.colspan]="_columns.length">
              <!-- Empty. -->
            </td>
          </div>
        </tr>
      }
    </tbody>
  }
  <tfoot class="editable-table__footer">
    @for (footerRow of footerRows; track $index) {
      <tr
        class="table-row"
        dartsalescEditableTableStickyRow="bottom"
        [class.total-footer-row]="footerRow.isTotalRow"
      >
        @if (enableDraggableRows) {
          <td
            dartsalescEditableTableStickyColumnCell="left"
            class="table-cell"
          >
            <!-- Empty. -->
          </td>
        }
        <ng-container
          [ngTemplateOutlet]="rowCellsTemplate"
          [ngTemplateOutletContext]="{ $implicit: footerRow.tableColumns }"
        />
      </tr>
    }
  </tfoot>
</table>

<ng-template
  #rowCellsTemplate
  let-tableColumns
  [dartsalescTemplateContext]="rowCellsTemplateContext"
>
  @for (column of _columns; track trackByName($index, column)) {
    <ng-container
      *ngrxLet="getColumnByName(column.name, tableColumns) as columnTemplate"
    >
      <!-- The template for the sticky column and for the regular column must be identical except for the `dartsalescEditableTableStickyColumnCell` directive -->
      @if (column.stickySide === undefined) {
        <td
          class="table-cell"
          [attr.colspan]="
            columnTemplate?.tableCell?.dartsalescEditableTableCellColSpan ?? 1
          "
          [class.table-cell_expanded]="column.isExpandable"
          [ngClass]="getColumnCssClasses(column)"
          [ngStyle]="getColumnSizeStyles(column)"
        >
          @if (columnTemplate?.tableCell?.template; as template) {
            <ng-container
              [ngTemplateOutlet]="template"
              [ngTemplateOutletContext]="{
                $implicit: column,
              }"
            />
          } @else {}
        </td>
      } @else {
        <!-- The template for the sticky column and for the regular column must be identical except for the `dartsalescEditableTableStickyColumnCell` directive -->
        @if (column.stickySide !== undefined) {
          <td
            class="table-cell"
            [dartsalescEditableTableStickyColumnCell]="column.stickySide"
            [attr.colspan]="
              columnTemplate?.tableCell?.dartsalescEditableTableCellColSpan ?? 1
            "
            [class.table-cell_expanded]="column.isExpandable"
            [ngClass]="getColumnCssClasses(column)"
            [ngStyle]="getColumnSizeStyles(column)"
          >
            @if (columnTemplate?.tableCell?.template; as template) {
              <ng-container
                [ngTemplateOutlet]="template"
                [ngTemplateOutletContext]="{
                  $implicit: column,
                }"
              />
            } @else {}
          </td>
        }
      }
    </ng-container>
  }
</ng-template>
