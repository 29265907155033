<div class="select">
  <mat-select
    placeholder="Hide all modules"
    multiple
    [value]="visibleModules"
    (valueChange)="visibleModulesChange.emit($event)"
  >
    <mat-select-trigger>{{ visibleModulesText }}</mat-select-trigger>
    @for (type of moduleType.getBaseModules(); track type) {
      <mat-option [value]="type">
        {{ moduleType.toReadable(type) }}
      </mat-option>
    }
  </mat-select>
</div>
