@if (columnConfig) {
  <div
    class="header-cell"
    [ngClass]="{
      'header-cell_align-center': columnConfig.align === 'center',
      'header-cell_align-right': columnConfig.align === 'right',
    }"
  >
    <ng-content select="[prefix]" />

    <span
      class="header-cell__text ellipsis"
      [class.header-cell__text_active]="isSortActive"
      [title]="columnConfig.headerText"
    >
      {{ columnConfig.headerText }}
    </span>

    <ng-content select="[bulkUpdate]" />

    @if (columnConfig.sortField; as sortField) {
      <dartsalesc-table-header-sorting-button
        class="header-cell__sorting-button"
        [sortFieldName]="sortField"
      />
    }
  </div>
}
