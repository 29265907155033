import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';

import { NotificationMessage } from '../models/notification-message';

import { WebSocketApiService } from './api/hubs/web-socket-api.service';

/** Notifications service. */
@Injectable()
export class NotificationsService {
  private readonly webSocketService = inject(WebSocketApiService);

  /** All notification messages. */
  public readonly allNotifications$ = this.createNotificationMessagesStream();

  private readonly lastReadDate$ = new BehaviorSubject<Date>(new Date());

  /** Unread notification messages. */
  public readonly unreadMessages$ = this.createUnreadMessagesStream();

  /**
   * Update last read date.
   * @param date Date.
   */
  public updateLastReadDate(date: Date): void {
    this.lastReadDate$.next(date);
  }

  private createUnreadMessagesStream(): Observable<NotificationMessage[]> {
    return combineLatest([
      this.allNotifications$,
      this.lastReadDate$,
    ]).pipe(
      map(([list, lastDate]) => list.filter(item => item.date > lastDate)),
    );
  }

  private createNotificationMessagesStream(): Observable<NotificationMessage[]> {
    // TODO Return the code when the API endpoint is configured.

    // return this.webSocketService.getMessages().pipe(
    //   scan<string, NotificationMessage[]>((acc, message) => [
    //     new NotificationMessage({
    //       message,
    //       date: new Date(),
    //     }),
    //     ...acc,
    //   ], []),
    //   untilDestroyed(this),
    //   shareReplay({ bufferSize: 1, refCount: false }),
    // );

    return of([]);
  }
}
