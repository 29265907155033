import { computed, ReadonlySignal, Signal, signal } from '@preact/signals-core';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';

import { OverridableBasePricing } from '../pricing/overridable-base-pricing';
import { AmountPercent } from '../estimate/amount-percent';

import { AbstractCalculator } from './abstract-calculator';
import { ContingencyCalculator, ContingencyCalculatorInitArgs } from './contingency-calculator';
import { OverridableMarginCalculator, OverridableMarginCalculatorInitArgs } from './overridable-margin-calculator';
import { DomainCostCalculator } from './domain/domain-cost-calculator';
import { DomainOverridableBasePricingCalculator } from './domain/domain-overridable-base-pricing-calculator';
import { DirectCostCalculator } from './direct-cost-calculator';
import { EscalationCalculator, EscalationCalculatorInitArgs } from './escalation-calculator';

// TODO (Pavel Z.) Refactor this structure to base pricing param approach, or merge these both approach to one.
/** Overridable base pricing calculator params. */
export type OverridableBasePricingCalculatorParams = {

  /** Direct cost (in $). */
  readonly directCost: Overridable<number>;

  /** Contingency params. */
  readonly contingencyParams: Pick<ContingencyCalculatorInitArgs,
    'contingencyValue' | 'contingencyUnits'>;

  /** Margin params. */
  readonly marginParams: Pick<OverridableMarginCalculatorInitArgs,
    'marginValue' | 'marginType' | 'marginUnits'>;

  /** Contingency params. */
  readonly escalationParams: Pick<EscalationCalculatorInitArgs,
    'escalationValue' | 'escalationUnits'>;

  /** Escalation + WFS parameters from material module. */
  readonly aggregatedWEFS: Overridable<AmountPercent>;
};

/** Overridable base pricing calculator. */
export class OverridableBasePricingCalculator extends AbstractCalculator<OverridableBasePricing> {

  /** @inheritdoc */
  public override readonly result: ReadonlySignal<OverridableBasePricing>;

  /** Direct cost. */
  public readonly directCostCalc: DirectCostCalculator;

  /** Contingency calculator. */
  public readonly contingencyCalc: ContingencyCalculator;

  /** Escalation calculator. */
  public readonly escalationCalc: EscalationCalculator;

  /** Escalation + WFS parameters from material module. */
  public readonly aggregatedWEFS: Signal<Overridable<AmountPercent>>;

  /** Margin calculator. */
  public readonly marginCalc: OverridableMarginCalculator;

  /** Calculator value changes. */
  public override readonly valueChanges: ReadonlySignal<unknown>;

  public constructor(initParams: OverridableBasePricingCalculatorParams) {
    super();

    this.directCostCalc = this.createDirectCostCalculator(initParams);

    this.contingencyCalc = new ContingencyCalculator({
      directCostCalc: this.directCostCalc,
      contingencyValue: initParams.contingencyParams.contingencyValue,
      contingencyUnits: initParams.contingencyParams.contingencyUnits,
    });

    this.escalationCalc = new EscalationCalculator({
      contingencyCalculator: this.contingencyCalc,
      directCostCalculator: this.directCostCalc,
      escalationValue: initParams.escalationParams.escalationValue,
      escalationUnits: initParams.escalationParams.escalationUnits,
    });
    this.aggregatedWEFS = signal(initParams.aggregatedWEFS);

    this.marginCalc = new OverridableMarginCalculator({
      totalDirectCost: computed(() => DomainCostCalculator.calculateTotalDirectCost({
        directCost: this.directCostCalc.result.value.combinedValue,
        contingencyAmount: this.contingencyCalc.result.value.combinedValue.amount,
        escalationAmount: this.escalationCalc.result.value.combinedValue.amount,
      })),
      marginValue: initParams.marginParams.marginValue,
      marginUnits: initParams.marginParams.marginUnits,
      marginType: initParams.marginParams.marginType,
    });

    this.result = computed(() => this.calculateResult());

    this.valueChanges = computed(() => this.createValueChanges());
  }

  /**
   * Change aggregated WEFS value.
   * @param value Aggregated WEFS value.
   */
  public setAggregatedWEFS(value: Overridable<AmountPercent>): void {
    this.escalationCalc.setValue(value);
    this.aggregatedWEFS.value = value;
  }

  /**
   * Create direct cost calculator instance.
   * @param initParams Initial instance params.
   **/
  protected createDirectCostCalculator(initParams: OverridableBasePricingCalculatorParams): DirectCostCalculator {
    return new DirectCostCalculator({
      value: initParams.directCost,
    });
  }

  /**
   * Creates calculator init params by `BasePricing` based object.
   * @param args Arguments.
   */
  public static createParamsByPricing(
    args: DomainOverridableBasePricingCalculator.CalculationArgs,
  ): OverridableBasePricingCalculatorParams {
    const { originalPricing, contingencyUnits, marginUnits, marginType, escalationUnits } = args;
    return {
      directCost: originalPricing.directCost,
      contingencyParams: {
        contingencyValue: originalPricing.contingency,
        contingencyUnits: contingencyUnits ?? AmountCalcUnits.DEFAULT,
      },
      marginParams: {
        marginValue: originalPricing.margin,
        marginUnits: marginUnits ?? AmountCalcUnits.DEFAULT,
        marginType: marginType ?? MarginType.DEFAULT,
      },
      escalationParams: {
        escalationValue: originalPricing.escalation,
        escalationUnits: escalationUnits ?? AmountCalcUnits.DEFAULT,
      },
      aggregatedWEFS: originalPricing.aggregatedWEFS,
    };
  }

  /**
   * Set sell price value.
   * @param expectedSellPrice Expected sell price.
   */
  public setSellPrice(expectedSellPrice: Overridable<number> | number): void {
    // TODO (Pavel Z.) It would be nice if we got rid of this ambiguous logic of
    //  mixing Overridable and numeric values when refactoring calculators.
    const sellPrice = expectedSellPrice instanceof Overridable ? expectedSellPrice.combinedValue : expectedSellPrice;
    const delta = sellPrice - this.result.value.getTotalDirectCost();
    this.marginCalc.setAmount(delta);
  }

  /** @inheritdoc */
  protected override calculateResult(): OverridableBasePricing {
    return DomainOverridableBasePricingCalculator.calculatePricing({
      originalPricing: {
        directCost: this.directCostCalc.result.value,
        contingency: this.contingencyCalc.value.value,
        margin: this.marginCalc.value.value,
        escalation: this.escalationCalc.value.value,
        aggregatedWEFS: this.aggregatedWEFS.value,
      },
      contingencyUnits: AmountCalcUnits.Percent,
      marginType: MarginType.GrossMargin,
      marginUnits: AmountCalcUnits.Percent,
      escalationUnits: AmountCalcUnits.Percent,
    });
  }

  /** Calculator values change inspector. */
  protected createValueChanges(): unknown {
    return {
      result: this.result.value,
      margin: this.marginCalc.result.value,
      directCost: this.directCostCalc.value,
      contingency: this.contingencyCalc.result.value,
      escalation: this.escalationCalc.result.value,
      aggregatedWEFS: this.escalationCalc.result.value,
    };
  }
}
