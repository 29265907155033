import { ReadonlySignal, computed } from '@preact/signals-core';

/**
 * Abstract calculator.
 * This class is primarily needed to enforce specific contract for every other calculator.
 * It is mostly used to work with real-time updates.
 */
export abstract class AbstractCalculator<TResult> {

  /** Result. */
  public abstract readonly result: ReadonlySignal<TResult>;

  /** Calculator value changes. */
  public readonly valueChanges = computed<unknown>(() => this.result.value);

  /** Function to calculate the result. */
  protected abstract calculateResult(...args: unknown[]): TResult;
}
