import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-core';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';

import { AmountPercent } from '../estimate/amount-percent';

import { AbstractCalculator } from './abstract-calculator';
import { DomainContingencyCalculator } from './domain/domain-contingency-calculator';
import { DirectCostCalculator } from './direct-cost-calculator';

/** Contingency calculator initialization arguments. */
export type ContingencyCalculatorInitArgs = {

  /** Direct cost calculator. */
  readonly directCostCalc: DirectCostCalculator;

  /** Contingency initial value. */
  readonly contingencyValue: Overridable<AmountPercent>;

  /** Calc units. */
  readonly contingencyUnits?: AmountCalcUnits;
};

/** Contingency calculation utility. */
export class ContingencyCalculator extends AbstractCalculator<Overridable<AmountPercent>> {

  /** Result. */
  public readonly result: ReadonlySignal<Overridable<AmountPercent>>;

  /** Value. */
  public readonly value: Signal<Overridable<AmountPercent>>;

  /** Units. */
  public readonly units: Signal<AmountCalcUnits>;

  public constructor(private readonly initParams: ContingencyCalculatorInitArgs) {
    super();

    this.units = signal(initParams.contingencyUnits ?? AmountCalcUnits.DEFAULT);
    this.value = signal(initParams.contingencyValue);

    this.result = computed(() => this.calculateResult(this.value.value));
  }

  /**
   * Change current value.
   * @param value Contingency value.
   */
  public setValue(value: Overridable<AmountPercent>): void {
    this.value.value = this.calculateResult(value);
  }

  /**
   * Set units.
   * @param units Units.
   */
  public setUnits(units: AmountCalcUnits): void {
    this.units.value = units;
  }

  /** @inheritdoc */
  protected calculateResult(value: Overridable<AmountPercent>): Overridable<AmountPercent> {
    return DomainContingencyCalculator.calculateOverridableContingency({
      directCost: this.initParams.directCostCalc.result.value.combinedValue,
      value,
      units: this.units.peek(),
    });
  }
}
