<div
  #sentinel
  class="sentinel"
></div>
@if (isContentVisible$ | async) {
  @if (template) {
    <ng-container [ngTemplateOutlet]="template" />
  }
} @else {
  <ng-content select="[placeholder]" />
}
<ng-template #placeholder>
  <ng-content select="[placeholder]" />
</ng-template>
