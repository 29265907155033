import { Injectable } from '@angular/core';

import { NonStandardMaterialItem } from '@dartsales/common/core/models/estimate/modules/material/data/non-standard-material-item';
import { StandardMaterialGroup } from '@dartsales/common/core/models/estimate/modules/material/data/standard-material-group';
import { StandardMaterialItem } from '@dartsales/common/core/models/estimate/modules/material/data/standard-material-item';

import { MaterialTableCollapsibleRowData, MaterialTableRowViewModel } from '../view-models/material-table-row-view-model';

/** Service to work with material table row keys. */
@Injectable({ providedIn: 'root' })
export class MaterialTableRowKeysService {

  /** Key for non standard material type row. */
  public readonly nonStandardMaterialTypeKey = 'nonStandardMaterials';

  /** Key for add non standard material row. */
  public readonly addNonStandardMaterialKey = 'addNonStandardMaterial';

  /** Key for standard material type row. */
  public readonly standardMaterialTypeKey = 'standardMaterials';

  /** Key for total contingency row. */
  public readonly totalContingencyKey = 'totalContingency';

  /** Key for total direct cost row. */
  public readonly totalDirectCostKey = 'totalDirectCost';

  /** Key for grand total direct cost row. */
  public readonly grandTotalDirectCostKey = 'grandTotalDirectCost';

  /**
   * Create key for standard material group row.
   * @param groupId Standard material group ID.
   */
  public createStandardMaterialGroupKey(groupId: StandardMaterialGroup['id']): string {
    return `standardMaterialGroup-${groupId}`;
  }

  /**
   * Create key for standard material item row.
   * @param id Standard material parts group ID.
   */
  public createStandardMaterialItemKey(id: StandardMaterialItem['partsGroupId']): string {
    return id;
  }

  /**
   * Create key for non standard material item.
   * @param id Non standard material part ID.
   */
  public createStandardNonMaterialItemKey(id: NonStandardMaterialItem['partId']): string {
    return id;
  }

  /**
   * Get parent rows keys.
   * @param row Row.
   */
  public getParentRowsKeysData(
    row: MaterialTableRowViewModel,
  ): MaterialTableCollapsibleRowData[] {

    if (row.type === 'standardMaterialItem') {
      return [
        {
          type: 'materialsType',
          key: this.standardMaterialTypeKey,
        },
        {
          type: 'standardMaterialGroup',
          key: this.createStandardMaterialGroupKey(row.data.group.id),
        },
      ];
    }

    if (row.type === 'standardMaterialGroup') {
      return [
        {
          type: 'materialsType',
          key: this.standardMaterialTypeKey,
        },
      ];
    }

    const nonStandardMaterialCollapsibleData: MaterialTableCollapsibleRowData[] = [
      {
        key: this.nonStandardMaterialTypeKey,
        type: 'materialsType',
      },
    ];

    if (row.type === 'nonStandardMaterialItem' || row.type === 'addNonStandardMaterial') {
      return nonStandardMaterialCollapsibleData;
    }

    return [];
  }
}
