import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs';

import { UserAuthService } from '@dartsales/common/core/services/user-auth.service';
import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

/** App access error page. */
@Component({
  selector: 'dartsalesw-app-access-error-page',
  templateUrl: './app-access-error-page.component.html',
  styleUrls: ['./app-access-error-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppAccessErrorPageComponent {
  private readonly userAuthService = inject(UserAuthService);

  private readonly router = inject(Router);

  private readonly routePaths = injectWebAppRoutes();

  /** Handle click on logout button. */
  protected onLogoutClick(): void {
    this.userAuthService.logout()
      .pipe(first())
      .subscribe(() => this.router.navigate([this.routePaths.auth.children.login.path]));
  }
}
