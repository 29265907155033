<form
  class="search-panel"
  (ngSubmit)="handleSubmit.emit()"
>
  <div><!-- EMPTY --></div>
  <mat-form-field class="no-padding search-field">
    <mat-icon matPrefix>search</mat-icon>
    <dartsalesw-search-input
      placeholder="Search"
      [formControl]="searchControl"
    />
    <button
      matSuffix
      mat-icon-button
      type="button"
      [disabled]="areNextPrevButtonsDisabled"
      (click)="handlePrevButtonClick.emit($event)"
    >
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button
      matSuffix
      mat-icon-button
      type="button"
      [disabled]="areNextPrevButtonsDisabled"
      (click)="handleNextButtonClick.emit($event)"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </mat-form-field>
  @if (isSearchActive) {
    <div>{{ currentNumber }}/{{ total }}</div>
  }
</form>
