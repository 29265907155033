import { UserOrganization } from '../user-organization';

import { UserBase } from './user-base';
import { UserEstimate } from './user-estimate';

/** User profile. */
export class UserProfile extends UserBase {
  /** Whether user is global admin or not. */
  public readonly isGlobalAdmin: boolean;

  /** Organizations. */
  public readonly organizations: readonly UserOrganization[];

  /** Base estimates that user created or is a team member. */
  public readonly baseEstimates: readonly UserEstimate[];

  public constructor(data: UserProfileInitArgs) {
    super(data);
    this.organizations = data.organizations;
    this.isGlobalAdmin = data.isGlobalAdmin;
    this.baseEstimates = data.baseEstimates;
  }
}

type UserProfileInitArgs = UserProfile;

export type EditUserProfile = Pick<UserProfile, 'email' | 'firstName' | 'lastName' | 'title'>;
