<dartsalesc-base-dialog
  class="dialog"
  dialogTitle="Export Multiple"
>
  <form
    class="config-form"
    [formGroup]="configForm"
  >
    @if (isTermTreeLoading$ | async; as isTermTreeLoading) {
      <dartsalesw-loading [isLoading]="isTermTreeLoading" />
    }
    @if (termTree$ | async; as termTree) {
      <dartsalesw-select-term-categories-tree
        class="subcategories-select"
        [termTree]="termTree"
        (selected)="handleCategoriesSelectionChanges($event)"
      />
    }

    <section class="config-form__bottom-section">
      <dartsalesw-select-format-control
        [formControl]="configForm.controls.exportFormat"
      />
    </section>
  </form>
  <dartsalesc-base-dialog-footer
    dialogFooter
    actionButtonText="Export"
    [isLoading]="isLoading$ | async"
    (actionButtonClick)="onSubmit()"
    (cancelButtonClick)="dialogRef.close()"
  />
</dartsalesc-base-dialog>
