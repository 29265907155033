import { inject, Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';

import { DEFAULT_BASE_MODULES_SETTINGS, DEFAULT_MODULE_SETTINGS } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/default-estimate-settings';
import { ModuleType } from '@dartsales/common/core/enums/module-type';

import { BaseModulesSettingsFormControls, ExpensesModuleSettingsFormControls, LaborModuleSettingsFormControls, MaterialModuleSettingsFormControls, ModuleSettingsFormControls, ModuleUnitsSettingsFormControls, SubcontractorModuleSettingsFormControls } from './forms/base-modules-settings-form';

/** Form mapper for base modules local storage. */
@Injectable({
  providedIn: 'root',
})
export class BaseModulesLocalStorageFormMapper {
  private readonly fb = inject(NonNullableFormBuilder);

  /** Get base modules form. */
  public createBaseModulesForm(): FormGroup<BaseModulesSettingsFormControls> {
    return this.fb.group<BaseModulesSettingsFormControls>({
      [ModuleType.Subcontractor]: this.createSubcontractorModuleForm(),
      [ModuleType.Custom]: this.createModuleSettingsForm(),
      [ModuleType.Expenses]: this.createExpensesModuleForm(),
      [ModuleType.Labor]: this.createLaborModuleForm(),
      [ModuleType.Material]: this.createMaterialModuleForm(),
    });
  }

  /** Create module units settings form. */
  public createModuleUnitsSettingsForm(): FormGroup<ModuleUnitsSettingsFormControls> {
    return this.fb.group<ModuleUnitsSettingsFormControls>({
      contingencyUnits: this.fb.control(DEFAULT_MODULE_SETTINGS.units.contingencyUnits),
      escalationUnits: this.fb.control(DEFAULT_MODULE_SETTINGS.units.escalationUnits),
      dcUnits: this.fb.control(DEFAULT_MODULE_SETTINGS.units.dcUnits),
      marginParams: this.fb.control(DEFAULT_MODULE_SETTINGS.units.marginParams),
    });
  }

  /** Create module settings form. */
  public createModuleSettingsForm(): FormGroup<ModuleSettingsFormControls> {
    return this.fb.group<ModuleSettingsFormControls>({
      isExpandedEscalation: this.fb.control(DEFAULT_MODULE_SETTINGS.isExpandedEscalation),
      isExpandedPricing: this.fb.control(DEFAULT_MODULE_SETTINGS.isExpandedPricing),
      units: this.createModuleUnitsSettingsForm(),
    });
  }

  private createSubcontractorModuleForm(): FormGroup<SubcontractorModuleSettingsFormControls> {
    return this.fb.group<SubcontractorModuleSettingsFormControls>({
      isExpandCalculator: this.fb.control(DEFAULT_BASE_MODULES_SETTINGS[ModuleType.Subcontractor].isExpandCalculator),
      lumpSumPricing: this.createModuleSettingsForm(),
      unitPricing: this.createModuleSettingsForm(),
    });
  }

  private createExpensesModuleForm(): FormGroup<ExpensesModuleSettingsFormControls> {
    return this.fb.group<ExpensesModuleSettingsFormControls>({
      calculationDataSection: this.fb.group({
        isExpanded: this.fb.control(DEFAULT_BASE_MODULES_SETTINGS[ModuleType.Expenses].calculationDataSection.isExpanded),
        isExpandResourceSection:
          this.fb.control(DEFAULT_BASE_MODULES_SETTINGS[ModuleType.Expenses].calculationDataSection.isExpandLaborRolesSection),
        isExpandLaborRolesSection:
          this.fb.control(DEFAULT_BASE_MODULES_SETTINGS[ModuleType.Expenses].calculationDataSection.isExpandLaborRolesSection),
      }),
      summarySection: this.fb.group({
        isExpanded: this.fb.control(DEFAULT_BASE_MODULES_SETTINGS[ModuleType.Expenses].summarySection.isExpanded),
        table: this.createModuleSettingsForm(),
      }),
    });
  }

  private createLaborModuleForm(): FormGroup<LaborModuleSettingsFormControls> {
    return this.fb.group<LaborModuleSettingsFormControls>({
      ...this.createModuleSettingsForm().controls,
      isExpandRates: this.fb.control(DEFAULT_BASE_MODULES_SETTINGS[ModuleType.Labor].isExpandRates),
      collapsedRowTypes: this.fb.control([]),
      collapsibleRowData: this.fb.control([]),
    });
  }

  private createMaterialModuleForm(): FormGroup<MaterialModuleSettingsFormControls> {
    return this.fb.group<MaterialModuleSettingsFormControls>({
      ...this.createModuleSettingsForm().controls,
      isExpandWFS: this.fb.control(DEFAULT_BASE_MODULES_SETTINGS[ModuleType.Material].isExpandWFS),
      isExpandModel: this.fb.control(DEFAULT_BASE_MODULES_SETTINGS[ModuleType.Material].isExpandModel),
      collapsedRowTypes: this.fb.control([]),
      collapsibleRowData: this.fb.control([]),
    });
  }
}
