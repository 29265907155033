import { calculatePercentByFraction } from '@dartsales/common/core/utils/percents';

import { AmountPercent } from '../../estimate/amount-percent';

// TODO (Pavel Z.) Could we extend it based on BasePricing?
/** Domain customer overhead calculator. */
export namespace DomainCustomerOverheadCalculator {

  type CalculateOverheadParams = {

    /** Direct cost. */
    readonly directCost: number;

    /** Sell price. */
    readonly sellPrice: number;

    /** Margin. */
    readonly margin: AmountPercent;
  };

  /**
   * Calculate customer overhead.
   * @param params Params.
   */
  export function calculateOverhead(params: CalculateOverheadParams): AmountPercent {
    const amount = params.sellPrice - params.directCost - params.margin.amount;
    const percent = calculatePercentByFraction(params.directCost, amount);
    return new AmountPercent({
      amount,
      percent: isFinite(percent) ? percent : 0,
    });
  }
}
