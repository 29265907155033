<dartsalesc-base-dialog
  class="dialog"
  dialogTitle="Export Multiple"
>
  <form
    class="config-form"
    [formGroup]="exportParamsForm"
  >
    <section class="view-selects">
      <div class="view-select">
        <mat-checkbox
          [formControl]="exportParamsForm.controls.isExportInternalView"
        >
          {{ exportModuleService.viewTypeLabels[viewType.Internal] }}
        </mat-checkbox>
      </div>

      <div class="view-select">
        <mat-checkbox
          [formControl]="exportParamsForm.controls.isExportCustomerView"
        >
          {{ exportModuleService.viewTypeLabels[viewType.Customer] }}
        </mat-checkbox>
      </div>
    </section>

    <section class="config-form__bottom-section">
      @if (shouldShowFormError) {
        <div class="config-form__error body-2">
          {{ formAppError }}
        </div>
      }

      <dartsalesw-select-format-control
        [supportedExportFormats]="exportModuleService.supportedExportFormats"
        [formControl]="exportParamsForm.controls.exportFormat"
      />
    </section>
  </form>

  <dartsalesc-base-dialog-footer
    dialogFooter
    actionButtonText="Export"
    [isLoading]="isLoading$ | async"
    (actionButtonClick)="onSubmit()"
    (cancelButtonClick)="dialogRef.close()"
  />
</dartsalesc-base-dialog>
