<div class="avatar">
  @if (avatarUrl) {
    <img
      class="avatar__image"
      alt="organization logo"
      [src]="avatarUrl"
    />
  } @else {
    <img
      class="avatar__empty-avatar"
      alt="organization logo"
      src="/assets/icons/acme.svg"
    />
  }
</div>
