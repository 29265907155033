<mat-select
  #select
  class="mat-select"
  [value]="value"
  [placeholder]="placeholder"
  [compareWith]="compareWith"
  [errorStateMatcher]="errorStateMatcher"
  (selectionChange)="handleSelectionChange($event)"
>
  @for (option of options; track $index) {
    <mat-option
      tabindex="0"
      [value]="option.value"
    >
      {{ option.label }}
    </mat-option>
  }
</mat-select>
<dartsalesc-single-select-cell-renderer
  [isFocusable]="false"
  [value]="value"
  [options]="options"
/>
