import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';
import { ModuleSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/module-settings';
import { ModuleUnitsSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/module-units-settings';
import { DEFAULT_MARGIN_PARAMS } from '@dartsales/common/core/models/margin-params';
import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { TermLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/term-local-storage';
import { TermsTotalsResultType } from '@dartsales/common/core/models/calculators/estimate/services/term-calculator';
import { ModuleType } from '@dartsales/common/core/enums/module-type';
import { EstimateBaseModulesSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-base-modules-settings';
import { PointsListTabLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/point-list-local-storage';
import { TermSubcategoryLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/term-subcategory-local-storage';
import { SingleTermDetailsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/term-details-local-storage';
import { PointsListExpandedColumnsState } from 'projects/web/src/app/features/project-dashboard/pages/systems-page/components/points-list-table/services/points-list-table-columns.service';

import { DEFAULT_PART_TAB_COLUMNS, DEFAULT_PROJECT_TAB_COLUMNS, DEFAULT_SYSTEM_TAB_COLUMNS } from './default-catalog-tabs-settings';

/** Default option units. */
export const DEFAULT_OPTIONS_UNITS: ModuleUnitsSettingsLocalStorage = {
  contingencyUnits: AmountCalcUnits.DEFAULT,
  escalationUnits: AmountCalcUnits.DEFAULT,
  marginParams: DEFAULT_MARGIN_PARAMS,
  dcUnits: AmountCalcUnits.DEFAULT,
};

/** Default module settings. */
export const DEFAULT_MODULE_SETTINGS: ModuleSettingsLocalStorage = {
  units: DEFAULT_OPTIONS_UNITS,
  isExpandedEscalation: false,
  isExpandedPricing: false,
};

/** Default point list expand state. */
export const DEFAULT_POINT_LIST_EXPAND_STATE: PointsListExpandedColumnsState = {
  isLaborSectionExpanded: false,
  isPortsSectionExpanded: false,
  isCustomCategoryExpanded: false,
  isInputCategoryExpanded: false,
  isNetworkCategoryExpanded: false,
  isOtherCategoryExpanded: false,
  isOutputCategoryExpanded: false,
  isNoneCategoryExpanded: false,
  isPowerCategoryExpanded: false,
};

/** Default term settings. */
export const DEFAULT_TERM_SETTINGS: TermLocalStorage = {
  units: DEFAULT_OPTIONS_UNITS,
  isExpand: false,
  isModuleExpand: {
    [ModuleType.Subcontractor]: false,
    [ModuleType.Custom]: false,
    [ModuleType.Expenses]: false,
    [ModuleType.Labor]: false,
    [ModuleType.Material]: false,
  },
};

/** Default term subcategory settings. */
export const DEFAULT_SUBCATEGORY_SETTINGS: TermSubcategoryLocalStorage = {
  isExpand: false,
  units: DEFAULT_OPTIONS_UNITS,
};

/** Default term details settings. */
export const DEFAULT_TERM_DETAILS_SETTINGS: SingleTermDetailsLocalStorage = {
  exandedRowType: null,
  visibleModules: ModuleType.getBaseModules(),
};

/** Base modules settings. */
export const DEFAULT_BASE_MODULES_SETTINGS: EstimateBaseModulesSettingsLocalStorage = {
  [ModuleType.Subcontractor]: {
    isExpandCalculator: true,
    lumpSumPricing: DEFAULT_MODULE_SETTINGS,
    unitPricing: DEFAULT_MODULE_SETTINGS,
  },
  [ModuleType.Labor]: {
    ...DEFAULT_MODULE_SETTINGS,
    isExpandRates: false,
    collapsedRowTypes: [],
    collapsibleRowData: [],
  },
  [ModuleType.Custom]: DEFAULT_MODULE_SETTINGS,
  [ModuleType.Material]: {
    ...DEFAULT_MODULE_SETTINGS,
    isExpandWFS: false,
    isExpandModel: false,
    collapsedRowTypes: [],
    collapsibleRowData: [],
  },
  [ModuleType.Expenses]: {
    calculationDataSection: {
      isExpanded: true,
      isExpandLaborRolesSection: true,
      isExpandResourceSection: true,
    },
    summarySection: {
      isExpanded: true,
      table: DEFAULT_MODULE_SETTINGS,
    },
  },
};

/** Default points list tab settings. */
export const DEFAULT_POINTS_LIST_TAB_SETTINGS: PointsListTabLocalStorage = {
  columnsWidth: {},
  collapsedData: {
    invertedRows: [],
    collapsedRowTypes: [],
  },
};

const DEFAULT_TOTAL_MODULE_EXPAND_STATE = false;

/** Default estimate settings. */
export const DEFAULT_ESTIMATE_SETTINGS: EstimateSettingsLocalStorage = {
  baseModules: DEFAULT_BASE_MODULES_SETTINGS,
  pointList: {
    activeTabId: null,
    expandColumns: DEFAULT_POINT_LIST_EXPAND_STATE,
    tabs: {},
    catalog: {
      width: 300,
      isExpanded: true,
      systemTab: {
        columnsWidth: DEFAULT_SYSTEM_TAB_COLUMNS,
        filterParams: {
          filter: {},
          sort: [{ field: 'name', direction: SortDirection.ASC }],
        },
      },
      partTab: {
        columnsWidth: DEFAULT_PART_TAB_COLUMNS,
        filterParams: {
          filter: {},
          sort: [{ field: 'name', direction: SortDirection.ASC }],
        },
      },
      projectTab: {
        columnsWidth: DEFAULT_PROJECT_TAB_COLUMNS,
        filterParams: {
          filter: {},
          sort: [{ field: 'name', direction: SortDirection.ASC }],
        },
      },
    },
  },
  services: {
    terms: {},
    totalsType: TermsTotalsResultType.Totals,
    visibleModules: ModuleType.getBaseModules(),
  },
  estimateEditorModules: {
    tdcType: AmountCalcUnits.DEFAULT,
    marginType: DEFAULT_OPTIONS_UNITS.marginParams.marginType,
    expandedState: {
      [ModuleType.Subcontractor]: DEFAULT_TOTAL_MODULE_EXPAND_STATE,
      [ModuleType.Expenses]: DEFAULT_TOTAL_MODULE_EXPAND_STATE,
      [ModuleType.Labor]: DEFAULT_TOTAL_MODULE_EXPAND_STATE,
      [ModuleType.Custom]: DEFAULT_TOTAL_MODULE_EXPAND_STATE,
      [ModuleType.Material]: DEFAULT_TOTAL_MODULE_EXPAND_STATE,
    },
  },
  termsDetails: {
    details: {},
    services: {},
    subcategories: {},
  },
  meta: {
    timestamp: new Date().getTime(),
  },
};
