<div class="actions">
  @for (action of actions; track action.label; let index = $index) {
    @if (index !== 0) {
      <mat-divider
        class="actions__divider"
        [vertical]="true"
      />
    }
    <button
      class="actions__button"
      mat-button
      type="button"
      [class.active]="action.type === activeType"
      (click)="action.click()"
    >
      {{ action.label }}
    </button>
  }
</div>
