@if (listManager.pagePagination$ | async; as pagination) {
  <dartsalesc-chips-list-with-suggested
    placeholder="Add Tags"
    suggestedText="Suggested Tags:"
    [optionsList]="options$ | async"
    [formControl]="formControl"
    [maxLength]="50"
    [isLoading]="listManager.isLoading$ | async"
    (searchChange)="onSearchChange($event)"
    (goToNextPage)="listManager.nextPage()"
  />
}
