<dartsalesc-editable-table-cell
  [matTooltip]="errorMessage ?? ''"
  [editModeOnly]="true"
  [isReadonly]="isReadonly"
  (cellBlur)="trigger.closePanel()"
>
  <ng-container dartsalescTableCellEditMode>
    <input
      #trigger="matAutocompleteTrigger"
      dartsalescTableCellInput
      type="text"
      class="autocomplete-input"
      [readonly]="isReadonly"
      [errorStateMatcher]="errorStateMatcher"
      [placeholder]="placeholder"
      [formControl]="autocompleteControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto
      [hideSingleSelectionIndicator]="true"
      [panelWidth]="panelWidth"
      [displayWith]="autocompleteDisplayFn"
    >
      @if ((filteredOptions$ | async)?.length === 0) {
        <mat-option
          tabindex="0"
          [value]="autocompleteControl.value"
        >
          <span class="create-option__label">Create:</span>
          <span class="create-option__value">
            {{ autocompleteDisplayFn(autocompleteControl.value) }}
          </span>
        </mat-option>
      } @else {
        @for (option of filteredOptions$ | async; track option.value) {
          <mat-option
            tabindex="0"
            [value]="option"
            (onSelectionChange)="onOptionSelected($event)"
          >
            <span class="ellipsis">{{ option.label }}</span>
          </mat-option>
        }
      }

      <ng-template #filteredOptions>
        @for (option of filteredOptions$ | async; track option.value) {
          <mat-option
            tabindex="0"
            [value]="option"
            (onSelectionChange)="onOptionSelected($event)"
          >
            <span class="ellipsis">{{ option.label }}</span>
          </mat-option>
        }
      </ng-template>
    </mat-autocomplete>
  </ng-container>
</dartsalesc-editable-table-cell>
