import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { Observable, map } from 'rxjs';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';

/** Table header DC select. */
@Component({
  selector: 'dartsalesw-table-header-dc-select',
  templateUrl: './table-header-dc-select.component.html',
  styleUrls: [
    '../table-header-select-shared.css',
    './table-header-dc-select.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderDcSelectComponent {

  /** Units. */
  @Input()
  public set units(value: AmountCalcUnits) {
    this.unitsControl.setValue(value);
  }

  /** Units change. */
  @Output()
  public readonly unitsChange = new EventEmitter<AmountCalcUnits>();

  private readonly fb = inject(NonNullableFormBuilder);

  /** Contingency units control. */
  protected readonly unitsControl = this.fb.control<AmountCalcUnits>(AmountCalcUnits.Percent);

  /** Type select options list stream. */
  protected readonly typeOptions$ = this.createOptionsStream();

  /** Type short label. */
  protected get shortLabel(): string {
    const controlValue = this.unitsControl.value;
    return this.getTypeLabel(controlValue);
  }

  private createOptionsStream(): Observable<OptionSelect<AmountCalcUnits>[]> {
    const options: readonly OptionSelect<AmountCalcUnits>[] = [
      {
        label: 'DC %',
        value: AmountCalcUnits.Percent,
      },
      {
        label: 'DC $',
        value: AmountCalcUnits.Amount,
      },
    ];

    return listenControlChanges(this.unitsControl).pipe(
      map(value => options.filter(option => option.value !== value)),
    );
  }

  private getTypeLabel(amountType: AmountCalcUnits): string {
    const calcUnitsLabel = AmountCalcUnits.toReadable(amountType);
    return `DC ${calcUnitsLabel}`;
  }
}
