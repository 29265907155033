import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';

import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { matFormFieldControlProviderFor } from '@dartsales/common/core/utils/value-accessors/mat-form-field-value-accessor';
import { NUMBER_WITH_FRACTION_DIGITS_MASK } from '@dartsales/common/core/utils/constants';

import { AmountInputHelpers } from '../../inputs/abstract-input';
import { AbstractOverridableMatFormField } from '../abstract-overridable-mat-form-field';

/** Overridable masked number input. */
@Component({
  selector: 'dartsalesw-overridable-masked-number-input',
  templateUrl: './overridable-masked-number-input.component.html',
  styleUrls: ['./overridable-masked-number-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [matFormFieldControlProviderFor(() => OverridableMaskedNumberInputComponent)],
})
export class OverridableMaskedNumberInputComponent extends AbstractOverridableMatFormField<number> implements OnInit {

  /** Input mask. */
  @Input()
  public mask = NUMBER_WITH_FRACTION_DIGITS_MASK;

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly destroyRef = inject(DestroyRef);

  /** Form control. */
  protected readonly formControl = this.fb.control<string>('');

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: Overridable<number> | null): void {
    if (value != null) {
      this.formControl.patchValue(this.valueToInput(value), { emitEvent: false });
    }
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  private subscribeToControlChanges(): void {
    this.formControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    )
      .subscribe(value => {
        const newValue = AmountInputHelpers.numberFromInput(value);
        this.updateControl(newValue);
      });
  }

  private valueToInput(value: Overridable<number>): string {
    return Inputmask.format(value.combinedValue?.toString() ?? '', this.mask);
  }
}
