import Inputmask from 'inputmask';

import { UserRole } from '../enums/user-role';
import { OptionSelect } from '../models/option-select';
import { AmountCalcUnits } from '../enums/amount-calc-units';

/** Format for date and time string. */
export const DATE_TIME_FORMAT = 'MM.dd.yyyy hh:mm a';

/** User roles which have access to Sales app. */
export const ALLOWED_ROLES = [UserRole.Sales, UserRole.OrganizationAdmin, UserRole.SalesPerson, UserRole.SalesManager];

/** Maximum gross margin percent. It can't be 100 or greater. */
export const MAX_GROSS_MARGIN_PERCENT_NUMBER = 99.99;

/** Fraction digits for round for percent values. */
export const PERCENT_ROUND_FRACTION_DIGITS = 8;

/** Fraction digits for round percent values for compare. */
export const PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE = 4;

/** Fraction digits for round for amount values. */
export const AMOUNT_ROUND_FRACTION_DIGITS = 2;

/** Inputs fraction digits. */
export const INPUTS_FRACTION_DIGITS = 2;

/** Number mask. */
export const NUMBER_MASK: Inputmask.Options = {
  alias: 'numeric',
  groupSeparator: ',',
  placeholder: '0',
  unmaskAsNumber: true,
  rightAlign: false,
};

/** Number with fraction digits mask. */
export const NUMBER_WITH_FRACTION_DIGITS_MASK: Inputmask.Options = {
  ...NUMBER_MASK,
  digits: INPUTS_FRACTION_DIGITS,
  digitsOptional: false,
};

/** Number with optional digits mask. */
export const NUMBER_WITH_OPTIONAL_DIGITS_MASK: Inputmask.Options = {
  ...NUMBER_WITH_FRACTION_DIGITS_MASK,
  digitsOptional: true,
};

/**
 * Currency mask.
 * See docs here: https://github.com/ngneat/input-mask#3-currency.
 */
export const CURRENCY_MASK: Inputmask.Options = {
  ...NUMBER_WITH_FRACTION_DIGITS_MASK,
  prefix: '$ ',
};

/** Percent mask. */
export const PERCENT_MASK: Inputmask.Options = {
  ...NUMBER_WITH_FRACTION_DIGITS_MASK,
  suffix: '%',
};

/** Hours mask. */
export const HOURS_MASK: Inputmask.Options = {
  ...NUMBER_WITH_FRACTION_DIGITS_MASK,
  suffix: 'h',
};

/** Integer mask. */
export const POSITIVE_INTEGER_MASK: Inputmask.Options = {
  ...NUMBER_WITH_FRACTION_DIGITS_MASK,
  digits: 0,
  allowMinus: false,
};

/** Storage keys for estimate entities. */
export const ESTIMATE_STORAGE_KEYS = {
  baseEstimate: 'base_estimate',
  alternateEstimate: 'alternate_estimate',
  changeOrder: 'change_order',
} as const satisfies Record<string, string>;

/** TDC select options. */
export const TDC_SELECT_OPTIONS: readonly OptionSelect<AmountCalcUnits>[] = [
  {
    value: AmountCalcUnits.Amount,
    label: 'TDC $',
  },
  {
    value: AmountCalcUnits.Percent,
    label: 'TDC %',
  },
];

/** DC select options. */
export const DC_SELECT_OPTIONS: readonly OptionSelect<AmountCalcUnits>[] = [
  {
    value: AmountCalcUnits.Amount,
    label: 'DC $',
  },
  {
    value: AmountCalcUnits.Percent,
    label: 'DC %',
  },
];
