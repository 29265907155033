import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-core';

import { Overridable } from '../../utils/types/overridable';

import { AbstractCalculator } from './abstract-calculator';

type DirectCostCalculatorInitArgs = {

  /** Initial direct cost. */
  readonly value: Overridable<number>;
};

/** Basic direct cost calculator. */
export class DirectCostCalculator extends AbstractCalculator<Overridable<number>> {

  /** Result. */
  public readonly result: ReadonlySignal<Overridable<number>>;

  // TODO (Pavel Z.) `result` and `value` look super similar but they're different.
  // Let's review this approach during calculator refactoring.
  /** Value. */
  public readonly value: Signal<Overridable<number>>;

  public constructor(initParams: DirectCostCalculatorInitArgs) {
    super();

    this.value = signal(initParams.value);

    this.result = computed(() => this.calculateResult());
  }

  /** @inheritdoc */
  protected calculateResult(): Overridable<number> {
    return this.value.value;
  }
}
