import { Directive, Input, TemplateRef, inject } from '@angular/core';

/**
 * Directive to provide type for ng-template context.
 * @example
 * ```html
 * <ng-template #myTemplate [dartsalescTemplateContext]="myTemplateContext" let-item>
 *   {{ item.myProperty }}
 *   <!-- ... -->
 * </ng-template>
 * ```
 */
@Directive({
  selector: '[dartsalescTemplateContext]',
})
export class TemplateContextDirective<T> {
  /** Template. */
  public readonly template = inject<TemplateRef<T>>(TemplateRef);

  /**  */
  @Input()
  public dartsalescTemplateContext?: T;

  /**
   * Directive template context guard.
   * @param _dir Directive.
   * @param ctx Context.
   */
  public static ngTemplateContextGuard<T>(
    _dir: TemplateContextDirective<T>,
    ctx: unknown,
  ): ctx is T {
    return true;
  }
}
