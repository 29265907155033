import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap, map, combineLatest, Observable, BehaviorSubject } from 'rxjs';

import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';

/** Checkboxes filters section. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-checkboxes-section',
  templateUrl: './checkboxes-section.component.html',
  styleUrls: ['./checkboxes-section.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxesSectionComponent<T> implements OnInit {

  /** Whether to hide search or not. */
  @Input()
  public hideSearch = false;

  /** Initially selected options. */
  protected readonly preselectedOptions$ = new BehaviorSubject<readonly OptionSelect<T>[]>([]);

  /** Initially selected options. */
  @Input()
  public set preselectedOptions(options: readonly OptionSelect<T>[]) {
    this.preselectedOptions$.next(options);
  }

  /** Active options values. */
  @Input()
  public set activeOptionsValues(options: readonly OptionSelect<T>['value'][]) {
    this.selection.setSelection(...options);
  }

  private readonly allOptions$ = new BehaviorSubject<readonly OptionSelect<T>[]>([]);

  /** All options. */
  @Input()
  public set options(options: readonly OptionSelect<T>[]) {
    this.allOptions$.next(options);
  }

  /** Selected options change. */
  @Output()
  public readonly selectedOptionsChange = new EventEmitter<T[]>();

  /** Search change. */
  @Output()
  public readonly searchChange = new EventEmitter<string>();

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly selection = new SelectionModel<T>(true, []);

  /** Search control. */
  protected readonly searchControl = this.fb.control('');

  /** All options except preselected. */
  protected readonly selectableOptions$ = this.createSelectableOptions();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToSelectedOptionsChange();
    this.subscribeToSearchChange();
  }

  /** Whether some options are selected or not. */
  protected get areSomeOptionsSelected(): boolean {
    return this.selection.hasValue();
  }

  /**
   * Check whether some options are selected or not.
   * @param options Options.
   */
  protected areAllOptionsSelected(options: readonly OptionSelect<T>[]): boolean {
    return options.every(option => this.selection.isSelected(option.value));
  }

  /**
   * Check whether option is selected or not.
   * @param option Option.
   */
  protected isOptionSelected(option: OptionSelect<T>): boolean {
    return this.selection.isSelected(option.value);
  }

  /** Whether indicator is visible. */
  protected get isIndicatorVisible(): boolean {
    return this.areSomeOptionsSelected;
  }

  /**
   * Toggle all options.
   * @param options Options.
   */
  protected toggleAll(options: readonly OptionSelect<T>[]): void {
    const valuesToToggle = options
      .map(option => option.value);
    if (this.areAllOptionsSelected(options)) {
      this.selection.deselect(...valuesToToggle);
    } else {
      this.selection.select(...valuesToToggle);
    }
  }

  /**
   * Change option selection.
   * @param option Option.
   * @param isSelected Is selected.
   */
  protected changeOptionSelection(option: OptionSelect<T>, isSelected: boolean): void {
    if (isSelected) {
      this.selection.select(option.value);
    } else {
      this.selection.deselect(option.value);
    }
  }

  private subscribeToSelectedOptionsChange(): void {
    this.selection.changed.pipe(
      tap(() => {
        this.selectedOptionsChange.emit(this.selection.selected);
      }),
      untilDestroyed(this),
    )
      .subscribe();
  }

  private subscribeToSearchChange(): void {
    listenControlChanges(this.searchControl, { skipInitial: true }).pipe(
      tap(value => this.searchChange.emit(value)),
      untilDestroyed(this),
    )
      .subscribe();
  }

  private createSelectableOptions(): Observable<OptionSelect<T>[]> {
    return combineLatest([
      this.allOptions$,
      this.preselectedOptions$,
    ]).pipe(
      map(([options, preselectedOptions]) =>
        options.filter(option => preselectedOptions.every(preselectedOption => preselectedOption.value !== option.value))),
      untilDestroyed(this),
    );
  }
}
