<div class="header-select">
  <span class="header-units-label">
    {{ shortLabel }}
  </span>
  <mat-select
    class="header-units-select"
    panelClass="units-select-panel"
    [formControl]="unitsControl"
    (selectionChange)="unitsChange.emit($event.value)"
  >
    @for (item of typeOptions$ | async; track item.value) {
      <mat-option
        class="header-units-select__option"
        [value]="item.value"
      >
        {{ item.label }}
      </mat-option>
    }
  </mat-select>

  @if (isEditMode) {
    <div class="input-container">
      @if (cellPrefix) {
        <div class="prefix">
          <ng-content select="[dartsalescTableCellPrefix]" />
        </div>
      }
      <input
        #contingencyInput
        class="input-container__input"
        [class.cell__has-prefix]="cellPrefix"
        [dartsalescInputMask]="inputMask"
        [formControl]="percentControl"
        [readonly]="isLockedValue"
        (blur)="onInputBlur($event)"
        (keydown.enter)="contingencyInput.blur(); $event.stopPropagation()"
      />
    </div>
  } @else {
    <dartsalesw-table-header-readonly-cell
      label="Average percent"
      [value]="controlValue"
    />
  }
</div>

@if (shouldDisplayExpandButton) {
  <dartsalesw-horizontal-expand-button
    class="expand-button"
    [isExpanded]="isExpanded"
    (buttonClick)="expandChange.emit()"
  />
}
