@if (!useTextarea) {
  <input
    matInput
    type="text"
    [formControl]="formControl"
    [placeholder]="placeholder"
  />
} @else {
  <textarea
    matInput
    dartsalescAutoresizeTextarea
    class="textarea"
    [placeholder]="placeholder"
    [formControl]="formControl"
  ></textarea>
}

@if (isResetAvailable) {
  <button
    #resetButton
    matSuffix
    mat-icon-button
    class="reset-button"
    type="button"
    title="Reset value"
    (click)="onResetButtonClick($event)"
  >
    <mat-icon>refresh</mat-icon>
  </button>
}
