<div class="search-filed">
  <input
    matInput
    class="search-input"
    [placeholder]="placeholder"
    [formControl]="searchControl"
    (keydown)="inputKeydown.emit($event)"
  />
  @if (searchControl.value) {
    <button
      mat-icon-button
      type="button"
      class="clear-button"
      (click)="onClear()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  }
</div>
