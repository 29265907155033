@if (!shouldHideValue) {
  <div
    *ngrxLet="
      checkShouldDisplayValue(value)
        ? (value | inputMask: mask)
        : placeholder as displayedValue
    "
    class="content"
    [title]="displayedValue"
  >
    <span
      dartsalescRendererContent
      class="ellipsis"
    >
      {{ displayedValue }}
    </span>
  </div>
}
