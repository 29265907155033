<dartsalesc-editable-table-cell
  class="single-select-table-cell"
  [editModeOnly]="true"
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
>
  @if (!isReadonly) {
    <ng-container dartsalescTableCellEditMode>
      <mat-select
        #select
        dartsalescTableCellInput
        class="single-select-table-cell__select"
        [placeholder]="placeholder"
        [formControl]="selectControl"
        [errorStateMatcher]="errorStateMatcher"
        (selectionChange)="onSelectionChange()"
      >
        <mat-select-trigger>
          <div
            class="select-trigger"
            [title]="select.triggerValue"
          >
            {{ select.triggerValue }}
          </div>
        </mat-select-trigger>
        @for (option of options; track $index) {
          <mat-option
            tabindex="0"
            [value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        }
      </mat-select>
      <div class="suffix">
        @if (isResetAvailable && !isReadonly) {
          <button
            mat-icon-button
            type="button"
            class="reset-button"
            [matTooltip]="tooltipMessage"
            (click)="onResetButtonClick()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        }
      </div>
    </ng-container>
  }

  <div
    dartsalescTableCellViewMode
    class="select select_mode-view"
  >
    @if (currentOption !== null) {
      <span
        class="select__content"
        [title]="currentOption.label"
      >
        {{ currentOption.label }}
      </span>
    } @else if (!isReadonly) {
      <span class="select__placeholder">
        {{ placeholder }}
      </span>
    }
  </div>
</dartsalesc-editable-table-cell>
