<span class="header-units-label">
  {{ select.triggerValue }}
</span>
<mat-select
  #select
  class="header-units-select"
  panelClass="units-select-panel"
  [formControl]="formControl"
  (selectionChange)="selectionChange.emit($event.value)"
>
  @for (item of options; track item) {
    <mat-option
      class="header-units-select__option"
      [value]="item.value"
      [class.header-units-select__option_hidden]="
        item.value === formControl.value
      "
    >
      {{ item.label }}
    </mat-option>
  }
</mat-select>
