import {
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { Observable, map, debounceTime, filter, BehaviorSubject, combineLatest } from 'rxjs';
import { MatIconButton } from '@angular/material/button';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';
import { NUMBER_WITH_FRACTION_DIGITS_MASK, PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE } from '@dartsales/common/core/utils/constants';
import { TableCellPrefixDirective } from '@dartsales/common/shared/components/editable-table-cell/directives/table-cell-prefix.directive';
import { compareRoundNumbers } from '@dartsales/common/core/utils/rounds';

import { AmountInputHelpers } from '../../inputs/abstract-input';

/** Contingency table header cell. */
@UntilDestroy()
@Directive()
export abstract class AbstractAmountPercentTableHeaderCellComponent extends SimpleValueAccessor<number> implements OnInit {

  /** Is cell readonly. */
  @Input()
  public isReadonly = false;

  /** Units. */
  @Input()
  public set units(value: AmountCalcUnits) {
    this.unitsControl.setValue(value);
  }

  /** Is expanded. */
  @Input()
  public isExpanded = true;

  /** Should display expand button. */
  @Input()
  public shouldDisplayExpandButton = false;

  /** Whether value is locked. */
  @Input()
  public isLockedValue = false;

  /** Expanded state change emitter. */
  @Output()
  public readonly expandChange = new EventEmitter<void>();

  /** Units change. */
  @Output()
  public readonly unitsChange = new EventEmitter<AmountCalcUnits>();

  /** Apply percent value. */
  @Output()
  public readonly percentApply = new EventEmitter<number>();

  /** Default input button. */
  @ViewChild(MatIconButton, { read: ElementRef })
  private readonly defaultButton?: ElementRef<HTMLButtonElement>;

  /** Cell prefix. */
  @ContentChild(TableCellPrefixDirective)
  protected readonly cellPrefix?: TableCellPrefixDirective;

  /** Header label. */
  protected abstract headerLabel: string;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Input mask. */
  protected readonly inputMask = NUMBER_WITH_FRACTION_DIGITS_MASK;

  /** Contingency units control. */
  protected readonly unitsControl = this.fb.control<AmountCalcUnits>(AmountCalcUnits.Percent);

  /**
   * Percent value control.
   * This control is for Percent units only because we can't set value as Amount.
   */
  protected readonly percentControl = this.fb.control<string>('');

  /** Type select options list stream. */
  protected abstract readonly typeOptions$: Observable<OptionSelect<AmountCalcUnits>[]>;

  private readonly inputBlurTrigger$ = new BehaviorSubject<void>(undefined);

  private readonly valueChangeSaveTrigger$ = new BehaviorSubject<void>(undefined);

  /** Type short label. */
  protected get shortLabel(): string {
    const controlValue = this.unitsControl.value;
    return this.getTypeLabel(controlValue);
  }

  /** Is edit mode. */
  protected get isEditMode(): boolean {
    return this.unitsControl.value === AmountCalcUnits.Percent && !this.isReadonly;
  }

  /** @inheritdoc */
  public override writeValue(value: number | null): void {
    const percent = this.valueToInput(value ?? 0);
    this.percentControl.setValue(percent, { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToContingencyPercentControlChanges();
    this.subscribeToUnitsControlChanges();
    this.subscribeToInputTriggerChanges();
  }

  /**
   * Handle input blur.
   * @param event Focus event.
   */
  protected onInputBlur(event: FocusEvent): void {
    if (event.relatedTarget !== this.defaultButton?.nativeElement) {
      this.inputBlurTrigger$.next();
    }
  }

  /**
   * Create options.
   * @param amountOptionLabel Amount option label.
   * @param percentOptionLabel Percent option label.
   */
  protected createOptionsStream(
    amountOptionLabel: string,
    percentOptionLabel: string,
  ): Observable<OptionSelect<AmountCalcUnits>[]> {
    const options: readonly OptionSelect<AmountCalcUnits>[] = [
      {
        label: percentOptionLabel,
        value: AmountCalcUnits.Percent,
      },
      {
        label: amountOptionLabel,
        value: AmountCalcUnits.Amount,
      },
    ];

    return listenControlChanges(this.unitsControl).pipe(
      map(value => options.filter(option => option.value !== value)),
    );
  }

  private getTypeLabel(amountType: AmountCalcUnits): string {
    const calcUnitsLabel = AmountCalcUnits.toReadable(amountType);
    return `${this.headerLabel} ${calcUnitsLabel}`;
  }

  /**
   * Convert display format to native value.
   * @param valueVm Display value.
   */
  private valueFromInput(valueVm: string): number {
    return AmountInputHelpers.percentFromInput(valueVm, this.inputMask) ?? 0;
  }

  /**
   * Convert value to display format.
   * @param value Native component value.
   */
  private valueToInput(value: number): string {
    return AmountInputHelpers.percentToInput(value ?? 0);
  }

  private subscribeToContingencyPercentControlChanges(): void {
    const debounceTimeMs = 2000;
    this.percentControl.valueChanges.pipe(
      debounceTime(debounceTimeMs),
      filter(() => !this.percentControl.pristine),
      untilDestroyed(this),
    ).subscribe(() => this.valueChangeSaveTrigger$.next());
  }

  private subscribeToInputTriggerChanges(): void {
    combineLatest([
      this.inputBlurTrigger$,
      this.valueChangeSaveTrigger$,
    ]).pipe(
      untilDestroyed(this),
    )
      .subscribe(() => {
        const valueFromInput = this.valueFromInput(this.percentControl.value);
        const isInputValueChanged = this.controlValue != null &&
          !compareRoundNumbers(valueFromInput, this.controlValue, PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE);
        if (isInputValueChanged) {
          this.controlValue = valueFromInput;
          this.percentApply.emit(valueFromInput);
        }
      });
  }

  private subscribeToUnitsControlChanges(): void {
    listenControlChanges(this.unitsControl).pipe(
      filter(units => units === AmountCalcUnits.Percent),
      untilDestroyed(this),
    )
      .subscribe(() => this.percentControl.markAsPristine());
  }
}
