import { asapScheduler, defer, EMPTY, merge, MonoTypeOperatorFunction, scheduled, Subject } from 'rxjs';
import { finalize, ignoreElements, shareReplay } from 'rxjs/operators';

/**
 * Toggles loading subject when observable execution starts and ends.
 * @param subject$ Execution state subject.
 * Will accept `true` when execution started and `false` when it's finalized.
 */
export function toggleExecutionState<T>(
  subject$: Subject<boolean>,
): MonoTypeOperatorFunction<T> {
  const startLoadingSideEffect$ = defer(() => {
    subject$.next(true);
    return EMPTY;
  });

  return source$ => {
    const sharedSource$ = source$.pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    const finishLoadingSideEffect$ = sharedSource$.pipe(
      ignoreElements(),
      finalize(() => subject$.next(false)),
    );

    return scheduled(
      merge(
        startLoadingSideEffect$,
        finishLoadingSideEffect$,
        sharedSource$,
      ),
      asapScheduler,
    );
  };
}

/**
 * Toggles loading signal when observable execution starts and ends.
 * @param setSignal Function to set signal value.
 * Will accept `true` when execution started and `false` when it's finalized.
 */
export function toggleExecutionStateSignal<T>(
  setSignal: (value: boolean) => void,
): MonoTypeOperatorFunction<T> {
  const startLoadingSideEffect$ = defer(() => {
    setSignal(true);
    return EMPTY;
  });

  return source$ => {
    const sharedSource$ = source$.pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    const finishLoadingSideEffect$ = sharedSource$.pipe(
      ignoreElements(),
      finalize(() => setSignal(false)),
    );

    return scheduled(
      merge(
        startLoadingSideEffect$,
        finishLoadingSideEffect$,
        sharedSource$,
      ),
      asapScheduler,
    );
  };
}
