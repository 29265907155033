<mat-chip-grid
  #chipItem
  matTooltipPosition="above"
  matTooltipClass="array-input-tooltip"
  matTooltip="Press enter to record the value."
  [matTooltipDisabled]="chipItemsControl.value.length > 0"
  [errorStateMatcher]="errorStateMatcher"
>
  @for (item of chipItemsControl.value; track $index; let index = $index) {
    <mat-chip-row
      [editable]="false"
      (removed)="removeChipItem(index)"
    >
      <span [title]="item">
        {{ item }}
      </span>
      <button
        matChipRemove
        type="button"
        [attr.aria-label]="'remove ' + item"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  }
  <input
    matInput
    [placeholder]="placeholder"
    [matChipInputFor]="chipItem"
    (matChipInputTokenEnd)="addChipItem($event)"
  />
</mat-chip-grid>
