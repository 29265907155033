import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DEFAULT_TERM_SETTINGS } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/default-estimate-settings';
import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';
import { RawFormValues } from '@dartsales/common/core/utils/types/form';
import { ModuleType } from '@dartsales/common/core/enums/module-type';

import { BaseModulesLocalStorageFormMapper } from './base-modules-local-storage-form.mapper';
import { SingleTermFormControls } from './forms/terms-settings-form';
import { AbstractKeyValueLocalStorageManagerService } from './abstract-key-value-storage-manager';

/** Terms local storage service. */
@Injectable()
export class TermsLocalStorageManagerService extends AbstractKeyValueLocalStorageManagerService<SingleTermFormControls> {
  private readonly baseModulesLocalStorageFormMapper = inject(BaseModulesLocalStorageFormMapper);

  /** @inheritdoc */
  protected override getSavedSettings(
    key: string,
    settings: EstimateSettingsLocalStorage,
  ): RawFormValues<SingleTermFormControls> {
    return settings.services.terms[key];
  }

  /** @inheritdoc */
  protected override createForm(): FormGroup<SingleTermFormControls> {
    return this.fb.group<SingleTermFormControls>({
      isExpand: this.fb.control(DEFAULT_TERM_SETTINGS.isExpand),
      units: this.baseModulesLocalStorageFormMapper.createModuleUnitsSettingsForm(),
      isModuleExpand: this.fb.group({
        [ModuleType.Subcontractor]: this.fb.control(false),
        [ModuleType.Expenses]: this.fb.control(false),
        [ModuleType.Custom]: this.fb.control(false),
        [ModuleType.Labor]: this.fb.control(false),
        [ModuleType.Material]: this.fb.control(false),
      }),
    });
  }
}
