import { computed, ReadonlySignal } from '@preact/signals-core';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';

import { OverridableMargin } from '../estimate/modules/margin';
import { Overridable } from '../../utils/types/overridable';
import { AmountPercent } from '../estimate/amount-percent';

import { DomainMarginCalculator } from './domain/domain-margin-calculator';
import { AbstractMarginCalculator, AbstractMarginCalculatorInitArgs } from './abstract-margin-calculator';

export type OverridableMarginCalculatorInitArgs = AbstractMarginCalculatorInitArgs<OverridableMargin>;

/** Overridable margin calculation. */
export class OverridableMarginCalculator extends AbstractMarginCalculator<OverridableMargin, Overridable<AmountPercent>> {

  /** @inheritdoc */
  public override readonly result: ReadonlySignal<OverridableMargin>;

  public constructor(private readonly initParams: OverridableMarginCalculatorInitArgs) {
    super(
      initParams,
      args => new OverridableMargin(args),
    );
    this.result = computed(() => this.calculateResult(this.value.value));
  }

  /** @inheritdoc */
  protected override calculateValueByAmount(amount: number): OverridableMargin {
    return DomainMarginCalculator.calculateOverridableMargin({
      directCost: this.initParams.totalDirectCost.peek(),
      source: this.value.peek(),
      values: { override: { amount } },
      marginParams: { marginType: MarginType.GrossMargin, units: AmountCalcUnits.Amount },
    });
  }

  /** @inheritdoc */
  protected override calculateResult(value: OverridableMargin): OverridableMargin {
    const marginType = this.marginType.peek();
    const units = this.units.peek();
    const directCost = this.initParams.totalDirectCost.value;

    return DomainMarginCalculator.calculateOverridableMargin({
      directCost,
      source: value,
      marginParams: { marginType, units },
    });
  }
}
