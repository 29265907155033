import { Injectable, inject } from '@angular/core';

import { BidCategory, BidCategoryEdit } from '../../../models/resources/bid-category';
import { EntityValidationErrors } from '../../../models/errors/app-error';
import { BidCategoryFormValues } from '../../../models/forms/bid-category-form';
import { DateMapper } from '../date-mapper';
import { BidCategoryDto, BidCategoryEditDto, EditBidCategoryOrderDto } from '../dto/resources/bid-category.dto';
import { MappedValidationErrorDto } from '../dto/validation-error.dto';
import { extractErrorMessage } from '../errors/extract-error-message';
import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from '../mappers';

/** Bid category entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class BidCategoryMapper implements
  IMapperFromDto<BidCategoryDto, BidCategory>,
  IMapperToDto<BidCategoryEditDto, BidCategoryEdit>,
  IValidationErrorMapper<BidCategoryEditDto, BidCategoryFormValues> {
  private readonly dateMapper = inject(DateMapper);

  /** @inheritdoc */
  public fromDto(dto: BidCategoryDto): BidCategory {
    return new BidCategory({
      id: dto.id,
      name: dto.name,
      description: dto.description,
      organizationId: dto.organizationId,
      removedAt: this.dateMapper.fromDto(dto.removedAt),
      isDefault: dto.isDefault,
      organizationName: dto.organizationName,
      order: dto.order,
    });
  }

  /** @inheritdoc */
  public toDto(data: BidCategoryEdit): BidCategoryEditDto {
    return {
      name: data.name,
      description: data.description,
      order: data.order,
    };
  }

  /**
   * Map bid category order to dto.
   * @param data Bid category.
   * @param order Bid category order.
   */
  public mapBidCategoryOrderToDto(data: BidCategory, order: number): EditBidCategoryOrderDto {
    return {
      id: data.id,
      order,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<BidCategoryEditDto>,
  ): EntityValidationErrors<BidCategoryFormValues> {
    return {
      name: extractErrorMessage(errorDto.name),
      description: extractErrorMessage(errorDto.description),
    };
  }
}
