<dartsalesc-editable-table-cell
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
  [class.disabled]="disabled"
  (cellFocus)="cellFocus.emit()"
  (cellBlur)="cellBlur.emit()"
>
  @if (textControl.enabled) {
    <ng-container dartsalescTableCellEditMode>
      <input
        #inputElement
        dartsalescTableCellInput
        type="text"
        class="text_mode-edit"
        [placeholder]="isReadonly ? '' : placeholder"
        [formControl]="textControl"
        [errorStateMatcher]="errorStateMatcher"
        [readonly]="isReadonly"
        (keydown.enter)="inputElement.blur()"
      />
    </ng-container>
  }
  <div
    dartsalescTableCellViewMode
    class="text text_mode-view"
  >
    @if (textControl.value !== "") {
      <span
        class="text__content"
        [title]="textControl.value"
      >
        {{ textControl.value }}
      </span>
    } @else if (!isReadonly) {
      <span class="text__placeholder">
        {{ placeholder }}
      </span>
    }
  </div>
  @if (cellSuffix) {
    <ng-container dartsalescTableCellSuffix>
      <ng-content select="[dartsalescTableCellSuffix]" />
    </ng-container>
  }
</dartsalesc-editable-table-cell>
