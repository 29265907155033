<ng-container *ngrxLet="notifications$ | async as messages">
  <button
    *ngrxLet="getMessagesCount(messages) as notificationsCount"
    mat-icon-button
    type="button"
    class="notifications-button"
    matBadgeSize="small"
    [matBadge]="notificationsCount"
    [matBadgeHidden]="!messages?.length"
    [matMenuTriggerFor]="notificationsMenu"
  >
    <mat-icon fontSet="material-icons-outlined">notifications</mat-icon>
  </button>
</ng-container>

<mat-menu
  #notificationsMenu
  class="notifications-menu"
  (closed)="onNotificationsPanelClose()"
>
  @if (notifications$ | async; as messages) {
    <div class="notifications">
      @if (messages.length) {
        @for (message of messages; track $index) {
          <p class="notifications__message">
            {{ message.message }}
          </p>
        }
      } @else {
        <span>No new messages.</span>
      }
    </div>
  }
</mat-menu>
