import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Login } from '../../models/auth/login';
import { PasswordChangeData } from '../../models/auth/password-change';
import { PasswordReset } from '../../models/auth/password-reset';
import { UserSecret } from '../../models/auth/user-secret';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { SuccessResponseDto } from '../mappers/dto/success-response.dto';
import { UserSecretDto } from '../mappers/dto/auth/user-secret.dto';
import { LoginDataMapper } from '../mappers/login-data.mapper';
import { PasswordChangeDataMapper } from '../mappers/password-change-data.mapper';
import { ResetPasswordConfirmationMapper } from '../mappers/reset-password-confirmation.mapper';
import { ResetPasswordMapper } from '../mappers/reset-password.mapper';
import { UserSecretDataMapper } from '../mappers/user-secret-data.mapper';

import { AppUrlsConfig } from './app-urls.config';

/**
 * Performs CRUD operations for auth-related information.
 */
@Injectable({ providedIn: 'root' })
export class AuthApiService {
  private readonly apiUrlsConfig = inject(AppUrlsConfig);

  private readonly httpClient = inject(HttpClient);

  private readonly loginDataMapper = inject(LoginDataMapper);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly userSecretMapper = inject(UserSecretDataMapper);

  private readonly resetPasswordMapper = inject(ResetPasswordMapper);

  private readonly resetPasswordConfirmationMapper = inject(ResetPasswordConfirmationMapper);

  private readonly passwordChangeMapper = inject(PasswordChangeDataMapper);

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: Login): Observable<UserSecret> {
    return this.httpClient.post<UserSecret>(
      this.apiUrlsConfig.auth.login,
      this.loginDataMapper.toDto(loginData),
    )
      .pipe(
        map(dto => this.userSecretMapper.fromDto(dto)),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.loginDataMapper,
        ),
      );
  }

  /**
   * Refresh user's secret.
   * @param secret Secret data.
   */
  public refreshSecret(secret: UserSecret): Observable<UserSecret> {
    return this.httpClient.put<UserSecretDto>(
      this.apiUrlsConfig.auth.refreshSecret,
      this.userSecretMapper.toDto(secret),
    )
      .pipe(
        map(refreshedSecret => this.userSecretMapper.fromDto(refreshedSecret)),
        this.appErrorMapper.catchHttpErrorToAppError(),
      );
  }

  /**
   * Sends request to reset the password.
   * @param data Data for password reset.
   * @returns Email of the user who send the reset.
   */
  public resetPassword(data: PasswordReset.Data): Observable<string> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.auth.resetPassword,
      this.resetPasswordMapper.toDto(data),
    )
      .pipe(
        map(() => data.email),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.resetPasswordMapper,
        ),
      );
  }

  /**
   * Confirms password reset and applies new passwords to the account.
   * @param data New passwords data.
   * @returns Success message.
   */
  public confirmPasswordReset(
    data: PasswordReset.Confirmation,
  ): Observable<string> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.auth.confirmPasswordReset,
      this.resetPasswordConfirmationMapper.toDto(data),
    )
      .pipe(
        map(() => `Your password has been changed`),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.resetPasswordConfirmationMapper,
        ),
      );
  }

  /**
   * Changes password of current user.
   * @param data Data required for password changing.
   */
  public changePassword(data: PasswordChangeData): Observable<void> {
    return this.httpClient.put<void>(
      this.apiUrlsConfig.userAuth.changePassword,
      this.passwordChangeMapper.toDto(data),
    )
      .pipe(
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.passwordChangeMapper,
        ),
      );
  }
}
