<dartsalesc-base-dialog
  class="dialog"
  dialogTitle="Download"
>
  <div class="dialog-content body-2">
    <span class="dialog-content__text">
      If the download does not start automatically, click on the 'Download'
      button.
    </span>
    @if (filename) {
      <a
        mat-flat-button
        color="primary"
        [download]="filename"
        [href]="downloadUrl"
      >
        Download
      </a>
    } @else {
      <a
        mat-flat-button
        color="primary"
        [href]="downloadUrl"
      >
        Download
      </a>
    }
  </div>
  <div
    class="buttons-content"
    dialogFooter
  >
    <button
      mat-flat-button
      type="button"
      color="accent"
      mat-dialog-close
    >
      Close
    </button>
  </div>
</dartsalesc-base-dialog>
