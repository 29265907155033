import { ReadonlySignal, computed } from '@preact/signals-core';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { TermProperties, TermSubmoduleProperties } from '../../../estimate-services/term-properties';
import { EstimateSummaryCalculator, EstimateSummaryCalculatorParams } from '../estimate-summary-calculator';
import { OverridableBasePricing } from '../../../pricing/overridable-base-pricing';
import { ModuleUnitPreferences } from '../estimate-calculator-preferences';
import { TermSummary } from '../../../estimate-services/term-summary';
import { CompoundEscalationCalculator, CompoundEscalationCalculatorInitArgs } from '../../compound-escalation-calculator';
import { DomainTermCalculator } from '../../domain/domain-term-calculator';

/** Type of totals calculation result for services section. */
export enum TermsTotalsResultType {
  Totals = 'Totals',
  Average = 'Average',
}

/** Term calculator. */
export class TermCalculator extends EstimateSummaryCalculator {

  /** @inheritdoc */
  public override readonly escalationCalc: CompoundEscalationCalculator;

  /** @inheritdoc */
  public override readonly result: ReadonlySignal<OverridableBasePricing>;

  /** Subcontractor module. */
  public readonly subcontractor: EstimateSummaryCalculator;

  /** Custom module. */
  public readonly custom: EstimateSummaryCalculator;

  /** Labor module. */
  public readonly labor: EstimateSummaryCalculator;

  /** Material module. */
  public readonly material: EstimateSummaryCalculator;

  /** Expenses module. */
  public readonly expenses: EstimateSummaryCalculator;

  /** Is compound escalation enabled, used to properly add material WFS. */
  private readonly isCompoundEscalationEnabled: boolean;

  public constructor(data: TermCalculatorInitArgs) {
    super(data);

    this.subcontractor = this.createTermSubmoduleSummaryCalculator(data.submoduleTotals.subcontractor);
    this.custom = this.createTermSubmoduleSummaryCalculator(data.submoduleTotals.custom);
    this.labor = this.createTermSubmoduleSummaryCalculator(data.submoduleTotals.labor);
    this.material = this.createTermSubmoduleSummaryCalculator(data.submoduleTotals.material);
    this.expenses = this.createTermSubmoduleSummaryCalculator(data.submoduleTotals.expenses);
    this.isCompoundEscalationEnabled = data.isCompoundEscalationEnabled;

    this.escalationCalc = new CompoundEscalationCalculator({
      isCompoundEscalationEnabled: data.isCompoundEscalationEnabled,
      contingencyCalculator: this.contingencyCalc,
      directCostCalculator: this.directCostCalc,
      escalationValue: data.escalationParams.escalationValue,
      escalationUnits: data.escalationParams.escalationUnits,
    });

    this.result = computed(() => this.calculateResult());
  }

  /** @inheritdoc */
  protected override calculateResult(): OverridableBasePricing {
    return DomainTermCalculator.calculatePricing({
      isCompoundEscalationEnabled: this.isCompoundEscalationEnabled,
      originalPricing: {
        directCost: this.directCostCalc.result.value,
        contingency: this.contingencyCalc.value.value,
        margin: this.marginCalc.value.value,
        escalation: this.escalationCalc.value.value,
        aggregatedWEFS: this.aggregatedWEFS.value,
      },
      contingencyUnits: AmountCalcUnits.Percent,
      marginType: MarginType.GrossMargin,
      marginUnits: AmountCalcUnits.Percent,
      escalationUnits: AmountCalcUnits.Percent,
    });
  }

  /**
   * Creates summary params.
   * @param args Arguments.
   */
  public static createParamsFromTermSummary(args: {
    readonly termSummary: TermSummary;
    readonly preferences: ModuleUnitPreferences;
    readonly marginType: MarginType;
  }): TermCalculatorInitArgs {
    const { termSummary, preferences, marginType } = args;
    const { properties, order } = termSummary;

    const escalationUnits = preferences.escalationUnits ?? AmountCalcUnits.DEFAULT;

    return {
      isCompoundEscalationEnabled: properties.compoundEscalation.isEnabled,
      submoduleTotals: properties.submoduleTotals,
      directCost: properties.directCost,
      contingencyParams: {
        contingencyValue: properties.contingency,
        contingencyUnits: preferences.contingencyUnits ?? AmountCalcUnits.DEFAULT,
      },
      escalationParams: {
        escalationValue: properties.aggregatedWEFS,
        escalationUnits,
      },
      aggregatedWEFS: properties.aggregatedWEFS,
      order,
      marginParams: {
        marginValue: properties.margin,
        marginUnits: preferences.marginUnits ?? AmountCalcUnits.DEFAULT,
        marginType: marginType ?? MarginType.DEFAULT,
      },
      customerMarginParams: {
        value: properties.customerMarkup,
        marginUnits: preferences?.customerMarginUnits ?? AmountCalcUnits.DEFAULT,
        overheadUnits: preferences?.customerOverheadUnits ?? AmountCalcUnits.DEFAULT,
      },
    };
  }

  private createTermSubmoduleSummaryCalculator(
    data: TermSubmoduleProperties,
  ): EstimateSummaryCalculator {
    const overridablePricing = OverridableBasePricing.fromDomainBasePricing(data);
    return new EstimateSummaryCalculator({
      directCost: overridablePricing.directCost,
      contingencyParams: {
        contingencyValue: overridablePricing.contingency,
        contingencyUnits: AmountCalcUnits.DEFAULT,
      },
      escalationParams: {
        escalationValue: data.aggregatedWEFS,
        escalationUnits: AmountCalcUnits.DEFAULT,
      },
      aggregatedWEFS: overridablePricing.aggregatedWEFS,
      marginParams: {
        marginValue: overridablePricing.margin,
        marginUnits: AmountCalcUnits.DEFAULT,
        marginType: MarginType.DEFAULT,
      },
      customerMarginParams: {
        value: data.markup,
        marginUnits: AmountCalcUnits.DEFAULT,
        overheadUnits: AmountCalcUnits.DEFAULT,
      },
    });
  }
}

type TermCalculatorInitArgs =
  & Pick<TermSummary, 'order'>
  & Pick<TermProperties, 'submoduleTotals'>
  & StrictOmit<EstimateSummaryCalculatorParams, 'escalationParams'>
  & {

    /** Is compound escalation enabled. */
    readonly isCompoundEscalationEnabled: boolean;

    /** Contingency params. */
    readonly escalationParams: Pick<
      CompoundEscalationCalculatorInitArgs,
      'escalationUnits' | 'escalationValue'
    >;
  };
