import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';
import { RawFormValues } from '@dartsales/common/core/utils/types/form';
import { DEFAULT_TERM_DETAILS_SETTINGS } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/default-estimate-settings';

import { BaseModulesLocalStorageFormMapper } from './base-modules-local-storage-form.mapper';
import { AbstractKeyValueLocalStorageManagerService } from './abstract-key-value-storage-manager';
import { SingleTermDetailsFormControls } from './forms/terms-details-settings-form';

/** Term services local storage service. */
@Injectable()
export class TermDetailsLocalStorageManagerService extends AbstractKeyValueLocalStorageManagerService<SingleTermDetailsFormControls> {
  private readonly baseModulesLocalStorageFormMapper = inject(BaseModulesLocalStorageFormMapper);

  /** @inheritdoc */
  protected override getSavedSettings(
    key: string,
    settings: EstimateSettingsLocalStorage,
  ): RawFormValues<SingleTermDetailsFormControls> {
    return settings.termsDetails?.details[key];
  }

  /** @inheritdoc */
  protected override createForm(): FormGroup<SingleTermDetailsFormControls> {
    return this.fb.group<SingleTermDetailsFormControls>({
      exandedRowType: this.fb.control(DEFAULT_TERM_DETAILS_SETTINGS.exandedRowType),
      visibleModules: this.fb.control(DEFAULT_TERM_DETAILS_SETTINGS.visibleModules),
    });
  }
}
