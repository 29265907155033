@if (!isReadonly) {
  <dartsalesc-table-cell-bulk-update
    tooltipText="Bulk update is available. Click here to apply changes."
    [isUpdateConfirmed]="!hasUnconfirmedUpdates"
    (confirmUpdate)="onBulkUpdateConfirm($event)"
  >
    @for (item of keyValuePairs; track item.key) {
      @if (!item.value.isConfirmed) {
        <ng-container
          [ngTemplateOutlet]="customTemplates[item.key] ?? defaultTemplate"
          [ngTemplateOutletContext]="{ $implicit: item.value }"
        />
        <ng-template #defaultTemplate>
          <dartsalesw-confirmed-overridable-changes
            [label]="labels[item.key] ?? 'Value'"
            [value]="item.value"
          />
        </ng-template>
      }
    }
  </dartsalesc-table-cell-bulk-update>
}
