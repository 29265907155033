import { Injectable, inject } from '@angular/core';

import { PointsListSystemGroup } from '@dartsales/common/core/models/estimate/modules/points-list/system-group/points-list-system-group';
import { EditPointsListSystemGroup, BatchEditPointsListSystemGroup } from '@dartsales/common/core/models/estimate/modules/points-list/system-group/edit-points-list-system-group';
import { CreatePointsListSystemGroup } from '@dartsales/common/core/models/estimate/modules/points-list/system-group/create-points-list-system-group';

import { PointsListSystemGroupDto, EditPointsListSystemGroupDto, BatchEditPointsListSystemGroupDto, CreatePointsListSystemGroupDto } from '../../../dto/estimate/modules/points-list/system/points-list-system-group.dto';
import { IMapper } from '../../../mappers';

import { PointsListSystemMapper } from './points-list-system.mapper';

/** Points list system group mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemGroupMapper implements IMapper<PointsListSystemGroupDto, PointsListSystemGroup> {
  private readonly pointsListSystemMapper = inject(PointsListSystemMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListSystemGroupDto): PointsListSystemGroup {
    return new PointsListSystemGroup({
      id: dto.id,
      name: dto.name,
      description: dto.description,
      order: dto.order,
      tabId: dto.tabId,
      isDefault: dto.isDefault,
      systems: dto.systems.map(system => this.pointsListSystemMapper.fromDto(system)),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListSystemGroup): PointsListSystemGroupDto {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      order: data.order,
      tabId: data.tabId,
      isDefault: data.isDefault,
      systems: data.systems.map(system => this.pointsListSystemMapper.toDto(system)),
    };
  }

  /**
   * To create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreatePointsListSystemGroup): CreatePointsListSystemGroupDto {
    return {
      name: data.name,
      description: data.description,
      systemIds: data.systemIds,
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListSystemGroup): EditPointsListSystemGroupDto {
    return {
      name: data.name,
      description: data.description,
      order: data.order ?? undefined,
      estimateTabId: data.estimateTabId,
    };
  }

  /**
   * To batch edit DTO.
   * @param data Data.
   */
  public toBatchEditDto(data: BatchEditPointsListSystemGroup): BatchEditPointsListSystemGroupDto {
    return {
      systemGroupId: data.id,
      systemGroup: this.toEditDto(data.value),
    };
  }
}
