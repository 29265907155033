import { Directive, Input } from '@angular/core';
import { MatInput } from '@angular/material/input';

import { RelativePosition, CursorPositionIndex, CursorPositionRelativeToContent, FocusParams } from '../services/cell-renderer.service';

type SelectionRange = {

  /** Start index. */
  readonly startIndex: number;

  /** End index. */
  readonly endIndex: number;
};

/**
 * Editable cell input.
 * The task of the directive is to set the focus to the desired input component position.
 */
@Directive({
  selector: '[dartsalescCellEditorInput]',
  standalone: true,
})
export class CellEditorInputDirective extends MatInput {

  /** Input mask. */
  @Input()
  public inputMask?: Inputmask.Options;

  /**
   * Set focus with position.
   * @param params Focus params.
   */
  public focusWithPosition(params: FocusParams): void {
    if (this._elementRef.nativeElement instanceof HTMLSelectElement) {
      this.focus();
      return;
    }

    const selectionRangeArgs = this.getSelectionRangeArgs(params);
    this._elementRef.nativeElement.setSelectionRange(selectionRangeArgs.startIndex, selectionRangeArgs.endIndex);
    this.focus();

    if (this.inputMask != null) {
      // A timeout is required to render the changes called above.
      setTimeout(() => this.adjustCursorPositionInMaskedInput(), 0);
    }
  }

  /** Table row element. */
  public get tableRowElement(): HTMLTableRowElement | null {
    return this._elementRef.nativeElement.closest('tr') ?? this._elementRef.nativeElement.closest('.table-row');
  }

  /**
   * Get selection range for focus.
   * @param params Focus params.
   * @returns The selection range for the input, taking into account the features of the mask.
   *
   * Warning: Some masks have their own behavioral features for focus cursor position,
   * you can read more in {@link CellEditorInputDirective#adjustCursorPositionInMaskedInput},
   * because of this, the selection range may be incorrect as to where the input mask would put the cursor.
   */
  private getSelectionRangeArgs(params: FocusParams): SelectionRange {
    const { selection } = params;
    if (selection.type === 'index') {
      return this.getSelectionRangeWithIndex(selection);
    }
    if (selection.type === 'all-content') {
      return this.getSelectionRangeForAllContent();
    }
    return this.getRelativeSelectionRange(selection);
  }

  private getSelectionRangeWithIndex({ index }: CursorPositionIndex): SelectionRange {
    const maskPrefixOffset = this.inputMask?.prefix?.length ?? 0;

    if (maskPrefixOffset > index) {
      return this.getRelativeSelectionRange({ type: 'relative', position: RelativePosition.Before });
    }

    const maskSuffixOffset = this.inputMask?.suffix?.length ?? 0;
    if (index > this.value.length - maskSuffixOffset) {
      return this.getRelativeSelectionRange({ type: 'relative', position: RelativePosition.After });
    }

    return { startIndex: index, endIndex: index };
  }

  private getRelativeSelectionRange({ position }: CursorPositionRelativeToContent): SelectionRange {
    if (position === RelativePosition.Before) {
      const maskPrefixOffset = this.inputMask?.prefix?.length ?? 0;
      return { startIndex: maskPrefixOffset, endIndex: maskPrefixOffset };
    }

    const maskSuffixOffset = this.inputMask?.suffix?.length ?? 0;
    const offset = this.value.length - maskSuffixOffset;
    return { startIndex: offset, endIndex: offset };
  }

  private getSelectionRangeForAllContent(): SelectionRange {
    return {
      startIndex: 0,
      endIndex: this.value.length,
    };
  }

  /**
   * Adjust cursor position in masked input.
   * This method implicitly triggers the input mask methods to adjust the cursor position if the current one is incorrect.
   *
   * It needed because some masks have their own behavioral features for focus, for example
   * for a numeric mask, the cursor should usually go to the beginning of the line,
   * but if the value equal to 0, then the cursor should go after.
   */
  private adjustCursorPositionInMaskedInput(): void {
    this._elementRef.nativeElement.click();
  }
}
