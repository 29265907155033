import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';

import { OverridableBasePricing } from '../../pricing/overridable-base-pricing';

import { DomainContingencyCalculator } from './domain-contingency-calculator';
import { DomainMarginCalculator } from './domain-margin-calculator';
import { DomainSellPriceCalculator } from './domain-estimate-sell-price-calculator';
import { DomainCompoundEscalationCalculator } from './domain-compound-escalation-calculator';
import { DomainOverridableBasePricingCalculator } from './domain-overridable-base-pricing-calculator';
import { DomainEscalationCalculator } from './domain-escalation-calculator';

/** Domain term calculator. */
export namespace DomainTermCalculator {

  /** Domain term calculation arguments. */
  export type CalculationArgs = {

    /** Is compound escalation enabled. Needed to properly add material WFS. */
    readonly isCompoundEscalationEnabled: boolean;
  } & DomainOverridableBasePricingCalculator.CalculationArgs;

  /**
   * Calculate all values of OverridableBasePricing models.
   * @param args Calculation arguments.
   * @returns New values for OverridableBasePricing model.
   */
  export function calculatePricing(args: CalculationArgs): OverridableBasePricing {
    const { originalPricing } = args;

    const contingencyUnits = args.contingencyUnits ?? AmountCalcUnits.DEFAULT;
    const marginUnits = args.marginUnits ?? AmountCalcUnits.DEFAULT;
    const marginType = args.marginType ?? MarginType.DEFAULT;
    const escalationUnits = args.escalationUnits ?? AmountCalcUnits.DEFAULT;

    const directCost = originalPricing.directCost.combinedValue;

    const contingency = DomainContingencyCalculator.calculateOverridableContingency({
      directCost,
      value: originalPricing.contingency,
      units: contingencyUnits,
    });

    const escalation = args.isCompoundEscalationEnabled ?
      DomainCompoundEscalationCalculator.calculateOverridableCompoundEscalation({
        directCost,
        contingencyAmount: contingency.combinedValue.amount,
        compoundEscalation: originalPricing.escalation,
      }) :
      DomainEscalationCalculator.calculateOverridableEscalation({
        directCost,
        contingencyAmount: contingency.combinedValue.amount,
        escalationValue: originalPricing.escalation,
        escalationUnits,
      });

    const margin = DomainMarginCalculator.calculateOverridableMargin({
      directCost,
      contingencyAmount: contingency.combinedValue.amount,
      escalationAmount: escalation.combinedValue.amount,
      source: originalPricing.margin,
      marginParams: { marginType, units: marginUnits },
    });

    const sellPrice = DomainSellPriceCalculator.calculateOverridableSellPrice({
      directCost,
      contingencyAmount: contingency.combinedValue.amount,
      marginAmount: margin.grossMargin.combinedValue.amount,
      sellPrice: originalPricing.sellPrice,
      escalationAmount: escalation.combinedValue.amount,
    });

    return new OverridableBasePricing({
      directCost: originalPricing.directCost,
      contingency,
      escalation,
      aggregatedWEFS: originalPricing.aggregatedWEFS,
      margin,
      sellPrice,
    });
  }
}
