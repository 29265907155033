<div class="cell">
  @if (isEditMode$ | async) {
    <dartsalesc-text-cell-editor
      class="editor cell_align-left"
      [errorStateMatcher]="errorStateMatcher"
      [value]="formControl.value"
      [placeholder]="placeholder"
      (valueChange)="onValueChange($event)"
    />
  } @else {
    <dartsalesc-text-cell-renderer
      dartsalescTableCellInput
      class="renderer cell_align-left"
      [value]="controlValue?.combinedValue ?? null"
      [isFocusable]="(isFocusable$ | async) ?? false"
      [placeholder]="placeholder"
    />
  }

  <div class="suffix">
    @if (isResetAvailable && (isReadonly$ | async) === false) {
      <button
        mat-icon-button
        type="button"
        class="reset-button"
        [matTooltip]="tooltipMessage"
        (click)="onResetButtonClick()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    }
  </div>
</div>
