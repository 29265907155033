<dartsalesc-editable-table-cell
  #cell
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
>
  @if (maskedNumberControl.enabled) {
    <ng-container dartsalescTableCellEditMode>
      <input
        #inputElement
        dartsalescTableCellInput
        type="text"
        class="masked-number-edit-mode"
        [placeholder]="placeholder"
        [formControl]="maskedNumberControl"
        [dartsalescInputMask]="mask"
        [readonly]="isReadonly"
        [errorStateMatcher]="errorStateMatcher"
        (keydown.enter)="inputElement.blur()"
      />
    </ng-container>
  }

  @if (hideValue) {
    <div dartsalescTableCellViewMode>
      <!-- Empty. -->
    </div>
  } @else {
    <div
      dartsalescTableCellViewMode
      class="masked-number-view-mode"
    >
      @if (maskedNumberControl.value === "") {
        <span
          class="masked-number-view-mode__content"
          [title]="placeholder"
        >
          {{ placeholder }}
        </span>
      } @else {
        <span
          *ngrxLet="
            mask
              ? (maskedNumberControl.value | inputMask: mask)
              : maskedNumberControl.value as value
          "
          class="masked-number-view-mode__content"
          [class.masked-number-view-mode__content_placeholder]="
            showInitialValueAsPlaceholder && maskedNumberControl.pristine
          "
          [title]="value"
        >
          {{ value }}
        </span>
      }
    </div>
  }

  @if (cellPrefix) {
    <ng-container dartsalescTableCellPrefix>
      <ng-content select="[dartsalescTableCellPrefix]" />
    </ng-container>
  }
</dartsalesc-editable-table-cell>
