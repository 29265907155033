import { isEqual } from 'lodash-es';

/**
 * Remove keys with default value.
 * @param object Object.
 * @param defaultValue Default value.
 */
export function removeKeysWithDefaultValue<V, T extends Record<string, V>>(object: T, defaultValue: V): T {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (isEqual(value, defaultValue)) {
      return acc;
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {} as T);
}
