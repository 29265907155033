import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewChild, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';

import { AbstractCellFormControlComponent } from '@dartsales/common/shared/components/table-cell/base-components/abstract-cell-form-control.component';
import { CELL_PROVIDERS } from '@dartsales/common/shared/components/table-cell/services/cell.provider';
import { TableRowEventsService } from '@dartsales/common/shared/components/editable-table/services/table-row-events.service';
import { TableCellInputDirective } from '@dartsales/common/shared/components/editable-table-cell/directives/table-cell-input.directive';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';

/** Overridable text table cell. */
@Component({
  selector: 'dartsalesc-overridable-text-cell',
  templateUrl: './overridable-text-cell.component.html',
  styleUrls: ['./overridable-text-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...CELL_PROVIDERS],
})
export class OverridableTextCellComponent extends AbstractCellFormControlComponent<ConfirmedOverridable<string>> implements OnInit {

  /** Placeholder. */
  @Input()
  public placeholder = 'Enter value';

  /**
   * Whether cell has unconfirmed override. Override is considered 'unconfirmed' when it was set automatically due to bulk update.
   * @deprecated See OverridableNumberCellComponent for reference.
   */
  @Input()
  public hasUnconfirmedOverride = false;

  /** Disable override display. */
  @Input()
  public disableOverrideDisplay = false;

  /** Renderer element. */
  @ViewChild(TableCellInputDirective)
  private readonly rendererElement?: TableCellInputDirective;

  /** Whether input has override reset available. */
  @HostBinding('class.highlighted')
  protected get isResetAvailable(): boolean {
    return (
      !this.disabled &&
      !this.disableOverrideDisplay &&
      this.controlValue?.override != null &&
      (this.hasUnconfirmedOverride || this.controlValue?.isConfirmed !== false)
    );
  }

  private readonly rowEventsService = inject(TableRowEventsService, { optional: true });

  /** Form control. */
  protected readonly formControl = inject(NonNullableFormBuilder).control('');

  /** @inheritdoc */
  public override writeValue(value: ConfirmedOverridable<string> | null): void {
    this.formControl.setValue(value?.combinedValue ?? '', { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }

    super.setDisabledState(isDisabled);
  }

  /** Tooltip message. */
  protected get tooltipMessage(): string {
    if (this.controlValue?.initial !== null && this.controlValue?.initial !== undefined) {
      return `Reset to value: ${this.controlValue.initial.toString()}`;
    }
    return `Reset value`;
  }

  /** Handle reset button click. */
  protected onResetButtonClick(): void {
    if (this.controlValue !== null) {
      const clearedOverride = new ConfirmedOverridable({
        ...this.controlValue,
        override: null,
      });
      this.controlValue = clearedOverride;
      this.rowEventsService?.triggerCellBlur(this.rendererElement?.tableRowElement ?? null);
    }
  }

  /**
   * Handle value change.
   * @param value Updated value.
   */
  protected onValueChange(value: string): void {
    if (this.disableOverrideDisplay) {
      this.controlValue = new ConfirmedOverridable({
        isConfirmed: true,
        initial: value,
        override: null,
      });
    } else {
      this.controlValue = new ConfirmedOverridable({
        isConfirmed: true,
        initial: this.controlValue?.initial ?? '',
        override: value,
      });
    }

    this.formControl.patchValue(value);
  }
}
