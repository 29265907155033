import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-core';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';

import { AmountPercent } from '../estimate/amount-percent';

import { AbstractCalculator } from './abstract-calculator';
import { DirectCostCalculator } from './direct-cost-calculator';
import { ContingencyCalculator } from './contingency-calculator';
import { DomainEscalationCalculator } from './domain/domain-escalation-calculator';

/** Escalation calculator initialization arguments. */
export type EscalationCalculatorInitArgs = {

  /** Direct cost calculator. */
  readonly directCostCalculator: DirectCostCalculator;

  /** Contingency calculator. */
  readonly contingencyCalculator: ContingencyCalculator;

  /** Escalation initial value. */
  readonly escalationValue: Overridable<AmountPercent>;

  /** Escalation calculation units. */
  readonly escalationUnits?: AmountCalcUnits;
};

/** Escalation calculation utility. */
export class EscalationCalculator extends AbstractCalculator<Overridable<AmountPercent>> {

  /** Result. */
  public readonly result: ReadonlySignal<Overridable<AmountPercent>>;

  /** Value. */
  public readonly value: Signal<Overridable<AmountPercent>>;

  private readonly _units: Signal<AmountCalcUnits>;

  /** Units. */
  public readonly units: ReadonlySignal<AmountCalcUnits>;

  /** Init params. */
  protected readonly initParams: EscalationCalculatorInitArgs;

  public constructor(initParams: EscalationCalculatorInitArgs) {
    super();

    this.initParams = initParams;
    this._units = signal(initParams.escalationUnits ?? AmountCalcUnits.DEFAULT);
    this.units = computed(() => this._units.value);
    this.value = signal(initParams.escalationValue);

    this.result = computed(() => this.calculateResult(this.value.value));
  }

  /**
   * Change current value.
   * @param value Escalation value.
   */
  public setValue(value: Overridable<AmountPercent>): void {
    this.value.value = this.calculateResult(value);
  }

  /**
   * Set percents value.
   * @param percentValue Percents.
   */
  public setPercents(percentValue: Overridable<number>): void {
    this.value.value = this.calculateResult(
      new Overridable({
        initial: new AmountPercent({
          percent: percentValue.initial,
        }),
        override: new AmountPercent({
          percent: percentValue.override ?? undefined,
        }),
      }),
      AmountCalcUnits.Percent,
    );
  }

  /**
   * Set units.
   * @param units Units.
   */
  public setUnits(units: AmountCalcUnits): void {
    this._units.value = units;
  }

  /** @inheritdoc */
  protected calculateResult(value: Overridable<AmountPercent>, units?: AmountCalcUnits): Overridable<AmountPercent> {
    return DomainEscalationCalculator.calculateOverridableEscalation({
      contingencyAmount: this.initParams.contingencyCalculator.result.value.combinedValue.amount,
      directCost: this.initParams.directCostCalculator.result.value.combinedValue,
      escalationUnits: units ?? this.units.peek(),
      escalationValue: value,
    });
  }
}
