import { computed, ReadonlySignal } from '@preact/signals-core';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';

import { AmountPercent } from '../estimate/amount-percent';

import { DomainCompoundEscalationCalculator } from './domain/domain-compound-escalation-calculator';
import { EscalationCalculator, EscalationCalculatorInitArgs } from './escalation-calculator';
import { DomainEscalationCalculator } from './domain/domain-escalation-calculator';

/** Compound escalation calculator initialization arguments. */
export type CompoundEscalationCalculatorInitArgs = {

  /** Is compound escalation enabled. */
  readonly isCompoundEscalationEnabled: boolean;
} & EscalationCalculatorInitArgs;

/** Compound escalation calculation utility. */
export class CompoundEscalationCalculator extends EscalationCalculator {

  /** Result. */
  public override readonly result: ReadonlySignal<Overridable<AmountPercent>>;

  private readonly isCompoundEscalationEnabled: boolean;

  public constructor(initParams: CompoundEscalationCalculatorInitArgs) {
    super(initParams);
    this.isCompoundEscalationEnabled = initParams.isCompoundEscalationEnabled;

    this.result = computed(() => this.calculateResult(this.value.value));
  }

  /** @inheritdoc */
  protected override calculateResult(value: Overridable<AmountPercent>, units?: AmountCalcUnits): Overridable<AmountPercent> {
    if (this.isCompoundEscalationEnabled) {
      return DomainCompoundEscalationCalculator.calculateOverridableCompoundEscalation({
        directCost: this.initParams.directCostCalculator.result.value.combinedValue,
        contingencyAmount: this.initParams.contingencyCalculator.result.value.combinedValue.amount,
        compoundEscalation: value,
      });
    }
    return DomainEscalationCalculator.calculateOverridableEscalation({
      contingencyAmount: this.initParams.contingencyCalculator.result.value.combinedValue.amount,
      directCost: this.initParams.directCostCalculator.result.value.combinedValue,
      escalationUnits: units ?? this.units.peek(),
      escalationValue: value,
    });
  }
}
