import { enumToArray } from '../utils/enum-to-array';

/** Bid lead. */
export enum BidLead {
  BuildingOwner = 'BuildingOwner',
  BuildersExchange = 'BuildersExchange',
  ConstructionDatabase = 'ConstructionDatabase',
  Consultants = 'Consultants',
  Contractor = 'Contractor',
  Internal = 'Internal',
  PaidLeadService = 'PaidLeadService',
  Other = 'Other',
}

/** Namespace related to BidLead. */
export namespace BidLead {
  const TO_TITLE_MAP: Record<BidLead, string> = {
    [BidLead.BuildingOwner]: 'Building Owner',
    [BidLead.BuildersExchange]: 'Builders Exchange',
    [BidLead.ConstructionDatabase]: 'Construction Database',
    [BidLead.Consultants]: 'Consultants',
    [BidLead.Contractor]: 'Contractor',
    [BidLead.Internal]: 'Internal',
    [BidLead.PaidLeadService]: 'Paid Lead Service',
    [BidLead.Other]: 'Other',
  };

  /**
   * Converts a certain bid lead to readable title.
   * @param value Bid lead.
   */
  export function toReadable(value: BidLead): string {
    return TO_TITLE_MAP[value];
  }

  /** Gets list of all bid leads. */
  export function toArray(): BidLead[] {
    return enumToArray(BidLead);
  }
}
