<dartsalesc-editable-table-cell
  #cell
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
  (cellBlur)="onBlur()"
>
  @if (numberControl.enabled) {
    <ng-container dartsalescTableCellEditMode>
      <input
        #inputElement
        dartsalescTableCellInput
        type="number"
        class="number-edit-mode"
        [placeholder]="placeholder"
        [formControl]="numberControl"
        [readonly]="isReadonly"
        [min]="min"
        [errorStateMatcher]="errorStateMatcher"
        (keydown.enter)="inputElement.blur()"
      />
    </ng-container>
  }

  @if (hideValue) {
    <div dartsalescTableCellViewMode>
      <!-- Empty. -->
    </div>
  } @else {
    <div
      dartsalescTableCellViewMode
      class="number-view-mode"
      [class.number-view-mode_has-suffix]="suffix"
    >
      <span
        class="number-view-mode__content"
        [title]="numberControl.value"
      >
        {{ numberControl.value }}
      </span>
    </div>
  }

  @if (suffix && (!hideValue || cell.isEditMode)) {
    <ng-container dartsalescTableCellSuffix>
      {{ suffix }}
    </ng-container>
  }
</dartsalesc-editable-table-cell>
