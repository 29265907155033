import { Observable } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { PointsListSystemApiService } from '@dartsales/common/core/services/api/points-list/points-list-system-api.service';
import { BatchEditPointsListSystemTotals } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system-properties';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';

import { PointsListTransactionUndoCommand } from '../points-list-transaction-undo-command';

/** Update points list systems totals command. */
export class UpdateSystemsTotalsCommand extends PointsListTransactionUndoCommand<UpdateSystemsTotalsCommandInitArgs> {
  private readonly pointsListSystemApiService = this.injector.get(PointsListSystemApiService);

  /** @inheritdoc */
  public override executeAction(): Observable<ApiTransactionId> {
    return this.pointsListSystemApiService.updateSystemsTotals(this.args.estimateId, this.args.newValue);
  }
}

type UpdateSystemsTotalsCommandInitArgs = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;

  /** New value. */
  readonly newValue: readonly BatchEditPointsListSystemTotals[];
};
