import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';

/** Total select component. */
@Component({
  selector: 'dartsalesw-total-select',
  templateUrl: './total-select.component.html',
  styleUrls: ['./total-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => TotalSelectComponent)],
})
export class TotalSelectComponent<T> extends SimpleValueAccessor<T> {
  /** Select options. */
  @Input()
  public options: readonly OptionSelect<T>[] = [];
}
