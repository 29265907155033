@if ((isEditMode$ | async) === false) {
  <dartsalesc-text-cell-renderer
    [placeholder]="placeholder"
    [value]="controlValue ?? ''"
    [isFocusable]="(isFocusable$ | async) ?? false"
  />
} @else {
  <dartsalesc-text-cell-editor
    [placeholder]="placeholder"
    [value]="controlValue ?? ''"
    [errorStateMatcher]="errorStateMatcher"
    (valueChange)="controlValue = $event"
  />
}

@if (cellSuffixTemplate) {
  <ng-container [ngTemplateOutlet]="cellSuffixTemplate" />
}
