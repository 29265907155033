import { FilterData } from '../../filter-data';
import { dateComparison, isEmpty, numberComparison, numberEquals, textContains, textEquals } from '../../shared-filter-operators';
import { organizationNameSelect, projectStatusSelect, stringListSelect } from '../../shared-filter-select-operators';

/** Project field for filter. */
export enum ProjectField {
  OrganizationName = 'projectOrganizationName',
  ProjectName = 'name',
  ProjectNumber = 'projectNumber',
  ProjectId = 'number',
  ProjectDescription = 'projectDescription',
  ProjectStatus = 'status',
  ProjectStartDate = 'projectStartDate',
  ProjectEndDate = 'projectEndDate',
  UUID = 'projectId',
  TotalBuildingSquare = 'projectTotalBuildingSqFt',
  StreetAddress = 'projectAddressStreet',
  SellPrice = 'sellPrice',
  AwardedSellPrice = 'awardedSellPrice',
  City = 'projectAddressCity',
  State = 'projectAddressState',
  Country = 'projectAddressCountry',
  ZIP = 'projectAddressZip',
  BidDueDate = 'projectBidDueDate',
  ProductTypes = 'projectProductTypes',
  Tags = 'projectTags',
}

export namespace ProjectField {
  const projectName = {
    type: ProjectField.ProjectName,
    label: 'Project Name',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectNumber = {
    type: ProjectField.ProjectNumber,
    label: 'Project #',
    operators: [
      ...numberEquals,
      ...numberComparison,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const organizationName = {
    type: ProjectField.OrganizationName,
    label: 'Organization Name',
    operators: [
      ...textContains,
      ...textEquals,
      ...organizationNameSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectId = {
    type: ProjectField.ProjectId,
    label: 'Project ID',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const uuid = {
    type: ProjectField.UUID,
    label: 'UUID',
    operators: [
      ...numberEquals,
      ...numberComparison,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectDescription = {
    type: ProjectField.ProjectDescription,
    label: 'Project Description',
    operators: [
      ...textContains,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectStatus = {
    type: ProjectField.ProjectStatus,
    label: 'Project Status',
    operators: [...projectStatusSelect] as const,
  } satisfies FilterData<ProjectField>;

  const projectStartDate = {
    type: ProjectField.ProjectStartDate,
    label: 'Project Start Date',
    operators: [
      ...dateComparison,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectEndDate = {
    type: ProjectField.ProjectEndDate,
    label: 'Project End Date',
    operators: [
      ...dateComparison,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const buildingSquare = {
    type: ProjectField.TotalBuildingSquare,
    label: 'Total Building Sq. Ft.',
    operators: [
      ...numberEquals,
      ...numberComparison,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const sellPrice = {
    type: ProjectField.SellPrice,
    label: 'Sell Price',
    operators: [
      ...numberEquals,
      ...numberComparison,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const awardedSellPrice = {
    type: ProjectField.AwardedSellPrice,
    label: 'Awarded Sell Price',
    operators: [
      ...numberEquals,
      ...numberComparison,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectStreetAddress = {
    type: ProjectField.StreetAddress,
    label: 'Street Address (Project)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectCity = {
    type: ProjectField.City,
    label: 'City (Project)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectState = {
    type: ProjectField.State,
    label: 'State / Province (Project)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectCountry = {
    type: ProjectField.Country,
    label: 'Country (Project)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectZip = {
    type: ProjectField.ZIP,
    label: 'ZIP / Postal Code (Project)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectBidDueDate = {
    type: ProjectField.BidDueDate,
    label: 'Bid Due Date',
    operators: [
      ...dateComparison,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectProductTypes = {
    type: ProjectField.ProductTypes,
    label: 'Product Types',
    operators: [
      ...textContains,
      ...textEquals,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const projectTags = {
    type: ProjectField.Tags,
    label: 'Tags',
    operators: [
      ...textContains,
      ...textEquals,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ProjectField>;

  const ALL_PROJECT_FIELDS = [
    projectName,
    organizationName,
    projectId,
    projectNumber,
    uuid,
    projectDescription,
    projectStatus,
    projectStartDate,
    projectEndDate,
    buildingSquare,
    projectStreetAddress,
    projectCity,
    projectState,
    projectCountry,
    projectZip,
    projectBidDueDate,
    projectProductTypes,
    projectTags,
    sellPrice,
    awardedSellPrice,
  ] as const;

  export const GROUP_NAME = 'Project';

  /** Get all fields. */
  export function getAllFields(): typeof ALL_PROJECT_FIELDS {
    return ALL_PROJECT_FIELDS;
  }
}
