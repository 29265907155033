<div class="masked-input-container container">
  <input
    class="amount-input body-2"
    placeholder="0"
    [class.amount-input_has-suffix]="suffix"
    [dartsalescInputMask]="mask"
    [formControl]="maskedNumberControl"
  />
  @if (suffix) {
    <span class="suffix">
      {{ suffix }}
    </span>
  }
</div>
