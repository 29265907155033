import { Observable } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { BatchEditPointsListSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system';
import { PointsListSystemApiService } from '@dartsales/common/core/services/api/points-list/points-list-system-api.service';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';

import { PointsListTransactionUndoCommand } from '../points-list-transaction-undo-command';

/** Update points list systems command. */
export class UpdateSystemsCommand extends PointsListTransactionUndoCommand<UpdateSystemsCommandInitArgs> {
  private readonly pointsListSystemApiService = this.injector.get(PointsListSystemApiService);

  /** @inheritdoc */
  public override executeAction(): Observable<ApiTransactionId> {
    return this.pointsListSystemApiService.updateSystems(this.args.estimateId, this.args.newValue);
  }
}

type UpdateSystemsCommandInitArgs = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;

  /** New value. */
  readonly newValue: readonly BatchEditPointsListSystem[];
};
