<dartsalesc-editable-table-cell
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
>
  @if (percentControl.enabled) {
    <ng-container dartsalescTableCellEditMode>
      <input
        #inputElement
        dartsalescTableCellInput
        type="text"
        class="percent-edit-mode"
        [dartsalescInputMask]="percentMask"
        [readonly]="isReadonly"
        [formControl]="percentControl"
        [errorStateMatcher]="errorStateMatcher"
        (keydown.enter)="inputElement.blur()"
      />
    </ng-container>
  }

  @if (hideValue) {
    <div dartsalescTableCellViewMode>
      <!-- Empty. -->
    </div>
  } @else {
    <div
      dartsalescTableCellViewMode
      class="percent-view-mode"
    >
      <span
        class="percent-view-mode__content"
        [title]="percentControl.value | inputMask: percentMask"
      >
        {{ percentControl.value | inputMask: percentMask }}
      </span>
    </div>
  }

  @if (cellPrefix) {
    <ng-container dartsalescTableCellPrefix>
      <ng-content select="[dartsalescTableCellPrefix]" />
    </ng-container>
  }
</dartsalesc-editable-table-cell>
