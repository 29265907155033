@if (isExpandable) {
  <div class="container">
    <ng-container *ngTemplateOutlet="row" />

    @if (isExpanded) {
      <div
        class="details"
        @expand
      >
        <ng-container *ngTemplateOutlet="detailsTemplate" />
      </div>
    }
  </div>
} @else {
  <div
    class="row"
    [class.row_expanded]="isExpanded"
  >
    <ng-content select="beforeExpandButton" />
    @if (isExpandable) {
      <button
        mat-icon-button
        type="button"
        class="row__button"
        matTooltipPosition="above"
        [matTooltip]="buttonTooltip"
        [disabled]="isExpansionDisabled"
        (click)="onExpandButtonClick()"
      >
        <dartsalesc-expand-collapse-icon [isExpanded]="isExpanded" />
      </button>
    }

    <div class="row__content">
      <ng-container *ngTemplateOutlet="rowContentTemplate" />
    </div>
  </div>
}

<ng-template #row>
  <div
    class="row"
    [class.row_expanded]="isExpanded"
  >
    <ng-content select="beforeExpandButton" />
    @if (isExpandable) {
      <button
        mat-icon-button
        type="button"
        class="row__button"
        matTooltipPosition="above"
        [matTooltip]="buttonTooltip"
        [disabled]="isExpansionDisabled"
        (click)="onExpandButtonClick()"
      >
        <dartsalesc-expand-collapse-icon [isExpanded]="isExpanded" />
      </button>
    }

    <div class="row__content">
      <ng-container *ngTemplateOutlet="rowContentTemplate" />
    </div>
  </div>
</ng-template>
