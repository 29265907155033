import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PercentNumberTableCellComponent } from '@dartsales/common/shared/components/editable-table-cell/components/percent-number-table-cell/percent-number-table-cell.component';
import { MAX_GROSS_MARGIN_PERCENT_NUMBER, PERCENT_MASK } from '@dartsales/common/core/utils/constants';

/** Gross margin percent number table cell component. */
@Component({
  selector: 'dartsalesc-gross-margin-percent-number-table-cell',
  templateUrl: './percent-number-table-cell.component.html',
  styleUrls: ['./percent-number-table-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class GrossMarginPercentNumberTableCellComponent extends PercentNumberTableCellComponent {

  /** @inheritdoc */
  protected override readonly percentMask: Inputmask.Options = {
    ...PERCENT_MASK,
    max: MAX_GROSS_MARGIN_PERCENT_NUMBER,
  };
}
