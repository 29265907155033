import { computed, inject } from '@angular/core';
import { AbstractControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { map, Observable, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { listenFormRawValueChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';
import { ControlsOf, RawFormValues } from '@dartsales/common/core/utils/types/form';

import { injectEstimateService } from '../estimate.service';

import { KeyValueSettingsFormControls } from './forms/key-value-settings-form';
import { StorageSettingValue } from './storage-setting-value';

/** Abstract key value local storage service. */
export abstract class AbstractKeyValueLocalStorageManagerService<
  TSingleFormControls extends ControlsOf<Record<string, unknown>>,
> {
  /** From builder. */
  protected readonly fb = inject(NonNullableFormBuilder);

  private readonly estimateService = injectEstimateService();

  private readonly form = this.fb.group<KeyValueSettingsFormControls<TSingleFormControls>>({});

  /** Value changes. */
  public readonly valueChanges$ = listenFormRawValueChanges(this.form, { debounceTime: 0 }).pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** Create form. */
  protected abstract createForm(): FormGroup<TSingleFormControls>;

  /** Get saved setting from estimate settings. */
  protected abstract getSavedSettings(
    key: string,
    settings: EstimateSettingsLocalStorage,
  ): RawFormValues<TSingleFormControls> | null;

  /**
   * Get settings.
   * @param key$ Key.
   * @param selector Selector.
   */
  public getSettings<TControl extends AbstractControl>(
    key$: Observable<string | number>,
    selector: (form: FormGroup<TSingleFormControls>) => TControl,
  ): StorageSettingValue<ReturnType<TControl['getRawValue']>> {
    const settingsForm$ = key$.pipe(
      map(key => String(key)),
      withLatestFrom(this.estimateService.estimateSettings$),
      map(([key, settings]) => this.initializeFormGroup(key, settings)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    const form = toSignal(settingsForm$);

    // We can't use 'listenRawFormValues' here because it has debounce inside it.
    const formValue = toSignal<ReturnType<TControl['getRawValue']>>(settingsForm$.pipe(
      switchMap(settingsForm => settingsForm.valueChanges.pipe(
        startWith(null),
        map(() => selector(settingsForm).getRawValue()),
      )),
    ));

    const defaultValue = selector(this.createForm()).getRawValue();

    return {
      value: computed(() => formValue() ?? defaultValue),
      set(value) {
        const settingsForm = form();
        if (settingsForm != null) {
          selector(settingsForm).patchValue(value);
        }
      },
    };
  }

  private initializeFormGroup(
    key: string,
    settings: EstimateSettingsLocalStorage,
  ): FormGroup<TSingleFormControls> {
    const controlName = key;
    if (!this.form.contains(controlName)) {
      const group = this.createForm();
      this.form.addControl(controlName, group);
    }

    const formGroup = this.form.controls[controlName];

    const savedSettings = this.getSavedSettings(key, settings);
    if (savedSettings != null) {
      formGroup.patchValue(savedSettings);
    }

    return formGroup;
  }
}
