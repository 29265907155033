import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { map, Observable } from 'rxjs';

import { FilterComponentType, injectFilterControl } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';
import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { CurrentUserService } from '@dartsales/common/core/services/current-user.service';

/** Number filter input component. */
@Component({
  selector: 'dartsalesc-organization-name-filter-input',
  templateUrl: './organization-name-filter-input.component.html',
  styleUrls: ['./organization-name-filter-input.component.css'],

  // We have nested form controls. 'Default' change detection is required for displaying errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OrganizationNameFilterInputComponent implements FilterComponentType<string[]> {
  private readonly currentUserService = inject(CurrentUserService);

  /** @inheritdoc */
  public readonly formControl = injectFilterControl<string[]>();

  /** Options. */
  protected readonly organizationOptions$ = this.createOrganizationNameOptions();

  private createOrganizationNameOptions(): Observable<OptionSelect<string>[]> {
    return this.currentUserService.userOrganizations$.pipe(
      map(organizations => organizations.map<OptionSelect<string>>(item => ({
        label: item.name,
        value: item.name,
      }))),
    );
  }
}
