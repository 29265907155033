<div class="format-select">
  <label class="format-select__label caption-2">Export as:</label>
  <mat-radio-group
    class="format-select__selects"
    [(ngModel)]="controlValue"
  >
    @for (option of supportedExportFormats; track option) {
      <mat-radio-button [value]="option">
        {{ exportFormat.toReadable(option) }}
      </mat-radio-button>
    }
  </mat-radio-group>
</div>
