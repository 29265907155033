import { Directive, ElementRef, inject, Input, OnDestroy } from '@angular/core';

/** This directive helps to dynamically change the input mask if the input is displayed by condition. */
@Directive({
  selector: 'input[dartsalescInputMask]',
})
export class InputMaskDirective implements OnDestroy {

  /** Input mask. */
  @Input({ required: true })
  public set dartsalescInputMask(value: Inputmask.Options) {
    this.inputMaskPlugin = Inputmask(value).mask(this.inputElement);
  }

  private inputMaskPlugin: Inputmask.Instance | null = null;

  private readonly inputElement = inject<ElementRef<HTMLInputElement>>(ElementRef).nativeElement;

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.inputMaskPlugin?.remove();
  }
}
