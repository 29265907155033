import { inject, Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';

import { UserDataFormControls } from '@dartsales/common/core/models/forms/user-data-form';
import { UserBase } from '@dartsales/common/core/models/user/user-base';
import { RawFormValues } from '@dartsales/common/core/utils/types/form';
import { AppValidators } from '@dartsales/common/core/utils/validators';
import { EditUserProfile } from '@dartsales/common/core/models/user/user-profile';

import { IFormToModel, IModelToForm } from './form-mappers';

/** User data form mapper. */
@Injectable({ providedIn: 'root' })
export class UserDataFormMapper implements
  IFormToModel<UserDataFormControls, EditUserProfile>,
  IModelToForm<UserBase, UserDataFormControls> {

  private readonly fb = inject(NonNullableFormBuilder);

  /** @inheritdoc */
  public formToModel(formValues: RawFormValues<UserDataFormControls>): EditUserProfile {
    return {
      ...formValues,
    };
  }

  /** @inheritdoc */
  public modelToForm(model?: UserBase): FormGroup<UserDataFormControls> {
    return this.fb.group<UserDataFormControls>({
      email: this.fb.control(model?.email ?? '', [Validators.required, Validators.email]),
      firstName: this.fb.control(model?.firstName ?? '', [Validators.required, AppValidators.notWhiteSpacesOnly, Validators.maxLength(50)]),
      lastName: this.fb.control(model?.lastName ?? '', [Validators.required, AppValidators.notWhiteSpacesOnly, Validators.maxLength(60)]),
      title: this.fb.control(model?.title ?? ''),
    });
  }
}
