<mat-select
  class="estimate-select select caption-1"
  placeholder="Select"
  [(ngModel)]="controlValue"
>
  @for (option of options; track $index) {
    <mat-option [value]="option.value">
      {{ option.label }}
    </mat-option>
  }
</mat-select>
