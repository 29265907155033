import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { OptionSelect } from '@dartsales/common/core/models/option-select';

import { TableCellFormControl } from '../table-cell-form-control.component';

/** Single Select table cell component. */
@Component({
  selector: 'dartsalesc-single-select-table-cell',
  templateUrl: './single-select-table-cell.component.html',
  styleUrls: ['./single-select-table-cell.component.css'],

  // We have ErrorStateMatcher so we need Default change detection to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SingleSelectTableCellComponent<T> extends TableCellFormControl<T | null> {
  /** Select options. */
  @Input()
  public options: readonly OptionSelect<T>[] = [];

  /**
   * Compare function.
   * @param first First.
   * @param second Second.
   */
  @Input()
  public compareWith = (first?: T, second?: T): boolean => first === second;

  /** Whether cell is read-only. */
  @Input()
  public isReadonly = false;

  /** Placeholder. */
  @Input()
  public placeholder = 'Select value';

  /**
   * Disable override display.
   * This property is a workaround for complex cases where we have single form component where inputs are
   * overridable in one case and non-overridable in another (for instance points list part edit form).
   */
  @Input()
  public disableOverrideDisplay = false;

  /** Select element. */
  @ViewChild(MatSelect)
  protected readonly selectElement?: MatSelect;

  /** Selection change. */
  @Output()
  public readonly selectionChange = new EventEmitter<T | null>();

  private readonly fb = inject(NonNullableFormBuilder);

  /** Select form control. */
  protected readonly selectControl = this.fb.control<T | null>(null);

  /** Currently selected option. */
  protected get currentOption(): OptionSelect<T> | null {
    return this.options.find(option => option.value === this.controlValue) ?? null;
  }

  /** @inheritdoc */
  public override writeValue(value: T | null): void {
    this.selectControl.setValue(value);
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /** Handle selection change. */
  protected onSelectionChange(): void {
    if (this.selectControl.enabled) {
      this.controlValue = this.selectControl.value;
      this.selectionChange.emit(this.selectControl.value);
    }
  }
}
