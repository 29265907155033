import { enumToArray } from '../utils/enum-to-array';

/** User Roles on Organization level. */
export enum UserRole {

  /** OrganizationAdmin. */
  OrganizationAdmin = 'OrganizationAdmin',

  /** UserAdmin. */
  UserAdmin = 'UserAdmin',

  /** Manager. */
  Manager = 'Manager',

  /** Developer. */
  Developer = 'Developer',

  /** Sales manager. */
  Sales = 'Sales',

  /** Sales person. */
  SalesPerson = 'SalesPerson',

  /** Sales manager. */
  SalesManager = 'SalesManager',

  /** Project CX engineer. */
  ProjectCxEngineer = 'ProjectCxEngineer',

  /** Project developer. */
  ProjectDeveloper = 'ProjectDeveloper',

  /** Project engineer. */
  ProjectEngineer = 'ProjectEngineer',

  /** Project technician. */
  ProjectTechnician = 'ProjectTechnician',
}

export namespace UserRole {
  const TITLE_MAP: Record<UserRole, string> = {
    [UserRole.OrganizationAdmin]: 'Organization Admin',
    [UserRole.UserAdmin]: 'User Admin',
    [UserRole.Manager]: 'Manager',
    [UserRole.Developer]: 'Developer',
    [UserRole.Sales]: 'Sales',
    [UserRole.SalesManager]: 'Sales Manager',
    [UserRole.SalesPerson]: 'Salesperson',
    [UserRole.ProjectCxEngineer]: 'Project CX Engineer',
    [UserRole.ProjectDeveloper]: 'Project Developer',
    [UserRole.ProjectEngineer]: 'Project Engineer',
    [UserRole.ProjectTechnician]: 'Project Technician',
  };

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  export function toReadable(value: UserRole): string {
    const readable = TITLE_MAP[value];
    if (readable === undefined) {
      return '';
    }
    return readable;
  }

  /** Get list of all enum items. */
  export function toArray(): UserRole[] {
    return enumToArray(UserRole);
  }
}
