import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip } from 'rxjs';

import { FilterComponentType, injectFilterControl } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';
import { ValuesRange } from '@dartsales/common/core/models/values-range';
import { listenFormRawValueChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { ControlsOf } from '@dartsales/common/core/utils/types/form';
import { ValuesRangeStateMatcher } from '@dartsales/common/core/utils/values-range-state-matcher';

/** Date range filter input component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-date-range-filter-input',
  templateUrl: './date-range-filter-input.component.html',
  styleUrls: ['./date-range-filter-input.component.css'],

  // We have nested form controls. 'Default' change detection is required for displaying errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DateRangeFilterInputComponent implements FilterComponentType<ValuesRange<Date | null>>, OnInit {
  private readonly fb = inject(NonNullableFormBuilder);

  /** @inheritdoc */
  public readonly formControl = injectFilterControl<ValuesRange<Date | null>>();

  /** Error state matcher. */
  protected readonly matcher = new ValuesRangeStateMatcher(this.formControl);

  /** Date range form. */
  protected readonly dateRangeForm = this.createDateRangeForm(this.formControl.value);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  private subscribeToControlChanges(): void {
    listenFormRawValueChanges(this.dateRangeForm).pipe(
      skip(1),
      untilDestroyed(this),
    )
      .subscribe(value => this.formControl.patchValue(value));
  }

  private createDateRangeForm(initialValue: ValuesRange<Date | null>): FormGroup<ControlsOf<ValuesRange<Date | null>>> {
    return this.fb.group<ControlsOf<ValuesRange<Date | null>>>({
      start: this.fb.control(initialValue.start),
      end: this.fb.control(initialValue.end),
    });
  }
}
