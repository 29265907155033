import { EstimateBaseModuleKey } from '../models/estimate/modules-list';
import { enumToArray } from '../utils/enum-to-array';

/** Module type. */
export enum ModuleType {
  Subcontractor = 0,
  Expenses = 1,
  Labor = 2,
  Material = 3,
  Custom = 4,
  Totals = 5,
  PointsList = 6,
  Services = 7,
}

export namespace ModuleType {

  const TO_READABLE_MODULE_MAP: Readonly<Record<ModuleType, string>> = {
    [ModuleType.Subcontractor]: 'Subcontractor',
    [ModuleType.Expenses]: 'Expenses',
    [ModuleType.Custom]: 'Custom',
    [ModuleType.Labor]: 'Labor',
    [ModuleType.Material]: 'Material',
    [ModuleType.PointsList]: 'Points list',
    [ModuleType.Services]: 'Services',
    [ModuleType.Totals]: 'Totals',
  };

  const TO_MODULE_KEY_MAP: Readonly<Record<ModuleType, EstimateBaseModuleKey | null>> = {
    [ModuleType.Subcontractor]: 'subcontractor',
    [ModuleType.Expenses]: 'expenses',
    [ModuleType.Custom]: 'custom',
    [ModuleType.Labor]: 'labor',
    [ModuleType.Material]: 'material',
    [ModuleType.PointsList]: null,
    [ModuleType.Services]: null,
    [ModuleType.Totals]: null,
  };

  /**
   * Gets module type human-readable representation.
   * @param moduleType Module type.
   */
  export function toReadable(moduleType: ModuleType): string {
    return TO_READABLE_MODULE_MAP[moduleType];
  }

  /** Returns all variants of module type. */
  export function toArray(): ModuleType[] {
    return enumToArray(ModuleType);
  }

  /**
   * Gets module key from module type.
   * @param moduleType Module type.
   */
  export function toKey(moduleType: ModuleType): EstimateBaseModuleKey | null {
    return TO_MODULE_KEY_MAP[moduleType];
  }

  const baseModules = [
    ModuleType.Subcontractor,
    ModuleType.Expenses,
    ModuleType.Custom,
    ModuleType.Labor,
    ModuleType.Material,
  ] as const;

  /** Returns base modules. */
  export function getBaseModules(): typeof baseModules {
    return baseModules;
  }
}

export type BaseModulesTypes = ReturnType<typeof ModuleType.getBaseModules>[number];
