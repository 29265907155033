<ng-container
  *ngrxLet="{
    isEditMode: (isEditMode$ | async) ?? false,
    isFocusable: (isFocusable$ | async) ?? false,
  } as data"
>
  @if (!data.isEditMode) {
    <dartsalesc-single-select-cell-renderer
      [value]="controlValue"
      [options]="options"
      [class.focusable]="data.isFocusable"
      [isFocusable]="data.isFocusable"
    />
  } @else {
    <dartsalesc-single-select-cell-editor
      [errorStateMatcher]="errorStateMatcher"
      [compareWith]="compareWith"
      [options]="options"
      [value]="controlValue"
      (valueChange)="controlValue = $event"
    />
  }
</ng-container>
