<button
  mat-icon-button
  type="button"
  aria-label="expand row"
  class="button"
  (click)="buttonClick.emit()"
>
  @if (isHalfRotation) {
    <div [@indicator90Rotate]="isExpanded ? 'expanded' : 'collapsed'">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
  } @else {
    <div [@indicatorRotate]="isExpanded ? 'expanded' : 'collapsed'">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </div>
  }
</button>
