<span class="header-units-label">
  {{ shortLabel }}
</span>
<mat-select
  class="header-units-select"
  panelClass="units-select-panel"
  [formControl]="unitsControl"
  (selectionChange)="unitsChange.emit($event.value)"
>
  @for (item of typeOptions$ | async; track item.value) {
    <mat-option
      class="header-units-select__option"
      [value]="item.value"
    >
      {{ item.label }}
    </mat-option>
  }
</mat-select>
