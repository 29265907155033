import { DestroyRef, inject, Injectable } from '@angular/core';
import { AbstractControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { combineLatest, debounceTime, first, map, Observable, skip, switchMap, tap, withLatestFrom } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { EstimateSettingsStorageService } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/estimate-settings-storage.service';
import { RawFormValues } from '@dartsales/common/core/utils/types/form';
import { listenFormRawValueChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { DEFAULT_ESTIMATE_SETTINGS, DEFAULT_POINT_LIST_EXPAND_STATE, DEFAULT_POINTS_LIST_TAB_SETTINGS, DEFAULT_SUBCATEGORY_SETTINGS, DEFAULT_TERM_DETAILS_SETTINGS, DEFAULT_TERM_SETTINGS } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/default-estimate-settings';
import { DEFAULT_SYSTEM_TAB_COLUMNS, DEFAULT_PART_TAB_COLUMNS, DEFAULT_PROJECT_TAB_COLUMNS } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/default-catalog-tabs-settings';
import { PaletteSharedFilters } from '@dartsales/common/core/models/filters/palette-shared-filters';
import { PalettePartFilters } from '@dartsales/common/core/models/filters/palette-part-filters';
import { PaletteSystemFilters } from '@dartsales/common/core/models/filters/palette-system-filters';
import { CatalogTab, PointListLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/point-list-local-storage';
import { ResizableColumnsWidth } from '@dartsales/common/core/utils/catalog-table/catalog-table-columns-resize-manager';
import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';
import { ModuleType } from '@dartsales/common/core/enums/module-type';

import { injectEstimateService } from '../estimate.service';

import {
  EstimateSettingsFormControls,
  PointListSettingsFormControls,
  CatalogTabSettingsFormControls,
  ServicesSettingsFormControls,
  PointListCatalogSettingsFormControls,
} from './forms/estimate-settings-form';
import { TermsLocalStorageManagerService } from './terms-local-storage-manager.service';
import { BaseModulesLocalStorageFormMapper } from './base-modules-local-storage-form.mapper';
import { TermsSettingsValues } from './forms/terms-settings-form';
import { removeKeysWithDefaultValue } from './utils/remove-keys-with-default-value';
import { PointsListTabsLocalStorageManagerService } from './points-list-tabs-local-storage-manager.service';
import { PointsListTabsSettingsValues } from './forms/points-list-tabs-settings-form';
import { TermDetailsSettingsValues, TermSubcategoriesSettingsValues } from './forms/terms-details-settings-form';
import { TermServicesLocalStorageManagerService } from './term-services-local-storage-manager.service';
import { TermSubcategoriesLocalStorageManagerService } from './term-subcategories-local-storage-manager.service';
import { TermDetailsLocalStorageManagerService } from './term-details-local-storage-manager.service';
import { StorageSettingValue } from './storage-setting-value';
import { EstimateEditorModulesSettingsFormControls } from './forms/estimate-editor-modules-settings-form';
import { TermServicesSettingsValues } from './forms/term-service-settings-form';

/** Local storage manager service. */
@Injectable()
export class LocalStorageManagerService {
  private readonly fb = inject(NonNullableFormBuilder);

  private readonly destroyRef = inject(DestroyRef);

  private readonly estimateStorageService = inject(EstimateSettingsStorageService);

  private readonly termsLocalStorageManagerService = inject(TermsLocalStorageManagerService);

  private readonly termServicesLocalStorageManagerService = inject(TermServicesLocalStorageManagerService);

  private readonly termSubcategoriesLocalStorageManagerService = inject(TermSubcategoriesLocalStorageManagerService);

  private readonly pointsListTabsLocalStorageManagerService = inject(PointsListTabsLocalStorageManagerService);

  private readonly termDetailsLocalStorageManagerService = inject(TermDetailsLocalStorageManagerService);

  private readonly baseModulesLocalStorageFormMapper = inject(BaseModulesLocalStorageFormMapper);

  private readonly estimateService = injectEstimateService();

  private readonly form = this.createStorageManagerForm();

  public constructor() {
    this.estimateService.estimateSettings$.pipe(
      first(),
      tap(settings => this.form.patchValue(settings)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    combineLatest([
      listenFormRawValueChanges(this.form, { debounceTime: 0 }),
      this.termsLocalStorageManagerService.valueChanges$,
      this.pointsListTabsLocalStorageManagerService.valueChanges$,
      this.termServicesLocalStorageManagerService.valueChanges$,
      this.termSubcategoriesLocalStorageManagerService.valueChanges$,
      this.termDetailsLocalStorageManagerService.valueChanges$,
    ])
      .pipe(
        debounceTime(1000),

        // We want to skip initial value.
        skip(1),
        withLatestFrom(this.estimateService.estimateId$, this.estimateService.estimateSettings$),
        switchMap(([[estimateSettings, terms, pointsListTabs, termsServices, termSubcategories, termDetails], estimateId, oldSettings]) =>
          this.saveToStorage({
            estimateId,
            estimateSettings,
            terms,
            pointsListTabs,
            termsServices,
            termSubcategories,
            termDetails,
            oldSettings,
          })),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  /**
   * Get settings. We use this to avoid change detection issues.
   * @param selector Selector.
   */
  public getSettings<TControl extends AbstractControl>(
    selector: (form: FormGroup<EstimateSettingsFormControls>) => TControl,
  ): StorageSettingValue<ReturnType<TControl['value']>> {
    const control = selector(this.form);
    const valueChanges$ = control.valueChanges.pipe(
      map(() => control.getRawValue()),
    );

    return {
      value: toSignal(valueChanges$, { initialValue: control.value }),
      set(value) {
        control.patchValue(value);
      },
    };
  }

  private saveToStorage(args: {
    estimateId: EstimateId;
    estimateSettings: RawFormValues<EstimateSettingsFormControls>;
    terms: TermsSettingsValues;
    pointsListTabs: PointsListTabsSettingsValues;
    termsServices: TermServicesSettingsValues;
    termSubcategories: TermSubcategoriesSettingsValues;
    termDetails: TermDetailsSettingsValues;
    oldSettings: EstimateSettingsLocalStorage;
  }): Observable<void> {
    return this.estimateStorageService.save(args.estimateId, {
      meta: {
        timestamp: new Date().getTime(),
      },
      ...args.estimateSettings,
      services: {
        ...args.estimateSettings.services,
        terms: removeKeysWithDefaultValue({
          ...args.oldSettings.services.terms,
          ...args.terms,
        }, DEFAULT_TERM_SETTINGS),
      },
      pointList: {
        ...args.estimateSettings.pointList,
        tabs: removeKeysWithDefaultValue({
          ...args.oldSettings.pointList.tabs,
          ...args.pointsListTabs,
        }, DEFAULT_POINTS_LIST_TAB_SETTINGS),
      },
      estimateEditorModules: {
        ...args.estimateSettings.estimateEditorModules,
        expandedState: {
          ...args.oldSettings.estimateEditorModules?.expandedState,
          ...args.estimateSettings.estimateEditorModules.expandedState,
        },
      },
      termsDetails: {
        services: removeKeysWithDefaultValue({
          ...args.oldSettings.termsDetails.services,
          ...args.termsServices,
        }, DEFAULT_TERM_SETTINGS),
        subcategories: removeKeysWithDefaultValue({
          ...args.oldSettings.termsDetails.subcategories,
          ...args.termSubcategories,
        }, DEFAULT_SUBCATEGORY_SETTINGS),
        details: removeKeysWithDefaultValue({
          ...args.oldSettings.termsDetails.details,
          ...args.termDetails,
        }, DEFAULT_TERM_DETAILS_SETTINGS),
      },
    });
  }

  private createStorageManagerForm(): FormGroup<EstimateSettingsFormControls> {
    return this.fb.group<EstimateSettingsFormControls>({
      baseModules: this.baseModulesLocalStorageFormMapper.createBaseModulesForm(),
      pointList: this.createStoragePointListForm(),
      services: this.createServicesSettingsForm(),
      estimateEditorModules: this.createEstimateEditorModulesSettingsForm(),
    });
  }

  private createStoragePointListForm(): FormGroup<PointListSettingsFormControls> {
    return this.fb.group<PointListSettingsFormControls>({
      activeTabId: this.fb.control<PointListLocalStorage['activeTabId']>(null),
      expandColumns: this.fb.control(DEFAULT_POINT_LIST_EXPAND_STATE),
      catalog: this.fb.group<PointListCatalogSettingsFormControls>({
        width: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.width),
        isExpanded: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.isExpanded),
        systemTab: this.createCatalogTabSettingsForm<PaletteSystemFilters>(
          DEFAULT_SYSTEM_TAB_COLUMNS,
          DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.systemTab,
        ),
        partTab: this.createCatalogTabSettingsForm<PalettePartFilters>(
          DEFAULT_PART_TAB_COLUMNS,
          DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.partTab,
        ),
        projectTab: this.createCatalogTabSettingsForm(
          DEFAULT_PROJECT_TAB_COLUMNS,
          DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.projectTab,
        ),
      }),
    });
  }

  private createCatalogTabSettingsForm<TFilter extends PaletteSharedFilters>(
    defaultColumnsWidth: ResizableColumnsWidth, defaultTab: CatalogTab<TFilter>,
  ): FormGroup<CatalogTabSettingsFormControls<TFilter>> {
    return this.fb.group<CatalogTabSettingsFormControls<TFilter>>({
      columnsWidth: this.fb.control(defaultColumnsWidth),
      filterParams: this.fb.group({
        filter: this.fb.control(defaultTab.filterParams.filter),
        sort: this.fb.control(defaultTab.filterParams.sort),
      }),
    });
  }

  private createServicesSettingsForm(): FormGroup<ServicesSettingsFormControls> {
    return this.fb.group<ServicesSettingsFormControls>({
      totalsType: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.services.totalsType),
      visibleModules: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.services.visibleModules),
    });
  }

  private createEstimateEditorModulesSettingsForm(): FormGroup<EstimateEditorModulesSettingsFormControls> {
    return this.fb.group<EstimateEditorModulesSettingsFormControls>({
      tdcType: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.estimateEditorModules.tdcType),
      marginType: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.estimateEditorModules.marginType),
      expandedState: this.fb.group({
        [ModuleType.Subcontractor]: this.fb.control(false),
        [ModuleType.Expenses]: this.fb.control(false),
        [ModuleType.Labor]: this.fb.control(false),
        [ModuleType.Custom]: this.fb.control(false),
        [ModuleType.Material]: this.fb.control(false),
      }),
    });
  }
}
