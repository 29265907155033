@if (selectedItemsCount === 0) {
  <ng-container
    [ngTemplateOutlet]="singleItemDragTemplate ?? defaultSingleDragTemplate"
  />
} @else {
  <ng-container
    [ngTemplateOutlet]="
      multipleItemsDragTemplate ?? defaultMultipleItemsDragTemplate
    "
  />
}

<ng-template #defaultSingleDragTemplate>Item</ng-template>

<ng-template #defaultMultipleItemsDragTemplate>
  {{ selectedItemsCount }} selected
</ng-template>
