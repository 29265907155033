import { inject, Injectable } from '@angular/core';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

import { ModuleType } from '@dartsales/common/core/enums/module-type';
import { AbstractCollapseRowsService, CollapseData } from '@dartsales/common/shared/components/ag-grid-table/services/abstract-table-collapse-rows.service';
import { LocalStorageManagerService } from 'projects/web/src/app/features/project-dashboard/services/local-storage/local-storage-manager.service';

import { MaterialTableRowType } from '../view-models/material-table-row-view-model';
import { MaterialTableRowKeysService } from '../mappers/material-table-row-keys.service';

/** Material module collapse rows service. */
@Injectable()
export class MaterialTableCollapseRowsService extends AbstractCollapseRowsService<MaterialTableRowType> {

  private readonly localStorageManagerService = inject(LocalStorageManagerService);

  private readonly materialTableRowKeysService = inject(MaterialTableRowKeysService);

  private readonly startCollapseData$ = this.createStartCollapseDataStream();

  /** @inheritdoc */
  public override readonly collapseChanged$ = this.createCollapseChangedStream(this.startCollapseData$);

  /** @inheritdoc */
  public readonly collapsibleRowTypes: readonly MaterialTableRowType[] = [
    'standardMaterialGroup',
    'materialsType',
  ];

  /** Whether non-standard part row is collapsed. */
  public checkIsNonStandardPartRowCollapsed(): boolean {
    return this.checkIsRowCollapsed({
      key: 'nonStandardMaterials',
      type: 'materialsType',
    });
  }

  /**
   * Whether standard part row is collapsed.
   * @param groupId Group ID.
   */
  public checkIsStandardPartRowCollapsed(groupId: number): boolean {
    return this.checkIsRowCollapsed({
      key: this.materialTableRowKeysService.createStandardMaterialGroupKey(groupId),
      type: 'standardMaterialGroup',
    });
  }

  private createStartCollapseDataStream(): Observable<CollapseData<MaterialTableRowType>> {
    const collapsedRowTypes$ = toObservable(this.localStorageManagerService.getSettings(
      form => form.controls.baseModules.controls[ModuleType.Material].controls.collapsedRowTypes,
    ).value).pipe(
      distinctUntilChanged(),
    );
    const collapsibleRowData$ = toObservable(this.localStorageManagerService.getSettings(
      form => form.controls.baseModules.controls[ModuleType.Material].controls.collapsibleRowData,
    ).value).pipe(
      distinctUntilChanged(),
    );

    return combineLatest([
      collapsedRowTypes$,
      collapsibleRowData$,
    ]).pipe(
      map(([collapsedRowTypes, collapsibleRowData]) => ({ collapsedRowTypes, invertedRows: collapsibleRowData })),
    );
  }
}
