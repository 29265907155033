import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges, SimpleChange, Type, ViewContainerRef, inject } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { FilterComponentType, FILTER_COMPONENT_CONTROL, FILTER_COMPONENT_DATA } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';
import { FilterOperator } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-operator';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';

/** Filter form control outlet component. */
@Component({
  selector: 'dartsalesc-filter-form-control-outlet',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFormControlOutletComponent extends SimpleValueAccessor<unknown> implements OnChanges {
  private readonly ngControl = inject(NgControl, { optional: true, self: true });

  private readonly viewContainerRef = inject(ViewContainerRef);

  /** Component. */
  @Input()
  public operator?: FilterOperator;

  /** Form control. */
  public get formControl(): FormControl<unknown> | null {
    return this.ngControl?.control as FormControl<unknown>;
  }

  public constructor() {
    super();

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  /** @inheritdoc */
  public ngOnChanges(changes: { operator: SimpleChange; }): void {
    if (changes.operator != null && this.formControl) {
      const current = changes.operator.currentValue as FilterFormControlOutletComponent['operator'];
      const previous = changes.operator.previousValue as FilterFormControlOutletComponent['operator'];

      const isDifferent = current?.component !== previous?.component || current?.componentPayload !== previous?.componentPayload;

      if (isDifferent) {
        this.viewContainerRef.clear();
        this.formControl.reset();
      }

      if (current?.component && isDifferent) {
        this.createComponent(current.component, current.componentPayload);
      }

      // If control is empty or has default value of previous operator, then we should update its value.
      if (
        (!this.formControl.value || previous?.defaultValue === this.formControl.value || (isDifferent && previous !== undefined)) &&
        current?.defaultValue !== undefined
      ) {
        this.formControl.setValue(current.defaultValue);
      }

      this.updateValidators(this.formControl, this.operator);

      // We need this setTimeout to manually trigger re-render.
      setTimeout(() => {
        this.changeDetectorRef.markForCheck();
      }, 0);
    }
  }

  private createComponent(component: Type<FilterComponentType<unknown, unknown>>, payload: FilterOperator['componentPayload']): void {
    const injector = Injector.create({
      providers: [
        {
          provide: FILTER_COMPONENT_CONTROL,
          useValue: this.ngControl?.control,
        },
        {
          provide: FILTER_COMPONENT_DATA,
          useValue: payload,
        },
      ],
      parent: this.viewContainerRef.injector,
    });
    this.viewContainerRef.createComponent(component, {
      injector,
    });
  }

  private updateValidators(control: FormControl<unknown>, operator?: FilterOperator): void {
    control.clearValidators();
    if (operator) {
      control.addValidators(operator.validators);
    }
    control.updateValueAndValidity();
  }
}
