import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DEFAULT_POINTS_LIST_TAB_SETTINGS } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/default-estimate-settings';
import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';
import { RawFormValues } from '@dartsales/common/core/utils/types/form';

import { SinglePointsListTabFormControls } from './forms/points-list-tabs-settings-form';
import { AbstractKeyValueLocalStorageManagerService } from './abstract-key-value-storage-manager';

/** Points list tabs local storage service. */
@Injectable()
export class PointsListTabsLocalStorageManagerService extends AbstractKeyValueLocalStorageManagerService<SinglePointsListTabFormControls> {
  /** @inheritdoc */
  protected override getSavedSettings(
    key: string,
    settings: EstimateSettingsLocalStorage,
  ): RawFormValues<SinglePointsListTabFormControls> {
    return settings.pointList.tabs[key];
  }

  /** @inheritdoc */
  protected override createForm(): FormGroup<SinglePointsListTabFormControls> {
    return this.fb.group<SinglePointsListTabFormControls>({
      columnsWidth: this.fb.control(DEFAULT_POINTS_LIST_TAB_SETTINGS.columnsWidth),
      collapsedData: this.fb.control(DEFAULT_POINTS_LIST_TAB_SETTINGS.collapsedData),
    });
  }
}
