@if (label) {
  <span class="subtitle-2">{{ label }}:</span>
}

<div class="values-wrapper">
  <span
    class="previous-value line-clamp"
    [title]="previousValue ?? placeholder"
  >
    {{ previousValue ?? placeholder }}
  </span>
  <span class="arrow-divider">&rarr;</span>
  <span
    class="current-value line-clamp"
    [title]="currentValue ?? placeholder"
  >
    {{ currentValue ?? placeholder }}
  </span>
</div>
