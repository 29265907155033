<div class="cell">
  @if (isEditMode$ | async) {
    <dartsalesc-masked-number-cell-editor
      class="editor"
      [ngClass]="getCellCssClasses()"
      [errorStateMatcher]="errorStateMatcher"
      [mask]="mask"
      [formControl]="formControl"
      [placeholder]="placeholder"
    />
  } @else if (!hideValue) {
    <dartsalesc-masked-number-cell-renderer
      dartsalescTableCellInput
      class="renderer"
      [ngClass]="getCellCssClasses()"
      [value]="controlValue?.combinedValue ?? null"
      [mask]="mask"
      [isFocusable]="(isFocusable$ | async) ?? false"
      [placeholder]="placeholder"
    />
  }

  <div [ngClass]="getResetButtonCssClasses()">
    @if (isResetAvailable && (isReadonly$ | async) === false) {
      <button
        mat-icon-button
        type="button"
        class="reset-button"
        [matTooltip]="tooltipMessage"
        (click)="onResetButtonClick()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    }
  </div>
</div>
