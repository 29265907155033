<nav
  mat-tab-nav-bar
  mat-stretch-tabs="true"
  mat-align-tabs="start"
  class="navigation-tabs"
  [tabPanel]="tabPanel"
>
  @for (link of links; track $index) {
    <a
      #rla="routerLinkActive"
      mat-tab-link
      routerLinkActive
      class="navigation-tabs__link"
      [active]="rla.isActive"
      [routerLink]="link.path"
      [queryParams]="queryParams$ | async"
    >
      {{ link.label }}
    </a>
  }
</nav>
<mat-tab-nav-panel
  #tabPanel
  class="tab-content"
>
  <router-outlet />
</mat-tab-nav-panel>
