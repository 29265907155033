import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ContentChildren,
  QueryList,
  ChangeDetectionStrategy,
  TrackByFunction,
  ElementRef,
} from '@angular/core';
import { Sort as MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { trackByIndex } from '@dartsales/common/core/utils/trackby';
import { PaginationData } from '@dartsales/common/core/models/list-utilities/pagination-data';
import { TableColumnInfo } from '@dartsales/common/core/models/list-utilities/table-column-info';
import { Sort } from '@dartsales/common/core/models/list-utilities/sort';

import { TableColumnDirective } from '../../directives/table/table-column.directive';
import { ScrollToTopDirective } from '../../directives/scroll-to-top.directive';

import { matSortToInternalSort } from './utils/mat-sort-to-internal';
import { resolvePath } from './utils/resolve-path';

/** Base table component. */
@Component({
  selector: 'dartsalesc-base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseTableComponent<T> {
  /** Loading indicator. */
  @Input()
  public loading: boolean | null = false;

  /** Columns information. */
  @Input()
  public columns: TableColumnInfo[] | null = [];

  /** Sort settings. */
  @Input()
  public sort?: Sort | null;

  /** Whether rows should be clickable. */
  @Input()
  public clickableRows: boolean | null = false;

  /** Message that displays when items not found. */
  @Input()
  public emptyMessage = 'No items found.';

  /** Whether last column in table is sticky (fixed). */
  @Input()
  public isStickyLastColumn = false;

  /** Selected item id for clickable rows. */
  @Input()
  public selectedItemId: number | null = null;

  /** Function for trackBy. */
  @Input()
  public trackBy: TrackByFunction<T> = trackByIndex;

  /** Items to display. */
  @Input()
  public set rows(value: T[] | null) {
    if (value) {
      this.dataSource.data = value;
    }
  }

  private _pagination = new PaginationData();

  /** Pagination settings. */
  @Input()
  public set pagination(value: PaginationData | null) {
    if (value !== null) {
      this.checkPaginationAndScrollToTop(value);
      this._pagination = value;
    }
  }

  /** Pagination settings. */
  public get pagination(): PaginationData {
    return this._pagination;
  }

  /** Emitted when the item clicked. */
  @Output()
  public readonly itemClick = new EventEmitter<T>();

  /** Emitted when sort changes. */
  @Output()
  public readonly sortChange = new EventEmitter<Sort | undefined>();

  /** Emitted when pagination changes. */
  @Output()
  public readonly paginationChange = new EventEmitter<PaginationData>();

  /** Table holder element. */
  @ViewChild('tableHolder', { read: ElementRef })
  protected readonly table?: ElementRef<HTMLDivElement>;

  /** Columns templates. */
  @ContentChildren(TableColumnDirective)
  private readonly columnTemplates?: QueryList<TableColumnDirective>;

  /** Scroll directive. */
  @ViewChild(ScrollToTopDirective)
  private readonly scrollToTopDirective?: ScrollToTopDirective;

  /** Internal representation of data source for support of native sorting feature. */
  protected readonly dataSource = new MatTableDataSource<T>([]);

  /**
   * Used in `paginationChanged`.
   * Because we have hack (destroy instance) in `ScrollToTopDirective`,
   * after we reset pagination and scrolling to top, `(scrolled)` event is triggered.
   * In such case, we getting two pagination changes at the same time (page=1, page=2),
   * that leads to loosing first page data and showing second page data.
   */
  private isScrolledToTop = false;

  /** Handle click on the specific item. */
  protected get columnNames(): string[] {
    return this.columns?.map(c => c.name) ?? [];
  }

  /**
   * Handle click on the specific item.
   * @param item Item that was clicked.
   */
  protected onItemClick(item: T): void {
    if (this.clickableRows) {
      this.itemClick.emit(item);
    }
  }

  /**
   * Handle sort changing.
   * @param matSort Material sort event.
   */
  protected onSortChange(matSort: MatSort): void {
    const sort = matSortToInternalSort(matSort);
    this.sortChange.emit(sort);
  }

  /**
   * Get cell template by the name.
   * @param name Column name.
   */
  protected getColumnByName(name: string): TableColumnDirective | undefined {
    return this.columnTemplates?.find(column => column.name === name);
  }

  /**
   * Paginator changed.
   * @param pagination Current pagination.
   */
  protected paginationChanged(pagination: PaginationData): void {
    // See `isScrolledToTop` jsdoc.
    if (this.isScrolledToTop) {
      this.isScrolledToTop = false;
      return;
    }

    if (!pagination.isLastPage) {
      this.paginationChange.emit(pagination.fork({
        page: pagination.page + 1,
      }));
    }
  }

  /**
   * Get table column header text (only used for default header).
   * @param column Column info.
   * @param textFromDirective Column header text from TableColumn directive.
   */
  protected getTableHeaderText(column: TableColumnInfo, textFromDirective: string): string {
    const textFromInfo = column.headerText ?? column.name ?? '';
    return textFromDirective ?? textFromInfo;
  }

  /**
   * Get object value by key.
   * @param obj Object.
   * @param key Key.
   */
  protected getValue(obj: Record<string, unknown>, key: string): unknown {
    return resolvePath(key, obj);
  }

  private checkPaginationAndScrollToTop(pagination: PaginationData): void {
    if (pagination.page === 1 && this.pagination.page !== pagination.page) {
      this.scrollToTop();
    }
  }

  /** Scroll table to top. */
  private scrollToTop(): void {
    this.isScrolledToTop = true;

    // Timeout is required in order for the scroll to work after rendering the new received data.
    setTimeout(() => {
      this.scrollToTopDirective?.scrollToTop();
    }, 0);
  }
}
