import { Signal, ReadonlySignal, signal, computed } from '@preact/signals-core';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';

import { AmountPercent } from '../../estimate/amount-percent';
import { AbstractCalculator } from '../abstract-calculator';
import { OverridableMarginCalculator } from '../overridable-margin-calculator';
import { DomainCustomerOverheadCalculator } from '../domain/domain-customer-overhead-calculator';
import { OverridableMargin } from '../../estimate/modules/margin';

type EstimateCustomerOverheadCalculatorParams = {

  /** Total direct cost (in $). */
  readonly totalDirectCost: ReadonlySignal<number>;

  /** Sell price (in $). */
  readonly sellPrice: ReadonlySignal<number>;

  /** Margin. */
  readonly margin: AmountPercent;

  /** Margin units. */
  readonly marginUnits: AmountCalcUnits;

  /** Margin type. */
  readonly marginType?: MarginType;

  /** Overhead units. */
  readonly overheadUnits: AmountCalcUnits;
};

/** Estimate customer overhead calculator. */
export class EstimateCustomerOverheadCalculator extends AbstractCalculator<AmountPercent> {

  /** @inheritdoc */
  public override readonly result: ReadonlySignal<AmountPercent>;

  /** Customer margin. */
  public readonly margin: OverridableMarginCalculator;

  /** Total direct cost. */
  public readonly totalDirectCost: ReadonlySignal<number>;

  private readonly _units: Signal<AmountCalcUnits>;

  public constructor(private readonly initParams: EstimateCustomerOverheadCalculatorParams) {
    super();

    this._units = signal(initParams.overheadUnits ?? AmountCalcUnits.DEFAULT);
    this.totalDirectCost = computed(() => initParams.totalDirectCost.value);

    this.margin = new OverridableMarginCalculator({
      totalDirectCost: computed(() => this.totalDirectCost.value),
      marginValue: OverridableMargin.fromValues({
        initial: {
          amount: initParams.margin.amount,
          grossMargin: initParams.margin.percent,
          markup: initParams.margin.percent,
        },
      }),
      marginUnits: initParams.marginUnits,
      marginType: initParams.marginType,
    });

    this.result = computed(() => this.calculateResult());
  }

  /** Units. */
  public get units(): ReadonlySignal<AmountCalcUnits> {
    return computed(() => this._units.value);
  }

  /**
   * Set units.
   * @param units Units.
   */
  public setUnits(units: AmountCalcUnits): void {
    this._units.value = units;
  }

  /**
   * Set result.
   * @param expectedResult Expected result.
   */
  public setResult(expectedResult: AmountPercent): void {
    const marginAmount = this.initParams.sellPrice.value - this.totalDirectCost.value;
    if (this._units.value === AmountCalcUnits.Amount) {
      const newMarginAmount = marginAmount - expectedResult.amount;
      this.margin.setAmount(newMarginAmount);
    } else if (this._units.value === AmountCalcUnits.Percent) {
      const newMarginAmount = marginAmount - (expectedResult.percent * this.totalDirectCost.value);
      this.margin.setAmount(newMarginAmount);
    }
  }

  /** @inheritdoc  */
  protected override calculateResult(): AmountPercent {
    const marginType = this.margin.marginType.peek();
    const margin = marginType === MarginType.Markup ?
      this.margin.result.value.markup.combinedValue :
      this.margin.result.value.grossMargin.combinedValue;

    return DomainCustomerOverheadCalculator.calculateOverhead({
      directCost: this.initParams.totalDirectCost.value,
      sellPrice: this.initParams.sellPrice.value,
      margin,
    });
  }
}
