import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AMOUNT_ROUND_FRACTION_DIGITS, CURRENCY_MASK } from '@dartsales/common/core/utils/constants';
import { ValueAccessorDecomposition, provideValueAccessorDecomposition } from '@dartsales/common/core/utils/value-accessors/value-accessor-decomposition';
import { roundToFixed } from '@dartsales/common/core/utils/rounds';
import { parseMaskedNumber } from '@dartsales/common/core/utils/parse-masked-number';

import { BaseCellEditorComponent } from '../../../base-components/base-cell-editor.component';

/** Masked number cell editor component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-masked-number-cell-editor',
  templateUrl: './masked-number-cell-editor.component.html',
  styleUrls: ['./masked-number-cell-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessorDecomposition()],
})
export class MaskedNumberCellEditorComponent extends BaseCellEditorComponent<number> implements OnInit {

  /** Placeholder. */
  @Input()
  public placeholder = '0';

  /** Input mask, currency mask is used by default. */
  @Input()
  public mask = CURRENCY_MASK;

  /** Form control. */
  protected readonly formControl = inject(NonNullableFormBuilder).control('');

  private readonly valueAccessor = inject<ValueAccessorDecomposition<number>>(ValueAccessorDecomposition);

  /** @inheritdoc */
  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToNotTrustedChanges();
    this.subscribeToControlChanges();
  }

  private subscribeToNotTrustedChanges(): void {
    this.valueAccessor.outerChanges$.pipe(
      untilDestroyed(this),
    ).subscribe(changes => {
      this.formControl.setValue(changes?.value?.toString() ?? '', { emitEvent: false });
    });
  }

  private subscribeToControlChanges(): void {
    this.formControl.valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe(value => {
      const oldValue = roundToFixed(this.valueAccessor.controlValue ?? 0, AMOUNT_ROUND_FRACTION_DIGITS);
      const newNumberValue = roundToFixed(parseMaskedNumber(value, this.mask), AMOUNT_ROUND_FRACTION_DIGITS);
      if (oldValue !== newNumberValue) {
        this.changeValue(newNumberValue);
      }
    });
  }

  private changeValue(value: number): void {
    this.valueAccessor.controlValue = value;
    this.valueChange.emit(value);
  }
}
