<!-- View mode layout. -->
<div
  class="view-mode"
  [class.view-mode_hidden]="isEditModeEnabled"
>
  @if (viewModeChild) {
    <ng-content select="[dartsalescTableCellViewMode]" />
  } @else {
    <!-- If we have edit mode child then we can try to display it's value. -->
    @if (editModeChild) {
      <div class="default-view-mode">
        @if (editModeChild.input?.value) {
          <span class="default-view-mode__text">
            {{ editModeChild.input?.value }}
          </span>
        } @else {
          <div class="view-mode__placeholder">
            {{ editModeChild.input?.placeholder }}
          </div>
        }
      </div>
    }
  }
</div>

<!-- Edit mode layout. -->
<div
  class="edit-mode"
  [class.edit-mode_visible]="isEditModeEnabled"
>
  <ng-content select="[dartsalescTableCellEditMode]" />
</div>

<!-- Prefix element layout. -->
@if (cellPrefix) {
  <div class="prefix">
    <ng-content select="[dartsalescTableCellPrefix]" />
  </div>
}

<!-- Suffix element layout. -->
@if (cellSuffix) {
  <div class="suffix">
    <ng-content select="[dartsalescTableCellSuffix]" />
  </div>
}
