import { Injectable, inject } from '@angular/core';

import { PointsListPartGroup } from '@dartsales/common/core/models/estimate/modules/points-list/part-group/points-list-part-group';
import { BatchEditPointsListPartGroup, EditPointsListPartGroup } from '@dartsales/common/core/models/estimate/modules/points-list/part-group/edit-points-list-part-group';
import { CreatePointsListPartGroup, CreatePointsListPartGroupFromPalette } from '@dartsales/common/core/models/estimate/modules/points-list/part-group/create-points-list-part-group';

import { IMapper } from '../../../mappers';
import { PointsListPartGroupDto, EditPointsListPartGroupDto, CreatePointsListPartGroupDto, BatchEditPointsListPartGroupDto, CreatePointsListPartGroupFromPaletteDto } from '../../../dto/estimate/modules/points-list/part/points-list-part-group.dto';

import { PointsListPartMapper } from './points-list-part.mapper';
import { PointsListPartGroupPropertiesMapper } from './points-list-part-group-properties.mapper';

/** Points list system group mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListPartGroupMapper implements IMapper<PointsListPartGroupDto, PointsListPartGroup> {
  private readonly pointsListPartMapper = inject(PointsListPartMapper);

  private readonly pointsListPartGroupPropertiesMapper = inject(PointsListPartGroupPropertiesMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListPartGroupDto): PointsListPartGroup {
    return new PointsListPartGroup({
      id: dto.id,
      name: dto.name,
      description: dto.description ?? '',
      order: dto.order,
      systemId: dto.systemId,
      isDefault: dto.isDefault,
      properties: this.pointsListPartGroupPropertiesMapper.fromDto(dto.properties),
      parts: dto.parts.map(part => this.pointsListPartMapper.fromDto(part)),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListPartGroup): PointsListPartGroupDto {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      order: data.order,
      systemId: data.systemId,
      isDefault: data.isDefault,
      properties: this.pointsListPartGroupPropertiesMapper.fromDto(data.properties),
      parts: data.parts.map(part => this.pointsListPartMapper.toDto(part)),
    };
  }

  /**
   * To create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreatePointsListPartGroup): CreatePointsListPartGroupDto {
    return {
      name: data.name,
      description: data.description,
      partIds: data.partIds,
      order: data.order,
    };
  }

  /**
   * To create DTO from palette part group.
   * @param data Data.
   */
  public toCreateFromPaletteDto(data: CreatePointsListPartGroupFromPalette): CreatePointsListPartGroupFromPaletteDto {
    return {
      order: data.order,
      components: data.paletteComponents.map(component => ({
        paletteComponentId: component.paletteComponentId,
        systemPartGroupIds: component.childComponentIds,
      })),
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListPartGroup): EditPointsListPartGroupDto {
    return {
      name: data.name,
      description: data.description,
      order: data.order,
      estimateSystemId: data.estimateSystemId,
    };
  }

  /**
   * To batch edit DTO.
   * @param data Data.
   */
  public toBatchEditDto(data: BatchEditPointsListPartGroup): BatchEditPointsListPartGroupDto {
    return {
      partGroupId: data.id,
      partGroup: this.toEditDto(data.value),
    };
  }
}
