import { map, Observable, of, tap } from 'rxjs';

import { TransactionsApiService } from '@dartsales/common/core/services/api/transactions-api.service';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';

import { PointsListAbstractCommand } from './points-list-abstract-command';

/** Points list transaction undo command. */
export abstract class PointsListTransactionUndoCommand<TArgs = unknown> extends PointsListAbstractCommand<TArgs> {
  private readonly transactionsApiService = this.injector.get(TransactionsApiService);

  /** Undo transaction ID. */
  private transactionId: ApiTransactionId | null = null;

  /** We use 'undoAction' instead of 'undo' because we have to perform additional side effect on 'undo'. */
  protected abstract executeAction(): Observable<ApiTransactionId>;

  /** Don't override this method in subclasses. Use `executeAction` method instead. */
  public override execute(): Observable<void> {
    return this.executeAction().pipe(
      tap(transaction => {
        this.transactionId = transaction;
      }),
      map(() => undefined),
    );
  }

  /** @inheritdoc */
  public override undo(): Observable<void> {
    if (this.transactionId == null) {
      return of(undefined);
    }

    return this.transactionsApiService.undoTransaction(this.transactionId);
  }

  /** @inheritdoc */
  public override redo(): Observable<void> {
    if (this.transactionId == null) {
      return of(undefined);
    }

    return this.transactionsApiService.redoTransaction(this.transactionId);
  }
}
