import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Expansion action. */
export type ExpansionAction = {

  /** Label. */
  readonly label: string;

  /** Click handler. */
  readonly click: () => void;
};

/** Expansion actions. */
export type ExpansionActions = {

  /** Expand actions. */
  readonly expand: readonly ExpansionAction[];

  /** Collapse actions. */
  readonly collapse: readonly ExpansionAction[];
};

/**
 * Extension actions.
 * @deprecated Use `ToggleableExpansionActionsComponent` instead.
 */
@Component({
  selector: 'dartsalesw-expansion-actions',
  templateUrl: './expansion-actions.component.html',
  styleUrls: ['./expansion-actions.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionActionsComponent {
  /** Actions. */
  @Input()
  public actions: ExpansionActions = {
      expand: [],
      collapse: [],
    };
}
