import { Injectable, inject } from '@angular/core';

import { EditUserProfile, UserProfile } from '../../models/user/user-profile';
import { EntityValidationErrors } from '../../models/errors/app-error';

import { IMapperFromDto } from './mappers';
import { EditUserProfileDto, UserProfileDto } from './dto/user/user-profile.dto';
import { UserOrganizationMapper } from './user-organization.mapper';
import { MappedValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './errors/extract-error-message';
import { UserEstimateMapper } from './user-estimate.mapper';

/** User profile mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserProfileMapper implements IMapperFromDto<UserProfileDto, UserProfile> {

  private readonly userOrganizationMapper = inject(UserOrganizationMapper);

  private readonly userEstimateMapper = inject(UserEstimateMapper);

  /** @inheritdoc */
  public fromDto(dto: UserProfileDto): UserProfile {
    return new UserProfile({
      id: dto.id,
      email: dto.email,
      firstName: dto.firstName,
      lastName: dto.lastName,
      title: dto.title,
      isGlobalAdmin: dto.isGlobalAdmin,
      organizations: dto.organizations.map(org => this.userOrganizationMapper.fromDto(org)),
      baseEstimates: dto.baseEstimates.map(estimate => this.userEstimateMapper.fromDto(estimate)),
    });
  }

  /**
   * Map edit model to DTO.
   * @param data User edit data.
   */
  public toEditDto(data: EditUserProfile): EditUserProfileDto {
    return {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      title: data.title,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: MappedValidationErrorDto<EditUserProfile>): EntityValidationErrors<EditUserProfileDto> {
    return {
      email: extractErrorMessage(errorDto.email),
      firstName: extractErrorMessage(errorDto.firstName),
      lastName: extractErrorMessage(errorDto.lastName),
    };
  }
}
