<span class="header-units-label">
  {{ label }}
</span>
@if (!isReadonly) {
  <div class="input-container">
    @if (cellPrefix) {
      <div class="prefix">
        <ng-content select="[dartsalescTableCellPrefix]" />
      </div>
    }
    <input
      #input
      class="input-container__input"
      [class.cell__has-prefix]="cellPrefix"
      [dartsalescInputMask]="inputMask"
      [formControl]="inputValueControl"
      [readonly]="isLockedValue"
      (blur)="onInputBlur()"
      (keydown.enter)="input.blur(); $event.stopPropagation()"
    />
  </div>
} @else {
  <dartsalesw-table-header-readonly-cell
    [label]="label"
    [value]="value"
  />
}
